import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row, Toast, ToastBody, ToastHeader } from 'reactstrap'
import { ClientsServices } from "../../services/clientsServices";
import ReactDOM from "react-dom";
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr'

export default function CreateUserForm(props) {
    const [newClient, setNewClient] = useState({});
    const [isCreated, setIsCreated] = useState(false);
    const [error, setError] = useState(null);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let result = await ClientsServices
            .createClient(newClient)
            .then((data) => {
                setIsCreated(true);
                toastr["success"]("Клиент успешно создан", "Создание клиента", exportToast)
                props.onApply(data)
            })
            .catch(e => {
                setError(e);
                props.onError(e)
            });
    }

    useEffect(() => {
        if (!isCreated) {
            return;
        }

        setTimeout(() => {
            props.onCancel();
        }, 1500);
    }, [isCreated]);

    return (
        <>
            <Form onSubmit={handleFormSubmit}>
                {error && <Alert color="danger">{error}</Alert>}
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for='lastName'>
                                Фамилия
                            </Label>
                            <Input id='lastName' name='lastName'
                                onChange={({ target: { value } }) => setNewClient({ ...newClient, last_name: value })} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for='firstName'>
                                Имя <b className='text-danger'>*</b>
                            </Label>
                            <Input id='firstName' name='firstName' required
                                onChange={({ target: { value } }) => setNewClient({ ...newClient, first_name: value })} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for='middleName'>Отчество</Label>
                            <Input id='middleName' name='middleName'
                                onChange={({ target: { value } }) => setNewClient({ ...newClient, middle_name: value })} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for='email'>Эл. почта</Label>
                            <Input id='email' name='email' type='email'
                                onChange={({ target: { value } }) => setNewClient({ ...newClient, email: value })} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for='phone'>
                                Номер телефона <b className='text-danger'>*</b>
                            </Label>
                            <Input id='phone' name='phone' type='tel' required
                                onChange={({ target: { value } }) => setNewClient({ ...newClient, phone: value })} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col md={6}>
                        <FormGroup>
                            <Label for='dob'>Дата рождения</Label>
                            <Input id='dob' name='dob' type='date'
                                onChange={({ target: { value } }) => setNewClient({ ...newClient, birthday: value })} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for='discount'>Фиксированная скидка</Label>
                            <Input id='discount' name='discount'
                                onChange={({ target: { value } }) => setNewClient({ ...newClient, discount: value })} />
                        </FormGroup>
                    </Col>
                </Row>
                <Button color='success' type="submit">
                    Создать клиента
                </Button>{' '}
                <Button onClick={props.onCancel} className='mx-2'>
                    Отмена
                </Button>
            </Form>
        </>
    )
}
CreateUserForm.propTypes = {
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func
}
