import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import PropTypes from 'prop-types';
import CreateUserForm from '../Clients/create-user-form'

export default function CreateUserModal (props) {
  return (
    <Modal isOpen={props.isOpen} centered size="lg" style={{height: "600px"}}>
      <ModalHeader toggle={props.onClose}>
        Создание нового клиента
      </ModalHeader>
      <ModalBody>
        <CreateUserForm onApply={props.onApply} onError={props.onError} onCancel={props.onClose} />
      </ModalBody>
    </Modal>
  )
}
CreateUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onError: PropTypes.func
}
