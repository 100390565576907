import React from 'react'
import PropTypes from 'prop-types'
import { ObjectMap } from './ObjectMap'
import './objects-maps.scss'

export const ObjectsMaps = ({ models }) => {
  return (
    <div className='obj-map-list'>
      {models.map(model => (
        <ObjectMap key={model.title} model={model} />
      ))}
    </div>
  )
}

ObjectsMaps.propTypes = {
  models: PropTypes.array,
}
