export default class StatusServ {
    static colors =  {
        CREATED: { text: 'Создан', color: 'info' },
        ISSUED: { text: 'Выставлен', color: 'success' },
        PAID: { text: 'Оплачен', color: 'paid' },
        CANCELED: { text: 'Отменен', color: 'secondary' },
        DELETED: { text: 'Удален', color: 'danger' },
        ERROR: { text: 'Ошибка', color: 'finished' },
    };

    static getByName(name) {
        let res = {text: '', color: ''};
        Object.keys(StatusServ.colors).forEach(key => {
            let element = StatusServ.colors[key];

            if(element.text == name) res = element;
        });
        console.log(res);
        return res;
    }
    static getByTag(tag) {
        if(StatusServ.colors[tag]){
            return StatusServ.colors[tag];
        }
        else{
            return {text: '', color: ''};
        }
    }
}
