import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import { List, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import {OrdersService} from "../../services/ordersService";
import moment from "moment";
// const history = [
//   { title: 'Эдуард создал заказ №1469', timestamp: '27.04/2022, 11:14' },
//   {
//     title: 'Александр отредактировал заказ №1469',
//     timestamp: '27.04/2022, 12:14',
//   },
// ]

export function OrderHistory(props) {
  const [history, setHistory] = useState([]);
  useEffect(async()=>{
    if(props.order_id){
      let his = await OrdersService.history(props.order_id);
      if (his.data.data.history && his.data.data.history.length) {
        setHistory(his.data.data.history.reverse().map((item, idx) => (
          <li key={`history-item-${idx}`} className='mt-2'>
            <span>{item.message}</span>
            <br />
            <span>{moment(item.created_at).utc(0).format('YYYY-MM-DD HH:mm')}</span>
          </li>
        )));
      }
    }
  }, [props.order_id])

  return (
    <Offcanvas isOpen={props.show} toggle={props.onClose} direction='end'>
      <OffcanvasHeader toggle={props.onClose}>
        История изменений
      </OffcanvasHeader>
      <OffcanvasBody>
        <List tag='ol' style={{
          'display': 'flex',
          'flex-direction': 'column-reverse',
        }}>{history}</List>
      </OffcanvasBody>
    </Offcanvas>
  )
}

OrderHistory.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  order_id: PropTypes.any
}
