import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  SET_PROFILE_DATA,
  GET_PROFILE_DATA, SET_LAST_ACTIVITY, GET_LAST_ACTIVITY
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
};

export const setProfileData = (profileData) => {
  return {
    type: SET_PROFILE_DATA,
    payload: profileData,
  }
};

export const getProfileData = () => {
  return {
    type: GET_PROFILE_DATA
  };
};

export const setLastActivity = (lastActivity) => {
  return {
    type: SET_LAST_ACTIVITY,
    payload: lastActivity,
  };
};

export const getLastActivity = () => {
  return {
    type: GET_LAST_ACTIVITY,
  };
};