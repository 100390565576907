import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, FormGroup } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import LagoonaTable from '../../components/LagoonaTable'
import { defaultColumnStyle, getDefaultPaginationSettings } from '../../common/tableSettings'
import moment from 'moment'
import { BraceletsServices } from '../../services/braceletsServices';
import EditBraceletsModal from '../Bracelets/edit';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr'

export const braceletsColumns = [
    {
        dataField: 'created_at',
        text: 'Дата',
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: cell => {
            const m = moment(cell)
            return (
                <>
                    {m.format('D.MM.yyyy')}
                    <br />
                    <span className='text-black-50'>{m.format('HH:mm')}</span>
                </>
            )
        },
        headerStyle: {
            width: 150,
        },
        style: defaultColumnStyle,
    },
    {
        dataField: 'orange_adults',
        text: 'Оранжевые взрослые',
        style: defaultColumnStyle,
    },
    {
        dataField: 'orange_kids',
        text: 'Оранжевые до 12 лет',
        style: defaultColumnStyle,
    },
    {
        dataField: 'blue_adults',
        text: 'Синие взрослые',
        style: defaultColumnStyle,
    },
    {
        dataField: 'blue_kids',
        text: 'Синие до 12 лет',
        style: defaultColumnStyle,
    },
    {
        dataField: 'red',
        text: 'Красные',
        style: defaultColumnStyle,
    },
    {
        dataField: 'green',
        text: 'Зеленые',
        style: defaultColumnStyle,
    },
    {
        dataField: 'total',
        text: 'Всего',
        style: defaultColumnStyle,
    },
    {
        dataField: 'order_number',
        text: '№ Заказа',
        style: defaultColumnStyle,
    },
]

export default function OrderBraceletsHistory(props) {

    const { order_id } = props;

    // Table state
    const [braceletsHistory, setBraceletsHistory] = React.useState({ total: 0, items: [] });


    // Modal state
    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const [currentEditId, setCurrentEditId] = React.useState(undefined);
    const [toggle, setToggle] = React.useState(false);


    const fetch = async () => {
        try {
            const data = await BraceletsServices.readOrderBraceletHistory(order_id);
            setBraceletsHistory(data.data.data);
        }
        catch (error) {
            toastr['error'](error, "Загрузка истории браслетов", exportToast);
        }
    }

    React.useEffect(() => {
        if (!order_id) {
            return
        }
        fetch();
    }, [order_id, toggle])

    const rowEvents = {
        onClick: (e, row, index) => {
            if (window.getSelection().toString()) {
                return;
            }
            setCurrentEditId(row.id);
            setIsEditOpen(true);
        }
    };

    const toggleReload = () => {
        setToggle(!toggle);
    }

    return (
        <Card className="p-4">
            <EditBraceletsModal isOpen={isEditOpen} toggle={() => setIsEditOpen(false)} currentEditId={currentEditId} toggleReload={toggleReload} />
            <CardTitle>История выданных браслетов</CardTitle>
            <ToolkitProvider keyField='id' data={braceletsHistory.items} columns={braceletsColumns}>
                {(props) => (
                    <>
                        <LagoonaTable
                            // eslint-disable-next-line react/prop-types
                            {...props.baseProps}
                            rowEvents={rowEvents}
                        />
                    </>
                )}
            </ToolkitProvider>
        </Card>
    )
}

OrderBraceletsHistory.propTypes = {
    order_id: PropTypes.any
}