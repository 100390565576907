import { BaseApi } from '../helpers/api'
import { getToken } from '../helpers/common'

export class DashboardServices {

  static getDashboardSummary = async (dateFrom, dateTo) => {
    const jwtToken = getToken()

    let params = []
    if (dateFrom) params.push(`date_from=${dateFrom}`)
    if (dateTo) params.push(`date_to=${dateTo}`)

    return BaseApi.getRequest({
      url: 'analytics/dashboard/summary'
        + (params.length === 0 ? '' : `?${params.join('&')}`),
      jwt: jwtToken,
    })
  }

  static getPaymentMethodData = async (dateFrom, dateTo) => {
    const jwtToken = getToken()

    let params = []
    if (dateFrom) params.push(`date_from=${dateFrom}`)
    if (dateTo) params.push(`date_to=${dateTo}`)

    return BaseApi.getRequest({
      url: 'analytics/dashboard/payment-method' +
        (params.length === 0 ? '' : `?${params.join('&')}`),
      jwt: jwtToken,
    })
  }

  static getOrderSourceData = async (dateFrom, dateTo) => {
    const jwtToken = getToken()

    let params = []
    if (dateFrom) params.push(`date_from=${dateFrom}`)
    if (dateTo) params.push(`date_to=${dateTo}`)

    return BaseApi.getRequest({
      url:
        'analytics/dashboard/order-source' +
        (params.length === 0 ? '' : `?${params.join('&')}`),
      jwt: jwtToken,
    })
  }

  static getGraphIncomeData = async (group, dateFrom, dateTo) => {
    const jwtToken = getToken()
    let params = []
    if (group) params.push(`group_by=${group}`);
    if (dateFrom) params.push(`date_from=${dateFrom}`);
    if (dateTo) params.push(`date_to=${dateTo}`);

    return BaseApi.getRequest({
      url:
        'analytics/dashboard/graph-income' +
        (params.length === 0 ? '' : `?${params.join('&')}`),
      jwt: jwtToken,
    })
  }

  static getTrafficSourceData = async (dateFrom, dateTo) => {
    const jwtToken = getToken()
    let params = []
    if (dateFrom) params.push(`date_from=${dateFrom}`);
    if (dateTo) params.push(`date_to=${dateTo}`);

    return BaseApi.getRequest({
      url:
        'analytics/dashboard/traffic-source' +
        (params.length === 0 ? '' : `?${params.join('&')}`),
      jwt: jwtToken,
    })
  }

  static getTop5ClientsData = async (dateFrom, dateTo) => {
    return BaseApi.getRequest({
      url: `analytics/client/list?page=0&pageSize=5&sort=total_amount&direction=desc&booking_date_from=${dateFrom}&booking_date_to=${dateTo}`,
      jwt: getToken(),
    });
  }

  static getTop5OrdersData = async (dateFrom, dateTo) => {
    return BaseApi.getRequest({
      url: `analytics/order/list?page=0&pageSize=5&sort=amount_paid&direction=desc&booking_date_from=${dateFrom}&booking_date_to=${dateTo}`,
      jwt: getToken(),
    });
  }

  static getTop5ServiceData = async (dateFrom, dateTo) => {
    return BaseApi.getRequest({
      url: `analytics/service/list?page=0&pageSize=5&sort=total_amount&direction=desc&booking_date_from=${dateFrom}&booking_date_to=${dateTo}`,
      jwt: getToken(),
    });
  }

}
