export const isLoggedIn = () => JSON.parse(localStorage.getItem("authUser") || "{}")?.access_token || "";

export const getToken = () => JSON.parse(localStorage.getItem("authUser") || "{}")?.access_token || "";

export const getRefreshToken = () => JSON.parse(localStorage.getItem("authUser") || "{}")?.refresh_token || "";

export const logout = () => localStorage.removeItem("authUser");

export const isJwtExpired = () => {
    return (parseJwt(
      getToken()
    )?.exp || 0) * 1000 < Date.now()
};

export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};
