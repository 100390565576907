import React from 'react'
import { Redirect } from 'react-router-dom'

// Profile
import UserProfile from '../pages/Authentication/user-profile'

// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'
import ForgetPwd from '../pages/Authentication/ForgetPassword'
import ConfirmReset from '../pages/Authentication/ConfirmReset'
import PasswordRestored from '../pages/Authentication/PasswordRestored'

// Dashboard
import Dashboard from '../pages/Dashboard/index'

// Orders
import Orders from '../pages/Orders/index'
import OrderDetails from '../pages/Orders/details'
import OrderCreate from '../pages/Orders/add'
import OrdersAnalytics from '../pages/Orders/analytics'

// Clients
import Clients from '../pages/Clients/index'
import ClientDetails from '../pages/Clients/details'
import ClientCreate from '../pages/Clients/add'
import ClientEdit from '../pages/Clients/edit'
import ClientsAnalytics from '../pages/Clients/analytics'

// Employees
import Employees from '../pages/Employees/index'
import EmployeeCreate from '../pages/Employees/add'
import EmployeeDetails from '../pages/Employees/details'
import EmployeeEdit from '../pages/Employees/edit'

// Services
import Services from '../pages/Services/index'
import ServiceDetails from '../pages/Services/details'
import ServiceCreate from '../pages/Services/add'
import ServiceEdit from '../pages/Services/edit'
import ServicesAnalytics from '../pages/Services/analytics'

// Billing
import Billing from '../pages/Billing/index';
import BillDetails from '../pages/Billing/bill-details'
import PayDetails from '../pages/Billing/pay-details'
import BillCreate from 'pages/Billing/bill-add';
import BillingAnalytics from '../pages/Billing/analytics'

// Timetable
import Timetable from "../pages/Timetable";

// Map
import MapPage from "../pages/Map";

// Bracelets 

import BraceletsPage from '../pages/Bracelets/';

// Utility pages
import Pages404 from '../pages/Utility/pages-404'
import Pages500 from '../pages/Utility/pages-500'


const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },

  // Orders
  { path: '/orders', component: Orders },
  { path: '/order-details', component: OrderDetails },
  { path: '/add-order', component: OrderCreate },
  { path: '/analytics-order', component: OrdersAnalytics },

  // Clients
  { path: '/clients', component: Clients },
  { path: '/client-details', component: ClientDetails },
  { path: '/add-client', component: ClientCreate },
  { path: '/edit-client', component: ClientEdit },
  { path: '/analytics-client', component: ClientsAnalytics },

  // Employees
  { path: '/employees', component: Employees },
  { path: '/employee-details', component: EmployeeDetails },
  { path: '/add-employee', component: EmployeeCreate },
  { path: '/edit-employee', component: EmployeeEdit },

  // Services
  { path: '/services', component: Services },
  { path: '/service-details', component: ServiceDetails },
  { path: '/add-service', component: ServiceCreate },
  { path: '/edit-service', component: ServiceEdit },
  { path: '/analytics-service', component: ServicesAnalytics },

  // Billing
  // billing/invoice
  { path: '/billing/:id?', component: Billing },
  { path: '/bill-details', component: BillDetails },
  { path: '/pay-details', component: PayDetails },
  { path: '/add-bill', component: BillCreate },
  { path: '/analytics-billing', component: BillingAnalytics },

  // //profile
  { path: '/profile', component: UserProfile },

  // Timetable
  { path: '/timetable', component: Timetable },

  // Map
  { path: '/map', component: MapPage },

  { path: '/bracelets', component: BraceletsPage },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },
]

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  { path: '/confirm-reset', component: ConfirmReset },
  { path: '/password-restored', component: PasswordRestored },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { publicRoutes, authProtectedRoutes }
