import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button, Col,
  FormGroup,
  Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

export default function ClientsAnalyticsFilter(props) {

  const { onApply } = props;

  const [discount_amount_from, setTotalDiscountFrom] = useState('');
  const [discount_amount_to, setTotalDiscountTo] = useState('');

  const [count_from, setTotalOrdersFrom] = useState('');
  const [count_to, setTotalOrdersTo] = useState('');

  const [total_amount_from, setTotalAmountFrom] = useState('');
  const [total_amount_to, setTotalAmountTo] = useState('');

  const resetFilters = () => {
    setTotalAmountFrom('');
    setTotalAmountTo('');
    setTotalDiscountFrom('');
    setTotalDiscountTo('');
    setTotalOrdersFrom('');
    setTotalOrdersTo('');
    onApply();
  };

  const Apply = () => {
    onApply({
      discount_amount_from,
      discount_amount_to,
      count_from,
      count_to,
      total_amount_from,
      total_amount_to,
    })
  }

  return (
    <div className="d-flex justify-content-center">
      <Modal isOpen={props.show} centered size="lg" style={{ height: "600px" }}>
        <ModalHeader toggle={props.onCancel}>
          Фильтр
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Col>
              <Label md="auto" for="fromDiscount">Сумма скидки</Label>
              <FormGroup row className={"align-items-baseline"}>
                <Col>
                  <Input id="fromDiscount" value={discount_amount_from} onChange={(e) => setTotalDiscountFrom(e.target.value)} placeholder={0} />
                </Col>
                -
                <Col>
                  <Input id="toDiscount" value={discount_amount_to} onChange={(e) => setTotalDiscountTo(e.target.value)} placeholder={0} />
                </Col>
              </FormGroup>
              <Label md="auto" for="fromPrice">Общая сумма</Label>
              <FormGroup row className={"align-items-baseline"}>
                <Col>
                  <Input id="fromPrice" value={total_amount_from} onChange={(e) => setTotalAmountFrom(e.target.value)} placeholder={0} />
                </Col>
                -
                <Col>
                  <Input id="toPrice" value={total_amount_to} onChange={(e) => setTotalAmountTo(e.target.value)} placeholder={0} />
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <Label md="auto" for="fromOrders">Всего заказов</Label>
              <FormGroup row className={"align-items-baseline"}>
                <Col>
                  <Input id="fromOrders" value={count_from} onChange={(e) => setTotalOrdersFrom(e.target.value)} placeholder={0} />
                </Col>
                -
                <Col>
                  <Input id="toOrders" value={count_to} onChange={(e) => setTotalOrdersTo(e.target.value)} placeholder={0} />
                </Col>
              </FormGroup>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => Apply()}>Применить</Button>
          <Button onClick={resetFilters}>Сброс</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
ClientsAnalyticsFilter.propTypes = {
  show: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
