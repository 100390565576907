import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button, Col,
  FormGroup,
  Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { ServiceServices } from "../../services/serviceServices";

const categories = ['Комфорт', 'Стандарт', 'Полулюкс', 'Малая', 'Средняя', 'Большая', 'Баня', 'Хостел'];
const units = ['День', 'Сутки', 'Час'];

const categoryList = categories.map((cat) => ({ value: cat, label: cat }));
const unitList = units.map((unit) => ({ value: unit, label: unit }));

export default function ClientsFilter(props) {
  const resetFilters = () => {
    setCategoryFiler(null);
    setUnitFilter(null);
    setMinFilter(null);
    setMaxFilter(null);
    setMinPrice(null);
    setMaxPrice(null);
    // eslint-disable-next-line react/prop-types
    props.onReset();
  };

  const [categoryFilter, setCategoryFiler] = useState([]);
  const [minFilter, setMinFilter] = useState(null);
  const [maxFilter, setMaxFilter] = useState(null);

  const [unitFilter, setUnitFilter] = useState([]);

  const [minPriceFilter, setMinPrice] = useState(null);
  const [maxPriceFilter, setMaxPrice] = useState(null);

  const [categoryList, setCategoryList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    ServiceServices.getFormFilter().then(response => {
      setCategoryList(response.data.data.categories.map((cat, i) => ({ key: i, value: cat, label: cat })));
      setUnitList(response.data.data.units.map((unit, i) => ({ key: i, value: unit, label: unit })));
    });
  }, []);

  console.log({ categoryFilter, maxFilter, minFilter, maxPriceFilter, minPriceFilter, unitFilter });

  return (
    <div className="d-flex justify-content-center">
      <Modal isOpen={true} centered size="lg" style={{ height: "600px" }} wrapClassName={props.show ? "" : "hidden"}>
        <ModalHeader toggle={props.onCancel}>
          Фильтр
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label for="categorySelector">Категории</Label>
            <Select options={categoryList} value={categoryFilter} onChange={e => setCategoryFiler(e)} isMulti placeholder="Поиск" />
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label md="auto" for="minCount">Мин. человек:</Label>
              <Input id="minCount" value={minFilter} onChange={e => setMinFilter(e.target.value)} />
            </Col>
            <Col>
              <Label md="auto" for="maxCount">Макс. человек:</Label>
              <Input id="maxCount" value={maxFilter} onChange={e => setMaxFilter(e.target.value)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label md="auto" for="unitSelector">Усл. ед</Label>
              <Select id="unitSelector" value={unitFilter} options={unitList} onChange={e => setUnitFilter(e)} placeholder="Поиск" />
            </Col>
            <Col>
              <Label md="auto" for="minCost">Мин. стоимость:</Label>
              <Input id="minCost" value={minPriceFilter} onChange={e => setMinPrice(e.target.value)} />
            </Col>
            <Col>
              <Label md="auto" for="maxCost">Макс. стоимость:</Label>
              <Input id="maxCost" value={maxPriceFilter} onChange={e => setMaxPrice(e.target.value)} />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => props.onApply({
            categoryFilter: categoryFilter?.map(e => e.value).join(","),
            unitFilter: unitFilter?.value,
            minFilter,
            maxFilter,
            minPriceFilter,
            maxPriceFilter
          })}>Применить</Button>
          <Button onClick={resetFilters}>Сброс</Button>
        </ModalFooter>
      </Modal>
    </div>
  )

}
ClientsFilter.propTypes = {
  show: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.string),
    minPeople: PropTypes.number,
    maxPeople: PropTypes.number,
    units: PropTypes.arrayOf(PropTypes.string),
    minCost: PropTypes.number,
    maxCost: PropTypes.number
  })
};
