import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import CarouselPage from '../../components/CarouselPage'
import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Form,
    Input,
    FormFeedback,
    Label,
    FormGroup,
} from 'reactstrap'

import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Link, withRouter } from 'react-router-dom'
import laguna from "../../assets/images/laguna_logo.svg";


const PasswordRestored = (props) => {
    return (

        <React.Fragment>
            <div>
                <MetaTags>
                    <title>Пароль сброшен</title>
                </MetaTags>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <CarouselPage number={4} />

                        <Col cl={3}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5">
                                            <a href="/" className="d-block auth-logo">
                                                <img
                                                    src={laguna}
                                                    alt="logo"
                                                    height="64"
                                                    className="auth-logo-dark"
                                                />

                                            </a>
                                        </div>
                                        <div className="my-auto">
                                            <div className="text-center">
                                                <div className="avatar-md mx-auto">
                                                    <div className="avatar-title rounded-circle bg-light">
                                                        <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                                                    </div>
                                                </div>
                                                <div className="p-2 mt-4">
                                                    <h4>Пароль восстановлен</h4>
                                                    <p>
                                                        Новый пароль был отправлен на номер вашего телефона{" "}
                                                    </p>
                                                    <div className="mt-4">
                                                        <Link
                                                            to="/login"
                                                            className="btn btn-success w-md"
                                                        >
                                                            К Авторизации
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">
                                                © {new Date().getFullYear()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(PasswordRestored)