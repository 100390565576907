import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import ConfirmDialog from "../../components/ConfirmDialog";
import OrderServicesCard from './add-services';
import OrderTransactionsCard from './transactions';
import OrderInvoicesCard from './invoices';
import { OrderHistory } from "./history";
import { orderStatuses } from "./models";
import { OrdersService } from "../../services/ordersService";
import moment from "moment";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import StatusServ from 'services/statusServ';
import { ClientsServices } from "../../services/clientsServices";
import { ToastNotification } from "../../components/ToastNotification";
import { formatDate2 } from '../../utils/toHuman';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';
import OrderBraceletsHistory from './braceletsHistory';



const transformToId = (name) => {
  switch (name) {
    case 'Сайт':
      return 1;
    case 'Звонок':
      return 2;
    case 'Ресепшн':
      return 3;
    case 'Баннерная реклама':
      return 4;
    case 'Буклетная реклама':
      return 5;
    case 'Инстаграм':
      return 6;
    case 'Вконтакте':
      return 7;
    case 'Телеграм':
      return 8;
    case 'По рекомендации':
      return 9;
    case 'Постоянный клиент':
      return 10;
    case 'Радио-реклама':
      return 11;
    case 'Контекстная реклама (сайт)':
      return 12;
    case 'Транспортная реклама':
      return 13;
    default:
      return 14;
  }
}





export default function OrderDetails() {


  const [fio, setFio] = useState(null);
  const [client_phone, setPhone] = useState(null);
  const [client_email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [order_source, setOrderSource] = useState(null);
  const [traffic_source, setTraficSource] = useState(null);
  const [booking_date_from, setBookingFrom] = useState('');
  const [booking_date_to, setBookingTo] = useState('');
  const [manager_comment, setComment] = useState('');
  const [adults_count, setAdults] = useState('');
  const [kids_before_seven, setBeforeSeven] = useState('');
  const [kids_after_seven, setAfterSeven] = useState('');
  const [discount, setDiscount] = useState('');
  const [client_id, setClientId] = useState('');
  const [uuid, setUuid] = useState('');
  const [amount_left, setLeft] = useState(0);
  const [items, setItemsData] = useState([]);
  const setItemsDataF = (data) => {
    setItemsData(data);
  }

  const [pPeriod, setPPeriod] = useState(false);
  const [sPeriod, setSPeriod] = useState(false);

  const [can, setCan] = useState(false);
  const history = useHistory();
  const selectRef = React.createRef();
  const { search } = useLocation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [statusId, setStatusId] = useState(3);
  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sDis, setSDIS] = useState(false);
  const [sCurDis, setCurSDIS] = useState(false);
  const [bilet, setBilet] = useState(false);
  const [sourceList, setTrafics] = useState([]);
  const [trafficSourceList, setOrdSourceList] = useState([]);

  useEffect(async () => {
    const response = await OrdersService.getOrdersFilter();
    let res = [];
    setUuid(response.data.data.uuid);
    let trafics = response?.data?.data?.traffic_source || {};
    Object.keys(trafics).forEach(key => {
      res.push({ value: trafics[key].id, label: trafics[key].name });
    });
    setTrafics(res);


    res = [];
    let order_sources = response.data.data.order_source;
    Object.keys(order_sources).forEach(key => {
      res.push({ value: order_sources[key].id, label: order_sources[key].name });
    });
    setOrdSourceList(res);

  }, [true]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    setOrderId(params.get("id"));
  }, []);

  useEffect(async () => {
    if (!orderId) {
      return;
    }

    setLoading(true);
    const response = await OrdersService.read(orderId);
    setLoading(false);
    setCan(response.data.data.can);
    let order = response.data.data.order;
    // selectUset(order.client_id);
    setFio({ value: order.client_id, label: order.client_name });
    setPhone({ value: order.client_id, label: order.client_phone });
    setEmail(order.client_email);
    setBirthdate(null);

    if (order.birthdate) {
      setBirthdate(formatDate2(new Date(order.birthdate)));
    }

    setOrderSource({ value: order.order_source.name, label: order.order_source.name });
    setTraficSource({ value: order.traffic_source.name, label: order.traffic_source.name });
    setBookingFrom(moment(order.booking_date_from).format('YYYY-MM-DD'));
    setBookingTo(moment(order.booking_date_to).format('YYYY-MM-DD'));
    setComment(order.manager_comment);
    setAdults(order.adults_count);
    setBeforeSeven(order.kids_before_seven);
    setAfterSeven(order.kids_after_seven);
    setDiscount(order.discount);
    setClientId(order.client_id);
    setUuid(order.uuid);
    setStatusId(order.status.id)
    setLeft(order.amount_left);
  }, [orderId]);


  const setStat = async (id) => {
    setStatusId(id);
    await (OrdersService.setStatus(orderId, id)).then(async () => {
      toastr['success']('Cтатус успешно изменён', 'Cтатус ', exportToast);
      const response = await OrdersService.read(orderId);
      setCan(response.data.data.can);
    }).catch((e) => {
      toastr['error'](e, 'Ошибка', exportToast);
    })


  }

  const getOptionsPhone = async (input) => {
    let users = await OrdersService.getUsers({ phone: input });
    users = users.data.data.items;
    let res = [];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.phone });
    });
    return res;
  }

  const getOptionsFio = async (input) => {
    let users = await OrdersService.getUsers({ fio: input });
    users = users.data.data.items;
    let res = [];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.fullName });
    });
    return res;
  }

  const selectUset = async (id) => {
    let client = await ClientsServices.getClientInfo(id);
    client = client.data.data;
    setClientId(client.id);
    setFio({ value: client.id, label: client.fullName });
    setPhone({ value: client.id, label: client.phone });
    setEmail(client.email);
    setDiscount(client.discount)
    if (client.birthday) {
      console.log(client.birthday)
      setBirthdate(client.birthday);
    }
  }

  const onSelectUser = async (user) => {
    selectUset(user.value)
  };

  const status = useMemo(() =>
    orderStatuses.find(({ id }) => id === statusId)
    , [statusId]);

  const deleteOrder = async (id) => {
    await OrdersService.delete(id);
    setShowConfirm(false);
    history.goBack()
  };

  function genBillet(a, k) {
    setBilet({
      booking_date_from,
      booking_date_to,
      adults_count: a,
      kids_after_seven: k
    })
  }

  async function save(e) {
    e.preventDefault();
    let data = {
      client_id,
      client_email,
      client_phone,
      traffic_source: transformToId(traffic_source.label),
      order_source: transformToId(order_source.label),
      booking_date_from,
      booking_date_to,
      manager_comment,
      adults_count,
      kids_before_seven,
      kids_after_seven,
      discount,
      birthdate
    };

    // data.status = status;
    if (data.client_phone) {
      data.client_phone = data.client_phone.label;
    }
    else {
      toastr['info']('Выберите телефон', 'Форма', exportToast);
      return false;
    }

    if (data.order_source) {
      // data.order_source = data.order_source.value;
    }
    else {
      toastr['info']('Выберите источник заказа', 'Форма', exportToast);
      return false;
    }

    if (data.traffic_source) {
      // data.traffic_source = data.traffic_source.value;
    }
    else {
      toastr['info']('Выберите источник траффика', 'Форма', exportToast);
      return false;
    }

    if (!data.adults_count) {
      toastr['info']('Введите количество взрослых', 'Форма', exportToast);
      return false;
    }




    let ids = await OrdersService.items(orderId);
    ids = ids.data.data.items;
    // items.forEach(async item => {
    //   if(item.noChange){
    //     ids.forEach((j, key) => {
    //       if(item.id ==)
    //     });
    //   }
    // });
    if (ids.length > 0) {
      await ids.forEach(async (item, key) => {
        await OrdersService.delItem({
          uuid: uuid,
          service_id: item.service_id
        }).then(() => {
          if (key == ids.length - 1) {
            adlback();
          }
        });
      });
    }
    else {
      adlback();
    }

    async function adlback() {
      data.items = [];
      data.order_source = transformToId(data.order_source);
      data.traffic_source = transformToId(data.traffic_source);
      await items.forEach(async item => {
        // if(!item.noChange){
        await OrdersService.addItem({
          uuid: uuid,
          service_id: item.id,
          service_count: item.people_count,
          service_discount: item.discount,
          booking_date_from: moment(item.start_time).format('YYYY-MM-DD HH:mm:ss'),
          booking_date_to: moment(item.start_to).format('YYYY-MM-DD HH:mm:ss')
        });
        // }
      });
      toastr['success']('Услуги обновлены', 'Услуги', exportToast);
    }
    await OrdersService.fullUpdate(orderId, data).then(async data => {
      toastr['success']('Заказ обновлён', 'Заказы', exportToast);
    }).catch(e => {
      toastr['error'](e, 'Ошибка', exportToast);
    })

  }

  const canGet = (id) => {
    if (
      (id == 0 && !can.canDelete) ||
      (id == 1 && !can.canCancel) ||
      (id == 2 && !can.canDraft) ||
      (id == 3 && !can.canNew) ||
      (id == 4 && !can.canPrepaymentWait) ||
      (id == 5 && !can.canPrepay) ||
      (id == 6 && !can.canPay) ||
      (id == 7 && !can.canFinish)
    ) return true;
    else {
      return false;
    }
  }

  useEffect(() => {
    setSPeriod([booking_date_from, booking_date_to])
  }, [booking_date_from, booking_date_to])

  return (
    <div className='page-content'>
      <Container fluid>
        <form onSubmit={save}>
          <FormGroup row>
            <Col>
              <h4 className='mb-4'>Карточка заказа</h4>
            </Col>
            <Col className="text-end">
              <Button outline onClick={() => setShowHistory(true)}>
                История изменений
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>

            <Col>
              <Card className='p-4'>
                <FormGroup row>
                  <Col md={8}>
                    <CardTitle>Общая информация</CardTitle>
                  </Col>
                  <Col md={4} className={"text-end"}>
                    <Dropdown
                      isOpen={showStatusMenu}
                      toggle={() => setShowStatusMenu(!showStatusMenu)}
                      className="dropdown d-inline-block"
                    >
                      {orderStatuses[statusId] && <DropdownToggle color={orderStatuses[statusId].color} caret style={{ minWidth: "220px" }}>
                        {orderStatuses[statusId].name}
                      </DropdownToggle>}
                      <DropdownMenu end>
                        {orderStatuses.map(s => <DropdownItem key={s.id}>
                          <Button disabled={canGet(s.id)} type="button" color={orderStatuses[s.id].color} style={{ minWidth: "220px" }} onClick={() => setStat(s.id)}>
                            {s.name}
                          </Button>
                        </DropdownItem>)}
                      </DropdownMenu>
                    </Dropdown>


                  </Col>
                </FormGroup>
                <CardText>
                  <FormGroup row>
                    <Col>
                      <FormGroup row>
                        <Col>
                          <Label md="auto" for="clientName">Клиент <b className='text-danger'>*</b></Label>
                          <AsyncSelect disabled value={fio} id="clientName" loadOptions={getOptionsFio} placeholder="Поиск" onChange={onSelectUser} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col>
                          <Label md="auto" for="sourceList">Источник заказа <b className='text-danger'>*</b></Label>
                          <Select id="phone" value={order_source} onChange={e => setOrderSource(e)} options={trafficSourceList} placeholder="Поиск" />
                        </Col>
                      </FormGroup>
                      <Label md="auto" for="startDate">Период <b className='text-danger'>*</b></Label>
                      <FormGroup row className="align-items-baseline">
                        <Col>
                          <Input value={booking_date_from} onChange={e => {
                            setBookingFrom(e.target.value);
                            setPPeriod([e.target.value, booking_date_to])
                            setSPeriod([e.target.value, booking_date_to])
                          }} required id="startDate" type="date" />
                        </Col>
                        -
                        <Col>
                          <Input value={booking_date_to} onChange={e => {
                            setBookingTo(e.target.value);
                            setPPeriod([booking_date_from, e.target.value])
                            setSPeriod([booking_date_from, e.target.value])
                          }} required id="endDate" type="date" />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col>
                          <Label md="auto" for="comment">Комментарий</Label>
                          <Input value={manager_comment} onChange={e => setComment(e.target.value)} id="comment" type="textarea" rows={6} style={{ resize: "none" }} />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Col>
                          <Label md="auto" for="phone">Номер телефона <b className='text-danger'>*</b></Label>
                          <AsyncSelect value={client_phone} id="phone" loadOptions={getOptionsPhone} onChange={onSelectUser} placeholder="Поиск" />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col>
                          <Label md="auto" for="email">Электронная почта</Label>
                          <Input value={client_email} onChange={e => setEmail(e.target.value)} id="email" />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col>
                          <Label md="auto" for="people">Взрослых <b className='text-danger'>*</b></Label>
                          <Input value={adults_count} onChange={e => { setAdults(e.target.value); genBillet(e.target.value, kids_after_seven) }} required type="number" id="people" />
                        </Col>
                        <Col>
                          <Label md="auto" for="children">Детей до 7</Label>
                          <Input value={kids_before_seven} onChange={e => setBeforeSeven(e.target.value)} type="number" id="children" />
                        </Col>
                        <Col>
                          <Label md="auto" for="teens">Детей от 8 до 12</Label>
                          <Input value={kids_after_seven} onChange={e => { setAfterSeven(e.target.value); genBillet(adults_count, e.target.value) }} type="number" id="teens" />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col>
                          <Label md="auto" for="birthDate">Дата рождения</Label>
                          <Input value={birthdate} onChange={e => setBirthdate(e.target.value)} id="birthDate" type="date" />
                        </Col>
                        <Col>
                          <Label md="auto" for="discount">Фикс. скидка</Label>
                          <Input value={discount} onChange={e => {
                            setDiscount(e.target.value);
                            setSDIS(e.target.value);
                          }} type="number" id="discount" />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col>
                          <Label md="auto" for="trafficSource">Источник трафика <b className='text-danger'>*</b></Label>
                          <Select id="sourceList" value={traffic_source} onChange={e => setTraficSource(e)} options={sourceList} placeholder="Поиск" />
                        </Col>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </CardText>
              </Card>
            </Col>

          </FormGroup>
          <OrderServicesCard s_period={sPeriod} period={pPeriod} cur_discount={discount} amount_left={amount_left} bilet={bilet} discount={sDis} setItemsData={setItemsDataF} order_id={orderId} />
          <OrderInvoicesCard order_id={orderId} />
          <OrderTransactionsCard order_id={orderId} />
          <OrderBraceletsHistory order_id={orderId} />
          <FormGroup row>
            <Col>
              <Row>
                <Col md="auto">
                  <Button color='success' type="submit">
                    Сохранить
                  </Button>
                </Col>
                <Col md="auto">
                  <Button color='danger' onClick={() => setShowConfirm(true)}>
                    Удалить
                  </Button>
                </Col>
                <Col md="auto">
                  <Button onClick={() => history.goBack()}>
                    Отменить
                  </Button>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </form>
      </Container>
      <ConfirmDialog
        title="Удаление"
        body="Вы действительно хотите удалить заказ?"
        show={showConfirm}
        onClick={() => deleteOrder(orderId)}
        onCancel={() => setShowConfirm(false)}
      />
      <OrderHistory
        order_id={orderId}
        show={showHistory}
        onClose={() => setShowHistory(false)}
      />
    </div>
  )
}
