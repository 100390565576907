import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class ServiceAnalyticsServices {

    static getServiceList = async (
        {
            search = "",
            pageSize = 10,
            page = 0,
            sort = "total_amount",
            direction = "desc",
            dateFrom,
            dateTo,
            total_amount_from,
            total_amount_to,
            orders_count_from,
            orders_count_to,
            category,
            service_ids
        }) => {
        try {
            return BaseApi.getRequest({
                url: `analytics/service/list?page=${page}&pageSize=${pageSize}&sort=${sort}&direction=${direction}&booking_date_from=${dateFrom}&booking_date_to=${dateTo}`
                    + (search ? `&name=${search}` : '')
                    + (total_amount_from ? `&total_amount_from=${total_amount_from}` : '')
                    + (total_amount_to ? `&total_amount_to=${total_amount_to}` : '')
                    + (orders_count_from ? `&orders_count_from=${orders_count_from}` : '')
                    + (orders_count_to ? `&orders_count_to=${orders_count_to}` : '')
                    + (category ? `&category=${category}` : '')
                    + (service_ids ? `&service_ids=${service_ids}` : ''),
                jwt: getToken(),
            });
        } catch (error) {
            console.log(error)
        }
    };

    static getServiceCategoriesList = async () => {
        return BaseApi.getRequest({
            url: 'service/form-filter',
            jwt: getToken(),
        });
    };

    static getServiceSummary = async ({ dateFrom, dateTo }) => {
        try {
            return BaseApi.getRequest({
                url: `analytics/service/summary?booking_date_from=${dateFrom}&booking_date_to=${dateTo}`,
                jwt: getToken(),
            })
        } catch (error) {
            console.log(error)
        }

    }

    static makeExport = async ({ dateFrom, dateTo, ...filters }) => {
  
        console.log("makeData",
        filters)
        
        const exportDate = {
            format:"xlsx",
            action:"analytics_services_list",
            params:{
                from:dateFrom,
                to:dateTo
            }
        }
        return BaseApi.postRequest({
            url: 'export/create',
            data:  JSON.stringify(exportDate),
            jwt: getToken(),
            headers:{ "Content-Type": "application/json"}
        });
    };
}