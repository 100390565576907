import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  REFRESH_TOKEN,
  CHANGE_PASSWORD,
  SET_CHANGE_PASSWORD_ERROR,
  SET_CHANGE_PASSWORD_LOADING,
  SET_PASSWORD_CHANGED,
  SET_USER_ROLE,
  SET_USER_ROLES,
} from "./actionTypes"

export const changePassword = ({ oldPassword, newPassword, confirmationPassword }) => {
  return {
    type: CHANGE_PASSWORD,
    payload: {
      oldPassword,
      newPassword,
      confirmationPassword,
    }
  }
}

export const setChangePasswordError = (error) => {
  return {
    type: SET_CHANGE_PASSWORD_ERROR,
    payload: error,
  };
};

export const setChangePasswordLoading = (state) => {
  return {
    type: SET_CHANGE_PASSWORD_LOADING,
    payload: state,
  }
}

export const setPasswordChanged = (state) => {
  return {
    type: SET_PASSWORD_CHANGED,
    payload: state,
  }
}

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN,
  }
}

export const setUserRoles = (roles) => {
  return {
    type: SET_USER_ROLES,
    payload: roles,
  }
}
