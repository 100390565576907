import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Input } from 'reactstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import LagoonaTable from '../../components/LagoonaTable'
import {
  defaultColumnStyle,
  getPagSettings,
  getDefaultPaginationSettings,
} from '../../common/tableSettings'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import DatePicker from 'react-datepicker'
import ClientsAnalyticsFilter from './analytics-filter'
import { ExportButton } from '../../components/ExportButton';
import BootstrapTable from 'react-bootstrap-table-next';
import { Preloader } from '../../components/Preloader'
import { ClientsAnalyticsServices } from '../../services/clientsAnalyticsServices'
import moment from 'moment'
import { debounce } from 'lodash'
import { hMoney } from '../../utils/toHuman'
import { useHistory } from 'react-router-dom'
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';


/* eslint-disable react/display-name */

const makeColumns = (e) => {
  const columns = [
    {
      dataField: 'id',
      text: '#',
      sort: false,
      style: defaultColumnStyle,
      headerStyle: {
        width: '80px'
      },
      footer: 'Всего'
    },
    { dataField: 'fio', text: 'Клиент', sort: true, style: defaultColumnStyle, footer: '' },
    { dataField: 'phone', text: 'Телефон', sort: true, style: defaultColumnStyle, footer: '' },
    {
      dataField: 'count',
      text: 'Всего заказов',
      sort: true,
      style: defaultColumnStyle,
      footer: `${e?.count || 0}`
    },
    {
      dataField: 'discount_amount',
      text: 'Сумма скидки',
      sort: true,
      style: defaultColumnStyle,
      formatter: (cell) => <span>{cell}&#8381;</span>,
      footer: `${e?.discount_amount || 0}`
    },
    {
      dataField: 'total_amount',
      text: 'Общая сумма',
      sort: true,
      style: defaultColumnStyle,
      formatter: (cell) => <span>{cell}&#8381;</span>,
      footer: `${e?.total_amount || 0}`
    },
  ];
  return columns;
}

/* eslint-enable react/display-name */

const getOneMonthAgo = (date) => {
  const d = date;
  const m = date.getMonth();
  d.setMonth(d.getMonth() - 1);
  if (d.getMonth() == m) d.setDate(0);
  d.setHours(0, 0, 0, 0);
  return d;
}

const formatDate = (date) => {
  if (!date) {
    return;
  }
  let d = date;
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export default function ClientsAnalytics() {

  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [filter, setFilter] = useState();
  const [search, setSearch] = useState('');
  const [dateChange, setDateChange] = useState(0);

  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [startDate, setStartDate] = useState(getOneMonthAgo(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const [showFilters, setShowFilters] = useState(false);

  const [columns, setColumns] = useState(makeColumns());
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [exportStarted, setExportStarted] = useState(false);
  const [total, setTotal] = useState(0);

  function get_params() {
    return { pageSize, page: page - 1, sort: sortField, direction: sortOrder, search, dateFrom: formatDate(startDate), dateTo: formatDate(endDate), ...filter }
  }

  const handleExport = async () => {
    const dateFrom = moment().subtract(1, "month").format("YYYY-MM-DD");
    const dateTo = moment().format("YYYY-MM-DD");

    setExportStarted(true);
    toastr["success"]("Экспорт данных запущен", "Экспорт", exportToast)
    await ClientsAnalyticsServices.makeExport({ dateTo, dateFrom, ...filter }).finally(() => {
      setTimeout(() => {
        setExportStarted(false);
      }, 1500);
    });
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) setDateChange(dateChange + 1)
  };

  const onTableChanged = (type, { page, sizePerPage, sortOrder, sortField }) => {
    setPage(page);
    setPageSize(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  }

  const applyFilters = (newFilter) => {
    console.log(newFilter)
    setFilter(newFilter);
    setShowFilters(false);
  };

  useEffect(async () => {
    setLoading(true)
    const response = await ClientsAnalyticsServices.getClientsList(get_params());
    const data = response.data.data;
    setClientsList(makeData(data));
    setTotal(response.data.data.total);
    setLoading(false);
  }, [pageSize, page, filter, search, dateChange, sortField, sortOrder]);

  const makeData = (a) => {
    const data = (Array.isArray(a.items) ? a.items : Object.values(a.items)).map((e, i) => ({
      id: i + 1,
      client_id: e?.client_id,
      fio: e?.fio,
      phone: e?.phone,
      count: e?.count,
      discount_amount: hMoney(e?.discount_amount || 0),
      total_amount: hMoney(e?.total_amount || 0)
    }));
    return data;
  }

  useEffect(async () => {
    const dateFrom = formatDate(startDate);
    const dateTo = formatDate(endDate);
    const response = await ClientsAnalyticsServices.getClientsSummary({ dateFrom, dateTo })
    const data2 = response.data.data;
    setColumns(makeColumns(data2));
  }, [dateChange])

  const debouncedDispatch = debounce((_search) => {
    setSearch(_search);
  }, 400);

  const history = useHistory();

  const rowEvents2 = {
    onClick: (e, row, index) => {
      history.push({
        pathname: '/client-details',
        search: `?u=${row.client_id}`,
      });
    }
  };

  return (
    <>
      {/* {exportStarted && <ToastNotification title="Экспорт" content="Экспорт данных запущен" />} */}
      <LagoonaPageWrapper title='Аналитика.Клиенты'>
        <div style={{ display: 'flex', gap: 15 }}>
          <div style={{ display: 'flex', gap: 15 }}>
            <Input
              // eslint-disable-next-line react/prop-types
              onChange={(e) => debouncedDispatch(e.target.value)}
              placeholder='Поиск ФИО или телефону'
              style={{
                width: 300,
                height: 38
              }}
            />
          </div>
          <Col md={2}>
            <FormGroup>
              <DatePicker
                locale='ru'
                dateFormat='dd.MM.yyyy'
                className='form-control'
                placeholderText="Выберите период"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(range) => onDateChange(range)}
                isClearable={true}
              />
            </FormGroup>
          </Col>
          <div style={{ marginLeft: 'auto' }}>
            <button
              type='button'
              className='btn btn-outline-success mx-2'
              onClick={() => setShowFilters(true)}
            >
              Фильтры
            </button>
            <ExportButton onClick={handleExport} action={"analytics_clients_list"} />
          </div>
        </div>
        {loading ? <Preloader /> : (
          <BootstrapTable
            remote
            keyField="id"
            sort={{ dataField: sortField, order: sortOrder }}
            data={clientsList}
            columns={columns}
            headerWrapperClasses='table-light'
            wrapperClasses='mt-3'
            hover
            bordered={false}
            pagination={
              getPagSettings({ page, sizePerPage: pageSize, totalSize: total, length: total })
            }
            onTableChange={onTableChanged}
            rowEvents={rowEvents2}
          />)}
        <ClientsAnalyticsFilter show={showFilters} onApply={applyFilters} onCancel={() => setShowFilters(false)} />
      </LagoonaPageWrapper>
    </>
  )
}
