import MetaTags from "react-meta-tags";
import 'moment-timezone'
import React, { useState, useEffect } from "react";
import {
    Container,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup, CardTitle,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";

import Avatar from 'react-avatar-edit';

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

// actions
//import { ServiceServices } from "../../services/serviceServices";
import { changePassword, editProfile, getLastActivity, getProfileData, resetProfileFlag } from "../../store/actions";
import { ChangePassword } from "./ChangePassword";
import { AuthServices } from "../../services/authServices";
import { dataUrlToFile } from "../../utils/toHuman";
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr'

const UserProfile = props => {
    const dispatch = useDispatch();

    const [email, setemail] = useState("");
    const [name, setname] = useState("");
    const [idx, setidx] = useState(1);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [preview, setpreview] = useState(null);
    const [file, setFile] = useState(null)
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [errorUpload, setErrorUpload] = useState(false);
    const [srcAvatar, setSrcAvatar] = useState(null)
    const [showModalAvatar, setShowModalAvatar] = useState(false);
    const {
        error,
        success,
        profileData,
        lastActivity,
        changePasswordError,
        changePasswordLoading,
        isPasswordChanged
    } = useSelector(state => ({
        error: state.Profile.error,
        success: state.Profile.success,
        profileData: state.Profile.profileData,
        lastActivity: state.Profile.lastActivity,
        changePasswordError: state.Login.changePasswordError,
        changePasswordLoading: state.Login.changePasswordLoading,
        isPasswordChanged: state.Login.isPasswordChanged,
    }));

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName);
                setemail(obj.email);
                setidx(obj.uid);
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                setname(obj.username);
                setemail(obj.email);
                setidx(obj.uid);
            }
            setTimeout(() => {
                dispatch(resetProfileFlag());
            }, 3000);
        }
    }, [dispatch, success]);

    useEffect(() => {
        dispatch(getProfileData());
        dispatch(getLastActivity());
    }, []);

    useEffect(() => {
        if (!isPasswordChanged) {
            return;
        }

        setShowChangePassword(false);
        toastr['success']("Пароль успешно изменен", "Смена пароля", exportToast);

    }, [isPasswordChanged])

    const onChangePassword = ({ oldPassword, newPassword, confirmPassword }) => {
        dispatch(changePassword({ oldPassword, newPassword, confirmationPassword: confirmPassword }));
    };

    const onClose = () => {
        setpreview(null)
        //this.setState({preview: null})
    }

    const onCrop = (preview) => {
        setpreview(preview)
    }

    const appendAvatar = () => {
        setFile(preview);
        closeModalAvatar();
    }

    const openModalAvatar = () => {
        setShowModalAvatar(true)
    }
    const closeModalAvatar = () => {
        setShowModalAvatar(false)
    }



    useEffect(async () => {
        if (!file) {
            return;
        }
        const convert = await dataUrlToFile(file, 'avatar.png');
        const response = await AuthServices.changeProfilePicture({ picture: convert, id: profileData.user_id })
        console.log(response);
    }, [file])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Профиль</title>
                </MetaTags>
                <Container fluid>
                    <FormGroup row>
                        <Col>
                            <Card className="p-4 pb-0">
                                <CardTitle>Общая информация</CardTitle>
                                <CardBody>
                                    <FormGroup row>
                                        <Col md="auto">
                                            <img
                                                onClick={() => openModalAvatar()}
                                                src={profileData.avatar?.url}
                                                alt=""
                                                className="rounded-circle"
                                                style={{ width: '200px', height: '200px', maxWidth: '100%', marginRight: "20px" }}
                                            />
                                        </Col>
                                        <Col>
                                            {profileData.last_name &&
                                                <FormGroup row>
                                                    <Col>Фамилия:</Col>
                                                    <Col>{profileData.last_name}</Col>
                                                </FormGroup>
                                            }
                                            {profileData.first_name &&
                                                <FormGroup row>
                                                    <Col>Имя:</Col>
                                                    <Col>{profileData.first_name}</Col>
                                                </FormGroup>
                                            }
                                            {profileData.middle_name &&
                                                <FormGroup row>
                                                    <Col>Отчество:</Col>
                                                    <Col>{profileData.middle_name}</Col>
                                                </FormGroup>
                                            }
                                            {profileData.roles &&
                                                <FormGroup row>
                                                    <Col>Роль:</Col>
                                                    <Col>{profileData.roles.map(r => r.name).join(", ")}</Col>
                                                </FormGroup>
                                            }
                                            {profileData.position &&
                                                <FormGroup row>
                                                    <Col>Должность:</Col>
                                                    <Col>{profileData.position}</Col>
                                                </FormGroup>
                                            }
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                                <CardTitle>Контакты</CardTitle>
                                <CardBody>
                                    <Col md={8} sm="auto">
                                        {profileData.phone &&
                                            <FormGroup row>
                                                <Col>Телефон:</Col>
                                                <Col>{profileData.phone}</Col>
                                            </FormGroup>
                                        }
                                        {profileData.email &&
                                            <FormGroup row>
                                                <Col>Почта:</Col>
                                                <Col>{profileData.email}</Col>
                                            </FormGroup>
                                        }
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="p-4 pb-0">
                                <CardTitle>Безопасность</CardTitle>
                                <CardBody>
                                    <FormGroup row>
                                        <Col>Последняя активность:</Col>
                                        <Col>{lastActivity.last_login}</Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>Пароль:</Col>
                                        <Col>{lastActivity.last_password_change}</Col>
                                    </FormGroup>
                                    <FormGroup row className="mb-0">
                                        <Col>&nbsp;</Col>
                                        <Col className="text-left">
                                            <Button
                                                color="info"
                                                style={{ maxWidth: '300px' }}
                                                onClick={() => setShowChangePassword(true)}
                                            >
                                                Изменить пароль
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    </FormGroup>
                    <ChangePassword
                        show={showChangePassword}
                        onApply={onChangePassword}
                        errors={changePasswordError}
                        loading={changePasswordLoading}
                        onClose={() => setShowChangePassword(false)}
                    />
                </Container>
            </div>
            <Modal isOpen={showModalAvatar} centered>
                <ModalHeader>Изменить аватар</ModalHeader>
                <ModalBody>
                    <Avatar
                        width={466}
                        height={295}
                        imageWidth={350}
                        // imageHeight={350}
                        onCrop={onCrop}
                        onClose={onClose}
                        src={srcAvatar}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => appendAvatar()}>Применить</Button>
                    <Button onClick={() => closeModalAvatar()}>Отмена</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default withRouter(UserProfile);
