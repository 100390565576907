import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory from 'react-bootstrap-table2-editor'
import React from 'react'
import PropTypes from 'prop-types'

export const LagoonaTable = ({ editable = false, ...props }) => {
  return (
    <BootstrapTable
      // eslint-disable-next-line react/prop-types
      {...props}
      headerWrapperClasses='table-light'
      wrapperClasses='mt-3'
      hover
      bordered={false}
      cellEdit={editable ? cellEditFactory({ mode: 'click' }) : undefined}
    />
  )
}

LagoonaTable.propTypes = {
  editable: PropTypes.bool,
}

export default LagoonaTable
