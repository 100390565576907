import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  Button, Card, Col,
  FormGroup,
  Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Row
} from 'reactstrap'
import { OrdersService } from "../../services/ordersService";
import AsyncSelect from 'react-select/async';



const phoneList = [];

export default function OrdersFilter(props) {

  const [client, setClient] = useState(null);
  const [date_from, setDateFrom] = useState('');
  const [date_to, setDateTo] = useState('');
  const [status, setStatus] = useState(null);
  const [order_source, setSource] = useState(null);
  const [traffic_source, setTrafic] = useState(null);
  const [phone, setPhone] = useState(null);
  const [amount_from, setAmountFrom] = useState('');
  const [amount_to, setAmountTo] = useState('');
  const [booking_date_from, setPeriodFrom] = useState('');
  const [booking_date_to, setPeriodTo] = useState('');

  const resetFilters = () => {
    setClient(null)
    setDateFrom('')
    setDateTo('')
    setStatus(null)
    setPhone(null)
    setAmountFrom('')
    setAmountTo('')
    setSource(null)
    setTrafic(null)
    setPeriodFrom('')
    setPeriodTo('')
    sub()
  };


  const [statusList, setStatuses] = useState([]);
  const [traficList, setTrafics] = useState([]);
  const [orderSourceList, setOrdSourceList] = useState([]);
  useEffect(async () => {
    const response = await OrdersService.getOrdersFilter();
    let res = [];
    let statuses = response?.data?.data?.statuses || {};
    Object.keys(statuses).forEach(key => {
      res.push({ value: statuses[key].id, label: statuses[key].name });
    });
    setStatuses(res);


    res = [];
    let trafics = response?.data?.data.traffic_source || {};
    Object.keys(trafics).forEach(key => {
      res.push({ value: trafics[key].id, label: trafics[key].name });
    });
    setTrafics(res);


    res = [];
    let order_sources = response?.data?.data.order_source || {};
    Object.keys(order_sources).forEach(key => {
      res.push({ value: order_sources[key].id, label: order_sources[key].name });
    });
    setOrdSourceList(res);

  }, [true]);



  const getOptionsPhone = async (input) => {
    let users = await OrdersService.getUsers({ phone: input });
    users = users.data.data.items;
    let res = [];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.phone });
    });
    return res;
  }

  const getOptionsFio = async (input) => {
    let users = await OrdersService.getUsers({ fio: input });
    users = users.data.data.items;
    let res = [];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.fullName });
    });
    return res;
  }

  const sub = () => {
    let client_id = null;
    if (client) {
      client_id = client.value;
    }
    else if (phone) {
      client_id = phone.value;
    }
    props.onApply({
      date_from,
      date_to,
      status,
      order_source,
      traffic_source,
      amount_from,
      amount_to,
      booking_date_from,
      booking_date_to,
      client_id
    })
  }


  return (
    <div className="d-flex justify-content-center">
      <Modal isOpen={props.show} centered size="lg">
        <ModalHeader toggle={props.onCancel}>
          Фильтр
        </ModalHeader>
        <ModalBody>
          <Card>
            <Row>
              <Col>
                <FormGroup row>
                  <Label for="user">Клиент</Label>
                  <AsyncSelect loadOptions={getOptionsFio} onChange={e => { setClient(e); setPhone(null) }} value={client} id="user" placeholder="Поиск" />
                </FormGroup>
                <Label md="auto" for="startDate">Дата создания</Label>
                <FormGroup row className={"align-items-baseline"}>
                  <Col>
                    <Input value={date_from} id="startDate" onChange={e => setDateFrom(e.target.value)} type="date" />
                  </Col>
                  -
                  <Col>
                    <Input value={date_to} id="toDate" onChange={e => setDateTo(e.target.value)} type="date" className={"mb-0"} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md="auto" for="orderSource">Источник заказа</Label>
                  <Select value={order_source} id="orderSource" onChange={e => setSource(e)} options={orderSourceList} placeholder="Поиск" />
                </FormGroup>
                <FormGroup row>
                  <Label md="auto" for="statuses">Статус</Label>
                  <Select value={status} id="statuses" onChange={e => setStatus(e)} options={statusList} placeholder="Поиск" />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup row>
                  <Label for="phone">Номер телефона</Label>
                  <AsyncSelect id="phone" onChange={e => { setPhone(e); setClient(null) }} value={phone} loadOptions={getOptionsPhone} placeholder="Поиск" />
                </FormGroup>
                <Label md="auto" for="booking_date_from" >Период заказа</Label>
                <FormGroup row className={"align-items-baseline"}>
                  <Col>
                    <Input value={booking_date_from} onChange={(e) => setPeriodFrom(e.target.value)} id="booking_date_from" type="date" />
                  </Col>
                  -
                  <Col>
                    <Input value={booking_date_to} onChange={(e) => setPeriodTo(e.target.value)} id="booking_date_to" type="date" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md="auto" for="trafficSource">Источник трафика</Label>
                  <Select value={traffic_source} id="trafficSource" onChange={e => setTrafic(e)} options={traficList} placeholder="Поиск" />
                </FormGroup>
                <Label md="auto" for="fromCost">Сумма</Label>
                <FormGroup row className={"align-items-baseline"}>
                  <Col>
                    <Input value={amount_from} onChange={e => setAmountFrom(e.target.value)} id="fromCost" />
                  </Col>
                  -
                  <Col>
                    <Input value={amount_to} onChange={e => setAmountTo(e.target.value)} id="toCost" />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={sub}>Применить</Button>
          <Button onClick={resetFilters}>Сброс</Button>
        </ModalFooter>
      </Modal>
    </div>
  )

}
OrdersFilter.propTypes = {
  show: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.string),
    minPeople: PropTypes.number,
    maxPeople: PropTypes.number,
    units: PropTypes.arrayOf(PropTypes.string),
    minCost: PropTypes.number,
    maxCost: PropTypes.number
  })
};
