import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row, Toast, ToastBody, ToastHeader } from 'reactstrap'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { useHistory, useLocation } from "react-router-dom";
import Select from 'react-select';
import { ClientsServices } from "../../services/clientsServices";
import { Preloader } from "../../components/Preloader";
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';

const userStatuses = [
    { value: 'ACTIVE', label: 'Активный', },
    { value: 'DISABLED', label: 'Удален', },
];

export default function EditClient() {
    const history = useHistory();
    const { search } = useLocation();
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState({});
    const [editedUser, setEditedUser] = useState({});
    const [isUserUpdated, setIsUserUpdated] = useState(false);
    const [error, setError] = useState(false);

    const defaultStatus = useMemo(() => {
        if (!editedUser.status) {
            return userStatuses[1];
        }

        return userStatuses.find(status => status.value === editedUser.status);
    }, [editedUser]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        await ClientsServices
            .updateClient(userId, editedUser)
            .then(() => {
                setIsUserUpdated(true);
                toastr['success']('Клиент успешно отредактирован', 'Редактирование клиента', exportToast);
            }).catch(setError);
    };

    useEffect(() => {
        if (!isUserUpdated) {
            return;
        }

        setTimeout(() => {
            setIsUserUpdated(false);
            history.goBack();
        }, 1500);
    }, [isUserUpdated]);

    useEffect(() => {
        const id = new URLSearchParams(search);
        setUserId(id.get("u"));
    }, []);

    useEffect(async () => {
        if (!userId) {
            return;
        }

        setLoading(true);
        const userResponse = await ClientsServices.getClientInfo(userId);
        setLoading(false);
        setUser(userResponse.data.data);
        setEditedUser(userResponse.data.data);
    }, [userId]);

    return (
        <LagoonaPageWrapper title='Редактирование клиента'>
            {loading ? <Preloader /> : (
                <Form onSubmit={handleFormSubmit}>
                    {error && <Alert color="danger">{error}</Alert>}
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label for='lastName'>Фамилия</Label>
                                <Input id='lastName' name='lastName' defaultValue={user.last_name} onChange={({ target: { value } }) => setEditedUser({ ...editedUser, last_name: value })} />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for='firstName'>
                                    Имя <b className='text-danger'>*</b>
                                </Label>
                                <Input id='firstName' name='firstName' defaultValue={user.first_name} onChange={({ target: { value } }) => setEditedUser({ ...editedUser, first_name: value })} />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for='middleName'>Отчество</Label>
                                <Input id='middleName' name='middleName' defaultValue={user.middle_name} onChange={({ target: { value } }) => setEditedUser({ ...editedUser, middle_name: value })} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='email'>Эл. почта</Label>
                                <Input id='email' name='email' type='email' defaultValue={user.email} onChange={({ target: { value } }) => setEditedUser({ ...editedUser, email: value })} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='phone'>
                                    Номер телефона <b className='text-danger'>*</b>
                                </Label>
                                <Input id='phone' name='phone' type='tel' defaultValue={user.phone} onChange={({ target: { value } }) => setEditedUser({ ...editedUser, phone: value })} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='dob'>Дата рождения</Label>
                                <Input id='dob' name='dob' type='date' defaultValue={user.birthday} onChange={({ target: { value } }) => setEditedUser({ ...editedUser, birthday: value })} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='discount'>Фиксированная скидка</Label>
                                <Input id='discount' name='discount' defaultValue={user.discount} onChange={({ target: { value } }) => setEditedUser({ ...editedUser, discount: value })} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='status-select'>Статус</Label>
                                <Select options={userStatuses} onChange={(value) => setEditedUser({ ...editedUser, status: value.value })}
                                    value={defaultStatus} isDisabled={true} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='id'>Идентификатор</Label>
                                <Input id='id' name='id' readOnly value={user.id} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button color='success' type="submit" onClick={function noRefCheck() {
                    }}>
                        Сохранить
                    </Button>{' '}
                    <Button onClick={() => history.goBack()} className='mx-2'>
                        Отмена
                    </Button>
                </Form>
            )}
        </LagoonaPageWrapper>
    )
}
