import React, { useEffect, useRef, useState } from 'react'
import {
    Alert,
    Button,
    Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import LagoonaTable from '../../components/LagoonaTable'
import {
    defaultColumnStyle,
    getDefaultPaginationSettings, getPagSettings,
} from '../../common/tableSettings'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { Link, useHistory } from 'react-router-dom'
import moment from "moment";
import ClientsFilter from './filter'
import { ExportButton } from '../../components/ExportButton';
import BootstrapTable from "react-bootstrap-table-next";
import { clientsColumns } from "../Clients";
import { ServiceServices } from "../../services/serviceServices";
import { Preloader } from "../../components/Preloader";
import { ClientsServices } from "../../services/clientsServices";
import { debounce } from "lodash";
import { useSelector } from 'react-redux'
import toastr from "toastr";
import { exportToast } from '../../utils/toastr';

const { SearchBar } = Search

/* eslint-disable react/display-name */
export const serviceColumns = [
    {
        dataField: 'name',
        text: 'Наименование',
        style: defaultColumnStyle,
        headerStyle: {
            width: '140px',
        },
        formatter: (cell) => <span>{cell}</span>
    },
    { dataField: 'category', text: 'Категория', style: defaultColumnStyle },
    { dataField: 'people_count', text: 'Кол-во человек', style: defaultColumnStyle },
    { dataField: 'unit', text: 'Усл. единица', style: defaultColumnStyle },
    { dataField: 'min', text: 'Мин. значение', style: defaultColumnStyle },
    {
        dataField: 'start_time',
        text: 'Время начала',
        headerStyle: {
            width: '140px',
        },
        style: defaultColumnStyle,
        formatter: (cell) => <span>{cell}</span>
    },
    {
        dataField: 'finish_time',
        text: 'Время завершения',
        headerStyle: {
            width: '140px',
        },
        style: defaultColumnStyle,
        formatter: (cell) => <span>{cell}</span>
    },
    {
        dataField: 'price',
        text: 'Стоимость',
        style: defaultColumnStyle,
        formatter: (cell) => <span>{cell}&#8381;</span>
    },
];
/* eslint-enable react/display-name */

export const servicesData = Array.from(Array(9)).map((_, id) => ({
    id,
    name: 'Гостиница 1, № пом. 001',
    persons: '2',
    unit: 'сутки/номер',
    price: 2300,
    category: 'Категория №' + Math.floor(Math.random() * 10),
    minValue: Math.floor(Math.random() * 10),
    startDate: new Date(),
    endDate: new Date()
}))

function CreateServiceForm() {
    return (
        <Form>
            <FormGroup row>
                <Label for='role' sm={2}>
                    Роль <b className='text-danger'>*</b>
                </Label>
                <Col sm={10}>
                    <Input id='role' name='select' type='select'>
                        <option>Администратор</option>
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for='name' sm={2}>
                    ФИО <b className='text-danger'>*</b>
                </Label>
                <Col sm={10}>
                    <Input id='name' name='name' />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for='phone' sm={2}>
                    Номер телефона <b className='text-danger'>*</b>
                </Label>
                <Col sm={10}>
                    <Input id='phone' name='phone' type='tel' />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for='position' sm={2}>
                    Должность
                </Label>
                <Col sm={10}>
                    <Input id='position' name='position' />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for='email' sm={2}>
                    Эл. почта
                </Label>
                <Col sm={10}>
                    <Input id='email' name='email' type='email' />
                </Col>
            </FormGroup>
        </Form>
    )
}

export default function Services() {

    const fileRef = useRef(null);

    const [createEmployee, setCreateEmployee] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [showAddMenu, setShowAddMenu] = useState(false);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [servicesData, setServicesData] = useState({ items: [], total: 0 });
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [filters, setFilters] = useState({});

    const toggle = () => setCreateEmployee(!createEmployee);
    const history = useHistory();

    const rowEvents = {
        onClick: (e, row, index) => {
            if (window.getSelection().toString()) {
                return;
            }

            history.push({
                pathname: `/service-details`,
                search: `?u=${row.id}`,
            });
        }
    };

    const applyFilters = async (filters) => {
        setFilters(filters);
        setShowFilters(false);
    };

    const resetFilters = async () => {
        setFilters({});
        setLoading(true);
        const response = await ServiceServices.getServiceList({ page, pageSize, searchTerm });
        if (response?.data?.data) {
            setServicesData(response.data.data);
        }
        setLoading(false);
        setShowFilters(false);
    }

    const onTableChanged = (type, { page, sizePerPage, sortOrder }) => {
        setPage(page);
        setPageSize(sizePerPage);
    }

    const debouncedDispatch = debounce((_searchTerm) => {
        setSearchTerm(_searchTerm);
    }, 500);

    const handleExport = async () => {
        const dateFrom = moment().subtract(1, "month").format("YYYY-MM-DD");
        const dateTo = moment().format("YYYY-MM-DD");

        toastr['success']('Экспорт данных запущен', 'Экспорт', exportToast);
        await ServiceServices.makeExport({ dateTo, dateFrom })
    };

    const handleFileChange = (e) => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
    }

    useEffect(async () => {
        if (!file) {
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        ServiceServices
            .uploadFromFile(formData)
            .then(_ => toastr['success']('Импорт данных запущен', 'Импорт', exportToast))
            .catch(setError);
    }, [file]);

    useEffect(async () => {
        setLoading(true);
        const response = await ServiceServices.getServiceList({
            page, pageSize, searchTerm, people_count_from: filters.minFilter,
            people_count_to: filters.maxFilter,
            categoriesList: filters.categoryFilter,
            unitFilter: filters.unitFilter,
            price_min: filters.minPriceFilter,
            price_max: filters.maxPriceFilter,
        });
        if (response?.data?.data) {
            setServicesData(response.data.data);
        }
        setLoading(false);
    }, []);

    useEffect(async () => {

        setLoading(true);
        const response = await ServiceServices.getServiceList({
            page, pageSize, searchTerm, people_count_from: filters.minFilter,
            people_count_to: filters.maxFilter,
            categoriesList: filters.categoryFilter,
            unitFilter: filters.unitFilter,
            price_min: filters.minPriceFilter,
            price_max: filters.maxPriceFilter,
        });
        if (response?.data?.data) {
            setServicesData(response.data.data);
        }
        setLoading(false);
    }, [filters]);

    useEffect(async () => {
        setLoading(true);
        const response = await ServiceServices.getServiceList({
            pageSize, page, searchTerm, people_count_from: filters.minFilter,
            people_count_to: filters.maxFilter,
            categoriesList: filters.categoryFilter,
            unitFilter: filters.unitFilter,
            price_min: filters.minPriceFilter,
            price_max: filters.maxPriceFilter,
        });
        if (response?.data?.data) {
            setServicesData(response.data.data);
        }
        setLoading(false);
    }, [pageSize, page]);

    useEffect(async () => {
        setLoading(true);
        const response = await ServiceServices.getServiceList({
            searchTerm, page, pageSize, people_count_from: filters.minFilter,
            people_count_to: filters.maxFilter,
            categoriesList: filters.categoryFilter,
            unitFilter: filters.unitFilter,
            price_min: filters.minPriceFilter,
            price_max: filters.maxPriceFilter,
        });
        setLoading(false);
        if (response?.data?.data) {
            setServicesData(response.data.data);
        }
    }, [searchTerm]);

    console.log({ showFilters });

    const { roles } = useSelector(state => {
        return {
            roles: state.Login.roles,
        }
    })

    const adminAccess = (roles) => {
        if (roles[0] === 'ADMIN') return false;
        else return true;
    }

    const restrictAccess = (roles) => {
        if (roles[0] === 'ADMIN') return false;
        if (roles[0] === 'OPERATOR') return true;
        else return false;
    }

    return (
        <LagoonaPageWrapper title='Услуги'>
            <input type="file" ref={fileRef} onChange={handleFileChange} onClick={e => e.target.value = ""}
                style={{ display: "none" }} />
            {error && <Alert color="danger">{error}</Alert>}
            <div style={{ display: 'flex', gap: 15, marginBottom: "24px" }}>
                <div style={{ display: 'flex', gap: 15 }}>
                    <Input
                        onChange={(e) => debouncedDispatch(e.target.value)}
                        placeholder='Поиск по наименованию'
                        style={{
                            width: 300,
                        }}
                    />
                </div>
                <div style={{ marginLeft: 'auto' }}>
                    <button
                        type='button'
                        className='btn btn-outline-success mx-2'
                        onClick={() => setShowFilters(true)}
                    >
                        Фильтры
                    </button>
                    {restrictAccess(roles) ? <></> : <ExportButton onClick={handleExport} action={"services_list"} />}
                    <Dropdown
                        isOpen={showAddMenu}
                        toggle={() => setShowAddMenu(!showAddMenu)}
                        className="dropdown d-inline-block ms-2"
                    >
                        {adminAccess(roles) ? <></> : <DropdownToggle color="success" caret>
                            Добавить услугу
                        </DropdownToggle>}
                        <DropdownMenu end>
                            <DropdownItem>
                                <Link to='/add-service'>
                                    <Button
                                        color="success"
                                        style={{ width: 200 }}
                                    >
                                        Создать услугу
                                    </Button>
                                </Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Button
                                    onClick={() => fileRef.current.click()}
                                    color="success"
                                    style={{ width: 200 }}
                                >
                                    Импорт из xls
                                </Button>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                </div>
            </div>
            {loading ? <Preloader /> : (
                <BootstrapTable
                    remote
                    keyField="id"
                    data={servicesData.items}
                    columns={serviceColumns}
                    headerWrapperClasses='table-light'
                    wrapperClasses='mt-3 table-responsive'
                    hover
                    bordered={false}
                    pagination={
                        getPagSettings({
                            page,
                            sizePerPage: pageSize,
                            totalSize: servicesData.total,
                            length: servicesData.total
                        })
                    }
                    onTableChange={onTableChanged}
                    rowEvents={rowEvents}
                />
            )}
            <Modal isOpen={createEmployee} toggle={toggle}>
                <ModalHeader toggle={toggle}>Добавление услуги</ModalHeader>
                <ModalBody>
                    <CreateServiceForm />
                </ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={function noRefCheck() {
                    }}>
                        Добавить услугу
                    </Button>{' '}
                    <Button onClick={toggle}>Отмена</Button>
                </ModalFooter>
            </Modal>
            <ClientsFilter show={showFilters} onApply={applyFilters} onReset={resetFilters}
                onCancel={() => setShowFilters(false)} />
        </LagoonaPageWrapper>
    )
}
