import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class BillingAnalyticsServices {

    static getIncomeList = async (
        {
            pageSize = 10,
            page = 0,
            sort = "total_amount",
            direction = "desc",
            dateFrom,
            dateTo,
            invoices_count_from,
            invoices_count_to,
            payments_count_from,
            payments_count_to,
            orders_count_from,
            orders_count_to,
            services_count_from,
            services_count_to,
            total_amount_from,
            total_amount_to,
        }) => {
        try {
            return BaseApi.getRequest({
                url: `analytics/income/list?page=${page}&pageSize=${pageSize}&sort=${sort}&direction=${direction}&date_from=${dateFrom}&date_to=${dateTo}`
                    + (invoices_count_from ? `&invoices_count_from=${invoices_count_from}` : '')
                    + (invoices_count_to ? `&invoices_count_to=${invoices_count_to}` : '')
                    + (payments_count_from ? `&payments_count_from=${payments_count_from}` : '')
                    + (payments_count_to ? `&payments_count_to=${payments_count_to}` : '')
                    + (orders_count_from ? `&orders_count_from=${orders_count_from}` : '')
                    + (orders_count_to ? `&orders_count_to=${orders_count_to}` : '')
                    + (services_count_from ? `&services_count_from=${services_count_from}` : '')
                    + (services_count_to ? `&services_count_to=${services_count_to}` : '')
                    + (total_amount_from ? `&total_amount_from=${total_amount_from}` : '')
                    + (total_amount_to ? `&total_amount_to=${total_amount_to}` : ''),
                jwt: getToken(),
            });
        } catch (error) {
            console.log(error)
        }
    };

    static getIncomeSummary = async ({ dateFrom, dateTo }) => {
        try {
            return BaseApi.getRequest({
                url: `analytics/income/summary?date_from=${dateFrom}&date_to=${dateTo}`,
                jwt: getToken(),
            });
        } catch (error) {
            console.log(error)
        }

    }

    static makeExport = async ({ dateFrom, dateTo }) => {
        const formData = new FormData();
        formData.append("format", "xlsx");
        formData.append("action", "analytics_income_list");
        formData.append("params[from]", dateFrom);
        formData.append("params[to]", dateTo);

        return BaseApi.postRequest({
            url: 'export/create',
            data: formData,
            jwt: getToken(),
        });
    };
}