import {getToken} from "../helpers/common";
import {BaseApi} from "../helpers/api";

export class EmployeeService {
    static getEmployeeList = async ({searchTerm = "", pageSize = 10000, page = 1}) => {
        return BaseApi.getRequest({
            url: `employee/list?page=${page}&pageSize=${pageSize}&sort=last_name&direction=desc&column=${searchTerm}`,
            jwt: getToken(),
        });
    };

    static getRolesList = async () => {
        return BaseApi.getRequest({
            url: 'employee/form-filter',
            jwt: getToken(),
        });
    };

    static createUser = async (cred) => {
        const formData = new FormData();
        formData.append("last_name", cred.fio);
        formData.append("first_name", cred.name);
        formData.append("middle_name", cred.middName);
        formData.append("phone", cred.phone);
        cred.email ? formData.append("email", cred.email) : null;
        cred.position ? formData.append("position", cred.position) : null;
        cred.roles ? formData.append("roles", cred.role) : null;

        return BaseApi.postRequest({
            url: 'employee/create',
            jwt: getToken(),
            data: formData,
        });
    }

    static getEmplInfo = async (id) => {
        return BaseApi.getRequest({
            url: `employee/${id}/read`,
            jwt: getToken(),
        });
    }

    static deleteEmploye = async id => {
        return BaseApi.deleteRequest({
            url: `employee/${id}/delete`,
            jwt: getToken(),
        });
    }

    static updateUser = async (id, cred) => {
        const formData = new FormData();
        formData.append("first_name", cred.first_name);
        formData.append("phone", cred.phone);
        cred.middle_name ? formData.append("middle_name", cred.middle_name) : null;
        cred.status ? formData.append("status", cred.status) : null;
        cred.last_name ? formData.append("last_name", cred.last_name) : null;
        cred.email ? formData.append("email", cred.email) : null;
        cred.position ? formData.append("position", cred.position) : null;
        if (cred.roles) {
            _.isArray(cred.roles)? 
             formData.append("roles", cred.roles.map(role => role.id).join(",")) :
             formData.append("roles", cred.roles) ;

        }



        return BaseApi.postRequest({
            url: `employee/${id}/update`,
            jwt: getToken(),
            data: formData,
        });
    }
}