import React, { useEffect, useState } from 'react'
import { Col, Spinner, FormGroup, Label } from 'reactstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import PaymentsCard from './PaymentsCard'
import OrderSourcesCard from './OrderSourcesCard'
import OrdersStatsCard from './OrdersStatsCard'
import IncomeChartCard from './IncomeChartCard'
import TopServicesCard from './TopServicesCard'
import TopOrdersCard from './TopOrdersCard'
import TopClientsCard from './TopClientsCard'
import TrafficSourcesCard from './TrafficSourcesCard'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDashboardSummary,
  getPaymentMethodData,
  getOrderSourceData,
  getTrafficSourceData,
} from '../../store/dashboard/actions'
import { Redirect } from 'react-router-dom'

registerLocale('ru', ru)

const cashTotal = 1947000
const nonCashTotal = 3953000

export function Dashboard() {
  const dispatch = useDispatch()
  // const [dateRange, setDateRange] = useState([null, null])
  // const [startDate, endDate] = dateRange

  const getOneMonthAgo = (date) => {
    const d = date;
    const m = date.getMonth();
    d.setMonth(d.getMonth() - 1);
    if (d.getMonth() == m) d.setDate(0);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  const formatDate = (date) => {
    if (!date) {
      return;
    }
    let d = date;
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const [dateChange, setDateChange] = useState(0);
  const [startDate, setStartDate] = useState(getOneMonthAgo(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) setDateChange(dateChange + 1)
  };


  const {
    dashboardState,
    dashboardData,
    paymentData,
    paymentState,
    orderSourceData,
    orderSourceState,
    trafficSourceData,
    trafficSourceState,
    roles,
  } = useSelector(state => {
    return {
      dashboardState: state.Dashboard.dashboardState,
      dashboardData: state.Dashboard.dashboardSummary,
      paymentData: state.Dashboard.paymentMethodData,
      paymentState: state.Dashboard.paymentMethodState,
      orderSourceData: state.Dashboard.orderSourceData,
      orderSourceState: state.Dashboard.orderSourceState,
      trafficSourceData: state.Dashboard.trafficSourceData,
      trafficSourceState: state.Dashboard.trafficSourceState,
      roles: state.Login.roles,
    }
  })

  useEffect(() => {
    dispatch(getDashboardSummary(formatDate(startDate), formatDate(endDate)))
    dispatch(getPaymentMethodData(formatDate(startDate), formatDate(endDate)))
    dispatch(getOrderSourceData(formatDate(startDate), formatDate(endDate)))
    dispatch(getTrafficSourceData(formatDate(startDate), formatDate(endDate)))
  }, [dateChange])

  // console.log({ paymentData });

  return (
    roles[0] !== "ADMIN" ? <Redirect to="/orders" /> :
      <LagoonaPageWrapper title='Dashboard' disableContainer>
        <FormGroup>
          <Col md={3}>
            <Label for='datePeriod'>Период</Label>
            <DatePicker
              locale='ru'
              dateFormat='dd.MM.yyyy'
              className='form-control'
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={range => onDateChange(range)}
              isClearable={true}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col className='d-grid'>
            <PaymentsCard
              cashTotal={paymentData?.cash || 0}
              nonCashTotal={paymentData?.noncash || 0}
              state={paymentState}
            />
            <OrderSourcesCard
              state={orderSourceState}
              fromSite={orderSourceData?.site || 0}
              fromReception={orderSourceData?.reception || 0}
              fromCall={orderSourceData?.call || 0}
            />
          </Col>
          <Col md='8'>
            <OrdersStatsCard
              state={dashboardState}
              totalOrders={dashboardData?.orders_count || 0}
              totalIncome={dashboardData?.total_income || 0}
              middleCheck={dashboardData?.average_paycheck || 0}
              totalBills={dashboardData?.invoices_count || 0}
              totalPays={dashboardData?.payments_count || 0}
            />
            <IncomeChartCard dateRange={[formatDate(startDate), formatDate(endDate)]} dateChange={dateChange} />
          </Col>
        </FormGroup>
        <FormGroup>
          <TopServicesCard dateRange={[formatDate(startDate), formatDate(endDate)]} dateChange={dateChange} />
          <TopOrdersCard dateRange={[formatDate(startDate), formatDate(endDate)]} dateChange={dateChange} />
          <TopClientsCard dateRange={[formatDate(startDate), formatDate(endDate)]} dateChange={dateChange} />
          <TrafficSourcesCard items={trafficSourceData?.data.items} state={trafficSourceState} />
        </FormGroup>
      </LagoonaPageWrapper>
  )
}

export default Dashboard
