import {BaseApi} from "../helpers/api";
import {getToken} from "../helpers/common";

export class ClientsServices {
    static getClientsList = async ({searchTerm = "", pageSize = 10, page = 0, sort = "id", direction = "asc"}) => {
        return BaseApi.getRequest({
            url: `client/list?page=${page}&pageSize=${pageSize}&sort=id&direction=asc&column=${searchTerm}`,
            jwt: getToken(),
        }).then(data => {
            data?.data?.data?.items?.map((c, ci) => {
                c.index = ci + 1
                return c
            })
            return data || []
        });
    };

    static makeExport = async ({dateFrom, dateTo}) => {
        const formData = new FormData();
        formData.append("format", "xlsx");
        formData.append("action", "clients_list");
        formData.append("params[from]", dateFrom);
        formData.append("params[to]", dateTo);

        return BaseApi.postRequest({
            url: 'export/create',
            data: formData,
            jwt: getToken(),
        });
    };

    static getExportList = async () => {
        return BaseApi.getRequest({
            url: "export/list?page=0&pageSize=1000&sort=id&direction=desc&action=clients_list",
            jwt: getToken(),
        });
    };

    static deleteExportItem = async id => {
        return BaseApi.deleteRequest({
            url: `export/${id}/delete`,
            jwt: getToken(),
        });
    }

    static createClient = async (cred) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        formData.append("first_name", cred.first_name);
        cred.email ? formData.append("email", cred.email) : null;
        cred.last_name ? formData.append("last_name", cred.last_name) : null;
        cred.middle_name ? formData.append("middle_name", cred.middle_name) : null;
        cred.birthday ? formData.append("birthday", cred.birthday) : null;
        cred.discount ? formData.append("discount", cred.discount) : null;

        return BaseApi.postRequest({
            url: "client/create",
            jwt: getToken(),
            data: formData,
        })
    };

    static getClientInfo = async (id) => {
        return BaseApi.getRequest({
            url: `client/${id}/read`,
            jwt: getToken(),
        });
    };

    static getClientAnalytic = async id => {
        return BaseApi.getRequest({
            url: `client/${id}/analytics`,
            jwt: getToken(),
        });
    };

    static deleteClient = async id => {
        return BaseApi.deleteRequest({
            url: `client/${id}/delete`,
            jwt: getToken(),
        });
    };

    static updateClient = async (id, cred) => {
        const formData = new FormData();

        formData.append("phone", cred.phone);
        formData.append("first_name", cred.first_name);
        cred.email ? formData.append("email", cred.email) : null;
        cred.last_name ? formData.append("last_name", cred.last_name) : null;
        cred.middle_name ? formData.append("middle_name", cred.middle_name) : null;
        cred.birthday ? formData.append("birthday", cred.birthday) : null;
        cred.discount ? formData.append("discount", cred.discount) : null;

        return BaseApi.postRequest({
            url: `client/${id}/update`,
            data: formData,
            jwt: getToken(),
        });
    };
}
