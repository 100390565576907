import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { restorePasswordRequest } from "../../store/actions";
import CarouselPage from '../../components/CarouselPage'
import { useHistory } from 'react-router-dom'

import laguna from "../../assets/images/laguna_logo.svg";


const ForgetPasswordPage = props => {
  const dispatch = useDispatch();

  const history = useHistory();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Введите номер телефона"),
    }),

    onSubmit: (values) => {
      dispatch(restorePasswordRequest(values.email, history));
    }
  });

  const { error, token } = useSelector(state => {
    return {
      error: state.ForgetPassword.error,
      status: state.ForgetPassword.status,
      token: state.ForgetPassword.token,
    }
  })

  const customSubmit = (e) => {
    e.preventDefault();
    validation.handleSubmit();
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>
            Восстановление пароля
          </title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage number={2} />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={laguna}
                          alt="logo"
                          height="64"
                          className="auth-logo-dark"
                        />
                      </Link>
                    </div>

                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Восстановление пароля</h5>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => customSubmit(e)}
                        >
                          {error.error ? <Alert color='danger'>{error.error}</Alert> : null}

                          <div
                            className="alert alert-success text-center mb-4"
                            role="alert"
                          >
                            Введите номер телефона, который указан в системе
                          </div>

                          <div className="mb-3">
                            <Label for="email" className="form-label">
                              Номер телефона
                            </Label>
                            <Input
                              name="email"
                              placeholder="Введите номер телефона"
                              className={"form-control"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email}
                              invalid={!!(validation.touched.email && validation.errors.email)}
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-4 w-100">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              {" "}
                              Продолжить{" "}
                            </button>
                          </div>
                        </Form>

                        <div className="mt-4 text-center">
                          <p>
                            Вспомнили пароль?{" "}
                            <Link to="login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Войти{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
