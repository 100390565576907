import React from 'react';
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, CardActions, Row } from 'reactstrap'
import { useEffect, useState } from 'react';
import { BillingServices } from 'services/billingService';
import { Link, useHistory, useLocation } from 'react-router-dom'
import ConfirmDialog from 'components/ConfirmDialog';
import StatusServ from 'services/statusServ';
import { indexOf } from 'lodash';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';


export default function BillDetails() {
  const [data, setData] = useState({});
  const [methods, setMethods] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');

  const history = useHistory();

  useEffect(async () => {
    let filter = await BillingServices.getInvoicesFilter();
    setMethods(filter.data.data.methods);
  }, [true]);


  const { search } = useLocation();
  useEffect(async () => {
    const id = new URLSearchParams(search).get("u");
    let invoice = await BillingServices.getInvoice(id);

    setData(invoice?.data?.data);
    setPhone(invoice?.data?.data.order?.client_phone);
    setEmail(invoice?.data?.data.order?.client_email);
    setAmount(invoice?.data?.data.order?.total_amount);
  }, [true]);
  const sendChannel = async (channel) => {
    toastr['success']('Счёт успешно отправлен', 'Успешно', exportToast);
    await BillingServices.sendChannel(data.id, channel).catch(e => {
      toastr['error'](e, 'Ошибка', exportToast);
    });
  }


  const deleteBill = async (id) => {
    setShowConfirm(false);
    await BillingServices.delBill(data.id).catch(e => {
      toastr['error'](e, 'Ошибка', exportToast);
    });
    toastr['success']('Счёт успешно удален', 'Счет удален', exportToast);
    setTimeout(() => history.goBack(), 1500);
  };

  function copyStringToClipboard(str) {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  return (

    <div className="page-content">
      <Container fluid>
        <Card className="p-4">
          <FormGroup row>
            <Col>
              <CardTitle>Карточка счета №{data.id}</CardTitle>
            </Col>
            <Col md={2} className={"text-end"}>
              <Button color={StatusServ.getByTag(data.status).color} style={{ minWidth: "120px" }}>{data.statusText}</Button>
            </Col>
          </FormGroup>

          <CardBody>
            <FormGroup row>
              <Col>
                <Label md="auto" for='orderId'>Заказ <b className='text-danger'>*</b></Label>
                <Input defaultValue={data?.order?.number} id='orderId' name='orderId' readOnly />
              </Col>
              <Col>
                <Label md="auto" for='phone'>Номер телефона</Label>
                <Input value={phone} id='phone' name='phone' readOnly />
              </Col>
              <Col>
                <Label md="auto" for='email'>Эл.почта</Label>
                <Input value={email} id='email' name='email' readOnly />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col>
                <Label md="auto" for='orderName'>Наименование платежа</Label>
                <Input defaultValue={data?.name} id='orderName' name='orderName' readOnly />
              </Col>
              <Col>
                <Label md="auto" for='orderTarget'>Назначение платежа</Label>
                <Input defaultValue={data?.purpose} id='orderTarget' name='orderTarget' readOnly />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col>
                <Label md="auto" for='totalOrder'>Сумма заказа</Label>
                <Input id='totalOrder' value={amount} name='totalOrder' readOnly />
              </Col>
              <Col>
                <Label md="auto" for='totalPayment'>Сумма оплаты</Label>
                <Input defaultValue={data?.amount} id='totalPayment' name='totalPayment' readOnly />
              </Col>
              <Col>
                <Label md="auto" for='payType'>Способ оплаты</Label>
                <Input defaultValue={methods[data?.method]} id='payType' name='payType' readOnly />
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
        <FormGroup row>
          <Col>
            <Row>
              <Col md="auto">
                <Button onClick={() => sendChannel('SMS')} color='success'>Отправить по SMS</Button>
              </Col>
              <Col md="auto">
                <Button onClick={() => sendChannel('EMAIL')} color='success'>Отправить по почте</Button>
              </Col>
              <Col md="auto">
                <Button color='success' onClick={() => {
                  copyStringToClipboard(data.params.url);
                  toastr['info']('Ссылка скопирована', '', exportToast)
                }}>Скопировать ссылку</Button>
              </Col>
              <Col md="auto">
                <Button color='danger' onClick={() => setShowConfirm(true)}>Удалить</Button>
              </Col>
            </Row>
          </Col>
        </FormGroup>
      </Container>
      <ConfirmDialog
        title="Удаление"
        body="Вы действительно хотите удалить счет?"
        show={showConfirm}
        onClick={() => deleteBill()}
        onCancel={() => setShowConfirm(false)}
      />
    </div>
  )
}
