import React, { useEffect, useState } from 'react';
import { Button, Card, CardFooter, CardTitle } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import LagoonaTable from '../../components/LagoonaTable'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Preloader } from '../../components/Preloader';
import { defaultColumnStyle } from '../../common/tableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { getTop5ServiceData } from '../../store/dashboard/actions';
import { hMoney } from '../../utils/toHuman';


const columns = [
  {
    dataField: 'id',
    text: '#',
    sort: false,
    style: defaultColumnStyle,
    headerStyle: {
      width: '80px',
    },

  },
  {
    dataField: 'name',
    sort: false,
    text: 'Наименование',
    style: defaultColumnStyle,
    headerStyle: {
      width: '240px',
    },

  },
  { dataField: 'category', text: 'Категория', sort: false, style: defaultColumnStyle },
  { dataField: 'unit', text: 'Усл. единица', sort: false, style: defaultColumnStyle },
  {
    dataField: 'price',
    text: 'Стоимость',
    sort: false,
    style: defaultColumnStyle,
    // formatter: (cell) => <span>{cell}&#8381;</span>,
  },
  {
    dataField: 'totalOrders',
    text: 'Всего заказов',
    sort: false,
    style: defaultColumnStyle,
  },
  {
    dataField: 'totalPrice',
    text: 'Общая сумма',
    sort: false,
    style: defaultColumnStyle,
    // formatter: (cell) => <span>{cell}&#8381;</span>,
  },
];

export default function TopServicesCard(props) {
  const history = useHistory();
  const [info, setInfo] = useState([])
  const { dateRange, dateChange } = props;

  const dispatch = useDispatch()
  const { top5ServiceState, top5ServiceData } = useSelector(state => {
    return {
      top5ServiceState: state.Dashboard.top5ServiceState,
      top5ServiceData: state.Dashboard.top5ServiceData,
    }
  });

  useEffect(() => {
    dispatch(getTop5ServiceData(dateRange[0], dateRange[1]))
  }, [dateChange])

  useEffect(() => {
    const d = top5ServiceData?.data.items.map((e, i) => ({
      id: i + 1,
      serviceId: e.service_id,
      name: e.name,
      category: e.category,
      price: hMoney(e.price || 0) + '₽',
      unit: e.unit,
      totalOrders: e.orders_count,
      totalPrice: hMoney(e.total_amount || 0) + '₽',
    }))
    setInfo(d);
  }, [top5ServiceData])

  return (
    <Card className='p-2'>
      <CardTitle>ТОП-5 услуг</CardTitle>
      {top5ServiceState?.type === 'loading' && top5ServiceState?.data ? (
        <Preloader />
      ) : (
        <ToolkitProvider keyField='id' data={info || []} columns={columns} search>
          {(props) => (
            <LagoonaTable
              // eslint-disable-next-line react/prop-types
              {...props.baseProps}
              rowEvents={{
                onClick: (e, row) => {
                  history.push(`/service-details?u=${row.serviceId}`);
                }
              }}
            />
          )}
        </ToolkitProvider>)}
      <CardFooter>
        <div className="text-end">
          <Link to="/analytics-service">Все услуги</Link>
        </div>
      </CardFooter>
    </Card>
  )
}

TopServicesCard.propTypes = {
  dateRange: PropTypes.array,
  dateChange: PropTypes.number,
}