import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  Button, Col,
  FormGroup,
  Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { ServiceAnalyticsServices } from '../../services/serviceAnalyticsServices';

export default function ClientsAnalyticsFilter(props) {

  const { onApply } = props;

  const [currentCategories, setCurrentCategories] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  const [total_amount_from, setTotalAmountFrom] = useState('');
  const [total_amount_to, setTotalAmountTo] = useState('');

  const [orders_count_from, setTotalOrdersFrom] = useState('');
  const [orders_count_to, setTotalOrdersTo] = useState('');

  const resetFilters = () => {
    setCurrentCategories([]);
    setTotalAmountFrom('');
    setTotalAmountTo('');
    setTotalOrdersFrom('');
    setTotalOrdersTo('');
    onApply();
  };

  const Apply = () => {
    onApply({
      total_amount_from,
      total_amount_to,
      orders_count_from,
      orders_count_to,
      category: currentCategories.join(','),
    })
  }

  useEffect(async () => {
    const response = await ServiceAnalyticsServices.getServiceCategoriesList();
    const cat = response.data.data.categories;
    setCategoriesList(cat);
  }, [])

  return (
    <div className="d-flex justify-content-center">
      <Modal isOpen={props.show} centered size="lg" style={{ height: "600px" }}>
        <ModalHeader toggle={props.onCancel}>
          Фильтр
        </ModalHeader>
        <ModalBody>
          <Col>
            <FormGroup row>
              <Label md="auto" for="category">Категория</Label>
              <Select id="category" value={currentCategories} onChange={(e) => setCurrentCategories(e)} options={categoriesList} getOptionLabel={(e) => e} getOptionValue={(option) => option} isMulti placeholder="Поиск" />
            </FormGroup>
            <Row>
              <Col>
                <Label md="auto" for="fromOrders">Всего заказов</Label>
              </Col>
              <Col>
                <Label md="auto" for="fromPrice">Общая сумма</Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup row className="align-items-center">
                  <Col>
                    <Input id="fromOrders" value={orders_count_from} onChange={(e) => setTotalOrdersFrom(e.target.value)} placeholder={0} />
                  </Col>
                  -
                  <Col>
                    <Input id="toOrders" value={orders_count_to} onChange={(e) => setTotalOrdersTo(e.target.value)} placeholder={0} />
                  </Col>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup row className="align-items-center">
                  <Col>
                    <Input id="fromPrice" value={total_amount_from} onChange={(e) => setTotalAmountFrom(e.target.value)} placeholder={0} />
                  </Col>
                  -
                  <Col>
                    <Input id="toPrice" value={total_amount_to} onChange={(e) => setTotalAmountTo(e.target.value)} placeholder={0} />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => Apply()}>Применить</Button>
          <Button onClick={resetFilters}>Сброс</Button>
        </ModalFooter>
      </Modal>
    </div >
  )
}
ClientsAnalyticsFilter.propTypes = {
  show: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};


{/* <Col>
              <Label md="auto" for="fromOrders">Всего заказов</Label>
              <FormGroup row className={"align-items-baseline"}>
                <Col>
                  <Input id="fromOrders" value={orders_count_from} onChange={(e) => setTotalOrdersFrom(e.target.value)} placeholder={0} />
                </Col>
                -
                <Col>
                  <Input id="toOrders" value={orders_count_to} onChange={(e) => setTotalOrdersTo(e.target.value)} placeholder={0} />
                </Col>
              </FormGroup>
              <Label md="auto" for="fromPrice">Общая сумма</Label>
              <FormGroup row className={"align-items-baseline"}>
                <Col>
                  <Input id="fromPrice" value={total_amount_from} onChange={(e) => setTotalAmountFrom(e.target.value)} placeholder={0} />
                </Col>
                -
                <Col>
                  <Input id="toPrice" value={total_amount_to} onChange={(e) => setTotalAmountTo(e.target.value)} placeholder={0} />
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup row>
                <Label md="auto" for="category">Категория</Label>
                <Select id="category" value={currentCategories} onChange={(e) => setCurrentCategories(e)} options={categoriesList} getOptionLabel={(e) => e} getOptionValue={(option) => option} isMulti placeholder="Поиск" />
              </FormGroup>
            </Col> */}