import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import AuthCode from "react-auth-code-input"
import CarouselPage from '../../components/CarouselPage'

import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Form,
    Input,
    FormFeedback,
    Label,
    FormGroup,
} from 'reactstrap'
import { Link, Redirect, useHistory, withRouter } from 'react-router-dom'

import laguna from "../../assets/images/laguna_logo.svg";

import { useDispatch, useSelector } from 'react-redux'
import { restorePasswordConfirm, restorePasswordResend } from '../../store/actions'

let resendCountdown

const ConfirmReset = (props) => {
    const dispatch = useDispatch();

    const history = useHistory();

    const { token } = useSelector(state => {
        return {
            token: state.ForgetPassword.token
        }
    })

    const [resendTimer, setResendTimer] = useState(60);
    const [code, setCode] = useState('');
    const handleOnChange = (res) => {
        setCode(res);
    };

    useEffect(() => {
        resendCountdown = setTimeout(() => {
            if (resendTimer === 0) {
                clearTimeout(resendCountdown)
            } else {
                setResendTimer(resendTimer - 1)
            }
        }, 1000)

        return () => {
            clearTimeout(resendCountdown)
        }
    })

    const onSubmit = () => {
        if (code.length !== 4) return;
        dispatch(restorePasswordConfirm(token.token.token, code, history))
    }

    const onResend = () => {
        dispatch(restorePasswordResend(token.token.token))
    }

    return (
        !token ? (
            <Redirect to="/login" />
        ) : (
            <React.Fragment>
                <div>
                    <MetaTags>
                        <title>Подтвердить сброс пароля</title>
                    </MetaTags>
                    <div className="container-fluid p-0">
                        <div className="row g-0">
                            <CarouselPage number={3} />

                            <Col xl={3}>
                                <div className="auth-full-page-content p-md-5 p-4">
                                    <div className="w-100">
                                        <div className="d-flex flex-column h-100">
                                            <div className="mb-4 mb-md-5">
                                                <Link to="dashboard" className="d-block auth-logo">
                                                    <img
                                                        src={laguna}
                                                        alt="logo"
                                                        height="64"
                                                        className="auth-logo-dark"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="my-auto">
                                                <div className="text-center">
                                                    <div className="avatar-md mx-auto">
                                                        <div className="avatar-title rounded-circle bg-light">
                                                            <i className="bx bx-phone-incoming h1 mb-0 text-primary"></i>
                                                        </div>
                                                    </div>
                                                    <div className="p-2 mt-4">
                                                        <h4>Подтвердите телефон</h4>
                                                        <p>
                                                            Введите четырехзначный код, отправленный на телефон
                                                        </p>

                                                        <Form>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <FormGroup className="verification-2 mb-3">
                                                                        <Label
                                                                            htmlFor="digit1-input"
                                                                            className="visually-hidden"
                                                                        >
                                                                            Dight 1
                                                                        </Label>
                                                                        <AuthCode
                                                                            characters={4}
                                                                            onChange={(e) => handleOnChange(e)}
                                                                            className="form-control form-control-lg text-center"
                                                                            allowedCharacters="^[0-9]"
                                                                            inputStyle={{
                                                                                width: "40px",
                                                                                height: "calc(1.5em + 1rem + 2px)",
                                                                                padding: ".5rem",
                                                                                fontSize: "1.01562rem",
                                                                                textAlign: "center",
                                                                                marginRight: "10px",
                                                                                border: "1px solid #ced4da",
                                                                                textTransform: "uppercase",
                                                                                borderRadius: ".4rem"
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Form>

                                                        <div className="mt-4">
                                                            <button
                                                                className="btn btn-success w-md"
                                                                onClick={() => onSubmit()}
                                                            >
                                                                Подтвердить
                                                            </button>
                                                        </div>
                                                        <div className="mt-4">
                                                            {resendTimer ? <div>{resendTimer}</div> : <p
                                                              className='fw-medium text-primary'
                                                              onClick={() => onResend()}
                                                            >
                                                                Отправить еще раз
                                                            </p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-4 mt-md-5 text-center">
                                                <p className="mb-0">
                                                    © {new Date().getFullYear()}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    )
}

export default withRouter(ConfirmReset);
