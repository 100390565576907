import React from 'react'
import PropTypes from 'prop-types';
import { Button, ButtonDropdown, DropdownToggle, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { BraceletsServices } from '../../services/braceletsServices';
import { Preloader } from '../../components/Preloader';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr'

const RecordModal = (props) => {
    const { toggle, isOpen } = props;

    const [reportsList, setReportsList] = React.useState({ total: 0, items: [] })
    const [isLoading, setIsLoading] = React.useState(false);

    const [toggleReload, setToggleReload] = React.useState(false);

    const fetch = async () => {
        setIsLoading(true);
        try {
            const response = await BraceletsServices.getReportList();
            setReportsList(response.data.data)
            setIsLoading(false);
        } catch (error) {
            toastr['error'](error, "Загрузка списка отчетов", exportToast);
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        if (isOpen) {
            fetch();
        }
    }, [isOpen, toggleReload]);

    const handleDelete = async (id) => {
        try {
            const response = await BraceletsServices.deleteReport(id);
            setToggleReload(!toggleReload);
        } catch (error) {
            toastr['error'](error, "Удаление отчета", exportToast);
        }
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} size="md">
                <ModalHeader toggle={toggle}>
                    Список отчетов
                </ModalHeader>
                <ModalBody>
                    {isLoading ? <Preloader /> : (
                        <ul className='export-list'>
                            {reportsList.items.map((e, idx) => {
                                return (
                                    <li className='export-row' key={idx}>
                                        <a href={e.url}>{e.filename}</a>
                                        <i className="mdi mdi-delete" style={{ cursor: "pointer" }} onClick={() => handleDelete(e.id)} />
                                    </li>)
                            })}
                        </ul>
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default function RecordButton(props) {
    const { onClick } = props;

    const [isOpen, setIsOpen] = React.useState(false);


    const ModalToggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            <ButtonDropdown toggle={ModalToggle} >
                <Button id='caret' color='info' onClick={onClick}>
                    Сформировать отчет
                </Button>
                <DropdownToggle caret color='info'>
                    {isOpen ? <i className='mdi mdi-chevron-up' /> : <i className='mdi mdi-chevron-down' />}
                </DropdownToggle>
            </ButtonDropdown>
            <RecordModal isOpen={isOpen} toggle={ModalToggle} />
        </React.Fragment>
    )
}

RecordButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}

RecordModal.propTypes = {
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
}