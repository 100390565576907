import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Form, CardTitle, Col, Container, FormGroup, Input, Label, CardActions, Row } from 'reactstrap'
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { BillingServices } from 'services/billingService';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';

const payTypes = ['Безналичный'];
const payList = payTypes.map((item) => ({ value: item, label: item }));

export default function BillCreate() {
  const history = useHistory();
  const [order, setOrder] = useState(null);
  const [data, setData] = useState({});

  const [methods, setMethods] = useState({});
  const [str_methods, setStrMethods] = useState([]);
  useEffect(async () => {
    let filter = await BillingServices.getInvoicesFilter();

    let res = [];
    let methods = filter.data.data.methods;
    setStrMethods(methods);
    Object.keys(methods).forEach(key => {
      res.push({ value: key, label: methods[key] });
    });
    setMethods(methods);


  }, [true]);

  const searchOrder = async (input) => {
    let users = await BillingServices.searchOrder(input);
    console.log(users);
    users = users.data.data.items;
    let res = [];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.number });
    });
    return res;
  }

  const selectOrder = async (order) => {
    setOrder(order);
    let order_data = await BillingServices.preCreate(order.value).catch(e => {
      toastr['error'](e, 'Ошибка', exportToast);
    });
    if (!order_data) return
    order_data = order_data.data.data.invoice;
    setData(order_data);
  }


  const Icreate = async (e) => {
    e.preventDefault();
    if (order) {
      BillingServices.invoiceCreate(order.value).then(e => {

        console.log('invoiceCreate', e.data)

        history.push({
          pathname: `/bill-details`,
          search: 'u=' + e.data.invoice.id
        });

      }).catch(e => {
        toastr['error'](e, 'Ошибка', exportToast);
      })
    }
    else {
      toastr['error']('Выберите заказ', 'Ошибка', exportToast);
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Card className="p-4">
          <Form onSubmit={Icreate}>
            <CardTitle>Выставление счета</CardTitle>
            <CardBody>
              <FormGroup row>
                <Col>
                  <Label md="auto" for='orderId'>Заказ <b className='text-danger'>*</b></Label>
                  <AsyncSelect required id='orderId' name='orderId' placeholder="Поиск"
                    loadOptions={searchOrder} onChange={e => selectOrder(e)} value={order} />
                </Col>
                <Col>
                  <Label md="auto" for='phone'>Номер телефона</Label>
                  <Input readOnly defaultValue={data.phone} id='phone' name='phone' />
                </Col>
                <Col>
                  <Label md="auto" for='email'>Эл.почта</Label>
                  <Input readOnly defaultValue={data.email} id='email' name='email' />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label md="auto" for='orderName'>Наименование платежа</Label>
                  <Input readOnly defaultValue={data.name} id='orderName' name='orderName' />
                </Col>
                <Col>
                  <Label md="auto" for='orderTarget'>Назначение платежа</Label>
                  <Input readOnly defaultValue={data.purpose} id='orderTarget' name='orderTarget' />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label md="auto" for='totalOrder'>Сумма заказа</Label>
                  <Input readOnly defaultValue={data.total_amount} id='totalOrder' name='totalOrder' />
                </Col>
                <Col>
                  <Label md="auto" for='totalPayment'>Сумма оплаты</Label>
                  <Input readOnly defaultValue={data.amount} id='totalPayment' name='totalPayment' />
                </Col>
                <Col>
                  <Label md="auto" for='payType'>Способ оплаты</Label>
                  {/* <Select readOnly value={data.method} id='payType' name='payType' options={methods} placeholder="Поиск" /> */}
                  <Input readOnly defaultValue={str_methods[data.method]} id='payType' name='payType' />
                </Col>
              </FormGroup>
            </CardBody>
            <FormGroup row>
              <Col>
                <Row>
                  <Col md="auto">
                    <Button type="submit" color='success'>Выставить счет</Button>
                  </Col>
                  <Col md="auto">
                    <Button onClick={() => history.goBack()}>Отменить</Button>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </Form>
        </Card>
      </Container>
    </div>
  )
}
