import {
    CREATE_USER,
    GET_EMPL_LIST,
    GET_EMPL_ROLES,
    SET_EMPL_ERROR,
    SET_EMPL_LIST,
    SET_EMPL_LOADING,
    SET_EMPL_ROLES, USER_CREATED
} from "./actionTypes";

export const getEmplList = (term) => {
    return {
        payload: term,
        type: GET_EMPL_LIST,
    };
};

export const setEmplList = (list) => {
    return {
        type: SET_EMPL_LIST,
        payload: list,
    };
};

export const setEmplLoading = (state) => {
    return {
        type: SET_EMPL_LOADING,
        payload: state,
    };
};

export const setEmplError = (error) => {
    return {
        type: SET_EMPL_ERROR,
        payload: error,
    };
};

export const setEmplRoles = (roles) => {
    return {
        type: SET_EMPL_ROLES,
        payload: roles,
    };
};

export const getEmplRoles = () => {
    return {
        type: GET_EMPL_ROLES,
    };
};

export const createUser = (cred) => {
    return {
        type: CREATE_USER,
        payload: cred,
    };
};

export const setUserCreated = state => {
    return {
        type: USER_CREATED,
        payload: state,
    }
}