import { take } from "lodash";
import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { AuthServices } from '../../../services/authServices'
import { restorePasswordError, restorePasswordStatus, restorePasswordToken } from "./actions"
import { RESTORE_PASSWORD_CONFIRM, RESTORE_PASSWORD_REQUEST, RESTORE_PASSWORD_RESEND } from "./actionTypes"


function* requestPasswordRestore(action) {
  try {
    const response = yield call(AuthServices.requestPasswordRestore, action.payload.phone);
    yield put(restorePasswordToken(response.data));
    action.payload.history.push('/confirm-reset');
  } catch (error) {
    yield put(restorePasswordError(error));
  }
}

function* restorePasswordConfirm(action) {
  try {
    const response = yield call(AuthServices.confirmPasswordRestore, action.payload.token, action.payload.code)
    action.payload.history.push('/password-restored');
  } catch (error) {
    console.log(error)
  }
}

function* restorePasswordResend(action) {
  try {
    const response = yield call(AuthServices.passwordRestoreResend, action.payload.token)
  } catch (error) {
    console.log(error);
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(RESTORE_PASSWORD_REQUEST, requestPasswordRestore)
  yield takeEvery(RESTORE_PASSWORD_CONFIRM, restorePasswordConfirm)
  yield takeEvery(RESTORE_PASSWORD_RESEND, restorePasswordResend)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
