import React, { useEffect, useState } from 'react'
import { Badge, Input, Toast, ToastBody, ToastHeader } from 'reactstrap'
import { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  defaultColumnStyle,
  getPagSettings,
} from '../../common/tableSettings'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { ExportButton } from '../../components/ExportButton';
import { ClientsServices } from "../../services/clientsServices";
import { Preloader } from "../../components/Preloader";
import { debounce } from "lodash";
import { useSelector } from 'react-redux';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';

export const mapStatusToColor = {
  "Активный": "success",
  "Удален": "danger",
}

export const clientsColumns = [
  {
    dataField: 'index',
    text: '№',
    headerStyle: {
      width: 80,
    },
    style: defaultColumnStyle,
  },
  {
    dataField: 'created_at',
    text: 'Дата',
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: cell => {
      const m = moment(cell)
      return (
        <>
          {m.format('D.MM.yyyy')}
          <br />
          <span className='text-black-50'>{m.format('HH:mm')}</span>
        </>
      )
    },
    headerStyle: {
      width: 150,
    },
    style: defaultColumnStyle,
  },
  {
    dataField: 'fullName',
    text: 'Клиент',
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cell) => (<span>{cell}</span>),
    style: {
      verticalAlign: 'middle',
    },
  },
  {
    dataField: 'phone',
    text: 'Телефон',
    style: defaultColumnStyle,
  },
  {
    dataField: 'statusText',
    text: 'Статус',
    // eslint-disable-next-line react/display-name
    formatter: (cell) => (
      <h4>
        <Badge style={{ width: "100%", display: "flex", alignContent: "center", justifyContent: "center" }} color={mapStatusToColor[cell] || "yellow"}><small>{cell}</small></Badge>
      </h4>
    ),
    headerStyle: {
      width: '120px',
    },
    style: defaultColumnStyle,
  },
]

export default function Clients() {
  const history = useHistory();
  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exportStared, setExportStarted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const rowEvents = {
    onClick: (e, row, index) => {
      if (window.getSelection().toString()) {
        return;
      }

      history.push({
        pathname: `/client-details`,
        search: `?u=${row.id}`,
      });
    }
  };

  useEffect(async () => {
    if (!loading) {
      const response = await ClientsServices.getClientsList({ searchTerm, page, pageSize });
      setClientsList(response?.data?.data || []);
    }
  }, [searchTerm]);

  useEffect(async () => {
    setLoading(true);
    const response = await ClientsServices.getClientsList({ pageSize, page, searchTerm });
    console.log('getClientsList', response)
    setClientsList(response?.data?.data || []);
    setLoading(false);
  }, [pageSize, page]);

  const handleExport = async () => {
    const dateFrom = moment().subtract(1, "month").format("YYYY-MM-DD");
    const dateTo = moment().format("YYYY-MM-DD");

    toastr['success']('Экспорт данных запущен', 'Экспорт', exportToast);
    await ClientsServices.makeExport({ dateTo, dateFrom })
  };

  const { roles } = useSelector(state => {
    return {
      roles: state.Login.roles,
    }
  })

  const debouncedDispatch = debounce((_searchTerm) => {
    setSearchTerm(_searchTerm);
  }, 250);

  const onTableChanged = (type, { page, sizePerPage, sortOrder }) => {
    setPage(page);
    setPageSize(sizePerPage);
  }

  const restrictAccess = (roles) => {
    if (roles[0] === 'ADMIN') return false;
    if (roles[0] === 'OPERATOR') return true;
    else return false;
  }

  return (
    <>
      {/* {exportStared && <ToastNotification title="Экспорт" content="Экспорт данных запущен" />} */}
      <LagoonaPageWrapper title='Клиенты'>
        {' '}
        {loading ? <Preloader /> : (
          <>
            <div style={{ display: 'flex', gap: 15, marginBottom: "24px" }}>
              <div style={{ display: 'flex', gap: 15 }}>
                <Input
                  onChange={(e) => debouncedDispatch(e.target.value)}
                  placeholder='Поиск по ФИО или телефону'
                  style={{
                    width: 300,
                  }}
                />
              </div>
              <div style={{ marginLeft: 'auto' }}>
                {restrictAccess(roles) ? <></> : <ExportButton onClick={handleExport} action={"clients_list"} />}
                <Link to='/add-client'>
                  <button
                    type='button'
                    className='btn btn-success ms-2'
                    style={{ width: 200 }}
                  >
                    Добавить клиента
                  </button>
                </Link>
              </div>
            </div>
            <BootstrapTable
              remote
              keyField="id"
              data={clientsList.items}
              columns={clientsColumns}
              headerWrapperClasses='table-light'
              wrapperClasses='mt-3'
              hover
              bordered={false}
              pagination={
                getPagSettings({ page, sizePerPage: pageSize, totalSize: clientsList.total, length: clientsList.total })
              }
              onTableChange={onTableChanged}
              rowEvents={rowEvents}
            />
          </>
        )}
      </LagoonaPageWrapper>
    </>
  )
}
