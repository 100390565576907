import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD, LOGIN_USER, LOGOUT_USER, REFRESH_TOKEN, SOCIAL_LOGIN } from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  setChangePasswordError,
  setChangePasswordLoading,
  setPasswordChanged,
  setUserRoles
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import { BaseApi } from "../../../helpers/api";
import { AuthServices } from "../../../services/authServices";
import { logout } from "../../../helpers/common";
import { decodeToken } from "../../../helpers/jwt-token-access/tokenHelper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "original") {
      const response = yield call(AuthServices.login, {
        phone: user.email,
        password: user.password,
      });

      localStorage.setItem("authUser", JSON.stringify(response.data));
      yield put(loginSuccess(response));
    }

    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* loginUser2({ payload: { user, history } }) {
  try {
    const response = yield call(AuthServices.login, {
      phone: user.email,
      password: user.password,
    });

    localStorage.setItem("authUser", JSON.stringify(response.data));
    yield put(loginSuccess(response));
    let token = response.data.access_token;
    token = decodeToken(token);
    yield put(setUserRoles(token.roles));
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "original") {
      yield call(AuthServices.logout, {});
      yield put(logoutUserSuccess());
    }
  } catch (error) {
    yield put(apiError(error))
  } finally {
    logout();
    history.push("/login");
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    // history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* refreshToken() {
  try {
    const response = yield call(AuthServices.refreshToken, {});
    localStorage.setItem("authUser", JSON.stringify(response.data));
  } catch (error) {
    yield put(apiError(error))
  }
}

function* changePassword({ payload }) {
  console.log(payload);
  try {
    yield put(setChangePasswordLoading(true));
    yield call(AuthServices.changePassword, {
      oldPassword: payload.oldPassword,
      newPassword: payload.newPassword,
      confirmationPassword: payload.confirmationPassword,
    });
    yield put(setPasswordChanged(true));
  } catch (error) {
    yield put(setChangePasswordError(error));
    yield put(setPasswordChanged(false));
  } finally {
    yield put(setChangePasswordLoading(false));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser2)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(REFRESH_TOKEN, refreshToken)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}

export default authSaga
