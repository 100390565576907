import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Badge, Button, Card, Col, Container, Row, Toast, ToastBody, ToastHeader } from 'reactstrap'
import ConfirmDialog from "../../components/ConfirmDialog";
import { ClientsServices } from "../../services/clientsServices";
import { Preloader } from "../../components/Preloader";
import { mapStatusToColor } from "./index";
import { useSelector } from 'react-redux';
import { hMoney } from '../../utils/toHuman';
import toastr from "toastr";
import { exportToast } from '../../utils/toastr';

export default function ClientDetails() {

    const history = useHistory();
    const { search } = useLocation();
    const [showConfirm, setShowConfirm] = useState(false);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [analyticLoading, setAnalyticLoading] = useState(true);
    const [user, setUser] = useState({});
    const [userAnalytic, setUserAnalytic] = useState({});
    const [isDeleteComplete, setIsDeleteComplete] = useState(false);

    useEffect(() => {
        const id = new URLSearchParams(search);
        setUserId(id.get("u"));
    }, []);

    useEffect(async () => {
        if (!userId) {
            return;
        }

        setLoading(true);
        const userResponse = await ClientsServices.getClientInfo(userId);
        setLoading(false);
        setUser(userResponse.data.data);

        setAnalyticLoading(true);
        const userAnalyticResponse = await ClientsServices.getClientAnalytic(userId);
        setAnalyticLoading(false);
        setUserAnalytic(userAnalyticResponse.data.data[0]);
    }, [userId]);

    useEffect(() => {
        if (!isDeleteComplete) {
            return;
        }

        setTimeout(() => {
            setIsDeleteComplete(false);
            history.goBack();
        }, 1500);
    }, [isDeleteComplete]);

    const deleteUser = async () => {
        setShowConfirm(false);
        await ClientsServices.deleteClient(userId);
        setIsDeleteComplete(true);
        toastr['success']('Клиент успешно удален', 'Удаление клиента', exportToast);
    };

    const { roles } = useSelector(state => {
        return {
            roles: state.Login.roles
        }
    })

    const adminAccess = (roles) => {
        if (roles[0] === 'ADMIN') return false;
        else return true;
    }

    const operatorAccess = (roles) => {
        if (roles[0] === 'ADMIN' || roles[0] === 'OPERATOR') return false;
        else return true
    }

    return (
        <div className='page-content'>
            <Container fluid>
                <h4 className='mb-4'>Карточка клиента</h4>
                <Row>
                    <Col md={6}>
                        <Card className='p-4'>
                            {loading ? <Preloader /> : (
                                <>
                                    <h5 className='mb-5'>Общая информация</h5>
                                    <Row className='mb-4'>
                                        <Col>Фамилия:</Col>
                                        <Col>{user.last_name}</Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col>Имя:</Col>
                                        <Col>{user.first_name}</Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col>Отчество:</Col>
                                        <Col>{user.middle_name}</Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col>Номер телефона:</Col>
                                        <Col>{user.phone}</Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col>Эл. почта:</Col>
                                        <Col>{user.email}</Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col>Дата рождения:</Col>
                                        <Col>{user.birthday}</Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col>Фикс. скидка:</Col>
                                        <Col>{user.discount}%</Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col>Статус:</Col>
                                        <Col>
                                            <h4>
                                                <Badge color={mapStatusToColor[user.statusText] || "yellow"}>{user.statusText}</Badge>
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col>ID пользователя:</Col>
                                        <Col>{user.id}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {operatorAccess(roles) ? <></> :
                                                <Link to={`/edit-client?u=${userId}`}>
                                                    <Button color='primary' outline block>
                                                        Редактировать
                                                    </Button>
                                                </Link>}
                                        </Col>
                                        <Col>
                                            {operatorAccess(roles) ? <></> :
                                                <Button disabled={user.status === "DELETED"} color='danger' outline block onClick={() => setShowConfirm(true)}>
                                                    Удалить
                                                </Button>}
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Card>
                    </Col>
                    <Col md='6'>
                        <Card className='p-4'>
                            <Link to={`/orders?client_id=${userId}`} className='unstyled'>
                                <div className='d-flex'>
                                    <div className='me-4'>
                                        <i className='bx bxs-copy-alt bx-lg p-2'
                                            style={{ color: '#1EB34A', backgroundColor: '#BDECCB', borderRadius: '10px' }} />
                                    </div>
                                    <div>
                                        <h5>Заказы клиента</h5>
                                        <p>Просматривайте историю заказов конкретного клиента.</p>
                                    </div>
                                </div>
                            </Link>
                        </Card>
                        {operatorAccess(roles) ? <></> :
                            <Card className='p-4'>
                                <Link to={`/billing/invoice?client_id=${userId}`} className='unstyled'>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <i className='bx bxs-receipt bx-lg p-2'
                                                style={{ color: '#7E00FF', backgroundColor: '#E4C3FF', borderRadius: '10px' }} />
                                        </div>
                                        <div>
                                            <h5>Счета клиента</h5>
                                            <p>Просматривайте все выставленые счета на имя клиента.</p>
                                        </div>
                                    </div>
                                </Link>
                            </Card>}
                        {operatorAccess(roles) ? <></> :
                            <Card className='p-4'>
                                <Link to={`/billing/payments?client_id=${userId}`} className='unstyled'>
                                    <div className='d-flex'>
                                        <div className='me-4'>
                                            <i className='bx bxs-wallet-alt bx-lg p-2'
                                                style={{ color: '#DC3232', backgroundColor: '#ECBDBD', borderRadius: '10px' }} />
                                        </div>
                                        <div>
                                            <h5>Платежи клиента</h5>
                                            <p>Просматривайте поступления клиента на ваш расчетный счет.</p>
                                        </div>
                                    </div>
                                </Link>
                            </Card>}
                        <Card className='p-4'>
                            {analyticLoading ? <Preloader /> : (
                                <Row>
                                    <Col className='text-center'>
                                        <h2>{userAnalytic.count || 0}</h2>
                                        Всего заказов
                                    </Col>
                                    <Col className='text-center'>
                                        <h2>{hMoney(userAnalytic.discount_amount || 0)} ₽</h2>
                                        Сумма скидки
                                    </Col>
                                    <Col className='text-center'>
                                        <h2>{hMoney(userAnalytic.total_amount || 0)} ₽</h2>
                                        Общая сумма
                                    </Col>
                                </Row>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ConfirmDialog
                title="Удаление"
                body="Вы действительно хотите удалить пользователя?"
                show={showConfirm}
                onClick={() => deleteUser()}
                onCancel={() => setShowConfirm(false)}
            />
        </div>
    )
}
