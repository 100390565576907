import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Badge, Input } from 'reactstrap'
import LagoonaPageWrapper from "components/LagoonaPageWrapper";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link, useHistory } from 'react-router-dom'
import { defaultColumnStyle, getDefaultPaginationSettings, getPagSettings } from 'common/tableSettings';
import LagoonaTable from 'components/LagoonaTable';
import OrdersFilter from './filter'
import { ExportButton } from 'components/ExportButton';
import { ClientsServices } from "../../services/clientsServices";
import { OrdersService } from "../../services/ordersService";
import { Preloader } from "../../components/Preloader";
import BootstrapTable from "react-bootstrap-table-next";
import { clientsColumns } from "../Clients";
import { OrderStatus } from "./status";
import { useSelector } from 'react-redux';
import toastr from "toastr";
import { exportToast } from '../../utils/toastr';
import AddBraceletsModal from '../Bracelets/add';
import useDebounce from '../../hooks/useDebounce';


const { SearchBar } = Search;

const STATUS_LIST = [
  { text: 'Предоплачен', color: 'warning' },
  { text: 'Оплачен', color: 'success' },
  { text: 'Отменен', color: 'danger' },
  { text: 'Завершен', color: 'info' }
];

export const mapStatusToColor = {
  "1": "success",
  "2": "danger",
  "3": "danger",
  "4": "danger",
  "5": "danger",
  "6": "danger",
  "7": "danger",
}

/* eslint-disable react/display-name */
const orderColumns = [
  {
    dataField: 'created_at',
    text: 'Дата',
    sort: true,
    formatter: (cell) => {
      const m = moment(cell)
      return (
        <>
          {m.format('DD.MM.yyyy')}
          <br />
          <span className='text-black-50'>{m.format('HH:mm')}</span>
        </>
      )
    },
    headerStyle: {
      minWidth: '80px',
      width: '10%',
      textAlign: 'left'
    },
    style: {
      verticalAlign: 'middle',
      textAlign: 'left',
    },

  },
  {
    dataField: 'number',
    text: '№ заказа',
    formatter: (cell) => <span>{cell}</span>,
    headerStyle: {
      minWidth: '80px',
      width: '10%',
      textAlign: 'left'
    },
    style: {
      verticalAlign: 'middle',
      textAlign: 'left',
    },
  },
  {
    dataField: 'client_name',
    text: 'Клиент',
    sort: true,
    formatter: (cell) => <span>{cell}</span>,
    headerStyle: {
      minWidth: '80px',
      width: '10%',
      textAlign: 'left'
    },
    style: {
      verticalAlign: 'middle',
      textAlign: 'left',
    },
  },
  {
    dataField: 'client_phone',
    text: 'Телефон',
    headerStyle: {
      minWidth: '80px',
      width: '10%',
      textAlign: 'left'
    },
    style: {
      verticalAlign: 'middle',
      textAlign: 'left',
    },
  },
  {
    dataField: 'status',
    text: 'Статус',
    formatter: (cell) => (
      <h3>
        <Badge style={{ minWidth: "100%", fontWeight: 400 }} className={"pb-2"} color={OrderStatus.getColor(cell.id)}><small>{OrderStatus.getName(cell.id)}</small></Badge>
      </h3>
    ),
    headerStyle: {
      minWidth: '80px',
      width: '10%',
      textAlign: 'left'
    },
    style: {
      verticalAlign: 'middle',
    },
  },
  {
    dataField: 'total_amount',
    text: 'Сумма',
    formatter: (cell) => <span>{cell}&#8381;</span>,
    headerStyle: {
      minWidth: '80px',
      width: '5%',
      maxWidth: '80px',
      textAlign: 'left'
    },
    style: {
      verticalAlign: 'middle',
      textAlign: 'left',
    },
  },
  {
    dataField: 'booking_date',
    text: 'Период',
    formatter: (cell) => {
      const fromStr = moment(cell.from).format('DD.MM.YYYY');
      const toStr = moment(cell.to).format('DD.MM.YYYY');
      return (
        <>
          {fromStr}-{toStr}
        </>
      )
    },
    headerStyle: {
      minWidth: '150px',
      width: '12%',
      textAlign: 'left'
    },
    style: {
      verticalAlign: 'middle',
      textAlign: 'left',
    },
  },
  {
    dataField: 'days',
    text: 'Кол-во суток',
    formatter: (cell) => (<span>{moment(cell).format('D')}</span>),
    headerStyle: {
      minWidth: '80px',
      width: '10%',
      textAlign: 'left'
    },
    style: {
      verticalAlign: 'middle',
      textAlign: 'left',
    },
  }
];
/* eslint-enable react/display-name */

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')

  );
}

const formatDate2 = (date) => {
  if (!date) {
    return;
  }
  let d = date;
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

const data = Array.from(Array(10)).map((_, id) => {
  const fromDate = new Date() - Math.floor(Math.random() * 14) * 86400000;
  const toDate = new Date();
  const daysCount = toDate - fromDate;
  return {
    id,
    clientName: `Заказчик ${id}`,
    phone: '+79280081422',
    date: new Date(),
    status: STATUS_LIST[Math.floor(Math.random() * STATUS_LIST.length)],
    cost: Math.floor(Math.random() * 10000),
    period: { fromDate, toDate },
    daysCount
  };
});

export default function Orders() {
  const [message, setMessage] = useState(false);
  const [filter, setFilter] = useState();
  const [showFilters, setShowFilters] = useState(false);

  const [isFirst, setFirst] = useState(true);

  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");

  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);

  const debouncedSearch = useDebounce(search, 700);

  // Modal state
  const [isAddOpen, setIsAddOpen] = React.useState(false);

  const history = useHistory();

  const applyFilters = (filters) => {
    setFilter(filters);
    setShowFilters(false);
  };

  const rowEvents = {
    onClick: (e, row, index) => {
      history.push({
        pathname: '/order-details',
        search: `?id=${row.id}`,
      });
    }
  };

  function get_params(source, id, super_date) {
    return { pageSize, page, search, ...filter, client_id: id || filter?.client_id, traffic_source: source || filter?.traffic_source, super_date: super_date || false, }
  }

  useEffect(async () => {
    if (isFirst) {
      setLoading(true);
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('client_id')) {
      const id = urlParams.get('client_id');
      const response = await OrdersService.list(get_params(false, id));
      const u = new URL(window.location.toString());
      // window.history.replaceState({}, document.title, u.origin);
      setOrderList(response.data.data);
      setLoading(false);
      return;
    }

    if (urlParams.has('traffic_source')) {
      const source = urlParams.get('traffic_source');
      const response = await OrdersService.list(get_params(source, false));
      // const u = new URL(window.location.toString());
      // window.history.replaceState({}, document.title, u.origin);
      setOrderList(response.data.data);
      setLoading(false);
      return;
    }

    if (urlParams.has('created_at')) {
      const date = urlParams.get('created_at');
      const d2 = new Date(date);
      const super_date = formatDate(d2);
      const response = await OrdersService.list(get_params(false, false, super_date));
      // const u = new URL(window.location.toString());
      // window.history.replaceState({}, document.title, u.origin);
      setOrderList(response.data.data);
      setLoading(false);
      return;
    }

    setFirst(false);
    const response = await OrdersService.list(get_params());
    setOrderList(response?.data?.data || []);
    setLoading(false);
  }, [pageSize, page, filter, debouncedSearch]);



  const { roles } = useSelector(state => {
    return {
      roles: state.Login.roles,
    }
  })


  const handleExport = async () => {
    let dateFrom = moment().subtract(1, "month").format("YYYY-MM-DD");
    if (filter && filter.date_from) dateFrom = filter.date_from;
    let dateTo = moment().format("YYYY-MM-DD");
    if (filter && filter.date_to) dateTo = filter.date_to;

    let status = false;
    if (filter && filter.status) status = filter.status.value;

    // setMessage({ title: 'Экспорт', content: 'Экспорт данных запущен' });
    toastr["success"]('Экспорт данных запущен', "Экспорт", exportToast)
    await OrdersService.makeExport({ dateTo, dateFrom, status, ...filter }).finally(() => {
      setTimeout(() => {
        setMessage(false);
      }, 1500);
    });
  };

  const onTableChanged = (type, { page, sizePerPage, sortOrder }) => {
    setPage(page);
    setPageSize(sizePerPage);
  }

  const restrictAccess = (roles) => {
    if (roles[0] === 'ADMIN') return false;
    if (roles[0] === 'OPERATOR') return true;
    else return false;
  }

  return (
    <LagoonaPageWrapper title='Заказы'>
      {' '}
      {/* {message && <ToastNotification {...message} />} */}
      <AddBraceletsModal isOpen={isAddOpen} toggle={() => setIsAddOpen(!isAddOpen)} toggleReload={() => { return }} />
      {loading ? <Preloader /> : (
        <>
          <div style={{ display: 'flex', gap: 15, marginBottom: "24px" }}>
            <div style={{ display: 'flex', gap: 15 }}>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Поиск по № заказа или телефону'
                style={{
                  width: 300,
                }}
              />
            </div>
            <div style={{ marginLeft: '5px' }}>
              <button
                type='button'
                className='btn btn-success ms-2'
                style={{ width: 200 }}
                onClick={() => setIsAddOpen(true)}
              >
                Добавить запись
              </button>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <button
                type='button'
                className='btn btn-outline-success mx-2'
                onClick={() => setShowFilters(true)}
              >
                Фильтры
              </button>
              {restrictAccess(roles) ? <></> : <ExportButton action='orders_list' onClick={handleExport} />}
              <Link to='/add-order'>
                <button
                  type='button'
                  className='btn btn-success ms-2'
                  style={{ width: 200 }}
                >
                  Добавить заказ
                </button>
              </Link>
            </div>
          </div>
          <BootstrapTable
            remote
            keyField="id"
            data={orderList.items}
            columns={orderColumns}
            headerWrapperClasses='table-light'
            wrapperClasses='mt-3'
            hover
            bordered={false}
            pagination={
              getPagSettings({ page, sizePerPage: pageSize, totalSize: orderList.total, length: orderList.total })
            }
            onTableChange={onTableChanged}
            rowEvents={rowEvents}
          />
        </>
      )}
      <OrdersFilter show={showFilters} onApply={applyFilters} onCancel={() => setShowFilters(false)} />
    </LagoonaPageWrapper>
  )
}
