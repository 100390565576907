import React, {useState, useEffect} from 'react';
import { Card, CardTitle, FormGroup } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import LagoonaTable from '../../components/LagoonaTable'
import { defaultColumnStyle, getDefaultPaginationSettings } from '../../common/tableSettings'
import {OrdersService} from "../../services/ordersService";
import PropTypes from 'prop-types';
import moment from 'moment'

const PAYMENTS = {
  'ONLINE_PAYMENT': 'Онлайн-платеж',
  'CASH': "Наличные",
  'NONCASH': "Безналичные"
};

/* eslint-disable react/display-name */
const invoicesColumns = [
  {
    dataField: 'id',
    text: '№',
    headerStyle: {
      width: 80
    },
    style: defaultColumnStyle,
    formatter: (cell,row, index) => index+1
  },
  {
    dataField: 'name',
    text: 'Наименование',
    style: defaultColumnStyle
  },
  {
    dataField: 'purpose',
    text: 'Назначение',
    style: defaultColumnStyle
  },
  {
    dataField: 'amount',
    text: 'Сумма',
    style: defaultColumnStyle
  },
  {
    dataField: 'method',
    text: 'Способ оплаты',
    style: defaultColumnStyle,
    formatter: (cell) => PAYMENTS[cell]
  },
  {
    dataField: 'created_at',
    text: 'Дата выставления',
    style: defaultColumnStyle,
    formatter: (cell) => (<>
      {moment(cell).format('D.MM.yyyy')}
      <br />
      <span className='text-black-50'>{moment(cell).format('HH:mm')}</span>
      </>),
  }
];
/* eslint-enable react/display-name */

export default function OrderInvoicesCard (props) {
  // const invoices = [
  //   {
  //     id: 1,
  //     name: 'Оплата заказа №1469',
  //     target: 'Оплата бронирования 10%',
  //     total: '539 ₽',
  //     payment: 'Онлайн платёж',
  //     date: '24.04.2022',
  //   }
  // ];

  const history = useHistory();
  const [invoices, setInvoices] = useState([]);
  useEffect(async()=>{
    if(props.order_id){
      let items = await OrdersService.invoices(props.order_id);
      
      items = items.data.data.items;
      // console.log(items);
      if(items.length > 0){
        // console.log([items]);
        setInvoices(items);
      }
    }
  }, [props.order_id])
  return (
    <Card className="p-4">
      <CardTitle>История выставленных счетов</CardTitle>
      <ToolkitProvider keyField='id' data={invoices} columns={invoicesColumns}>
        {(props) => (
          <>
            <LagoonaTable
              // eslint-disable-next-line react/prop-types
              {...props.baseProps}
              rowEvents={{
                onClick: (e, row) => {
                  history.push(`/bill-details?u=${row.id}`);
                }
              }}
            />
          </>
        )}
      </ToolkitProvider>
    </Card>
  )
}
OrderInvoicesCard.propTypes = {
  order_id: PropTypes.any
}