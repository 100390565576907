import React from 'react'
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import AsyncSelect from 'react-select/async';
import { BraceletsServices } from '../../services/braceletsServices';
import _ from 'lodash';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr'


export default function AddBraceletsModal(props) {

    const { isOpen, toggle, toggleReload } = props;

    // Modal state
    const [orangeAdult, setOrangeAdult] = React.useState('');
    const [orangeChild, setOrangeChild] = React.useState('');

    const [blueAdult, setBlueAdult] = React.useState('');
    const [blueChild, setBlueChild] = React.useState('');

    const [redAll, setRedAll] = React.useState('');
    const [greenAll, setGreenAll] = React.useState('');

    const [orderId, setOrderId] = React.useState('');

    const resetModalValues = () => {
        setOrangeAdult('');
        setOrangeChild('');
        setBlueAdult('');
        setBlueChild('');
        setRedAll('');
        setGreenAll('');
        setOrderId('');
    }

    const Apply = async () => {
        if (!orangeAdult && !orangeChild && !blueAdult && !blueChild && !redAll && !greenAll) {
            toastr['info']("Заполните хотя бы одно поле браслетов", "Создание записи", exportToast);
            return;
        }
        const data = {
            orange_adults: orangeAdult ? orangeAdult : 0,
            orange_kids: orangeChild ? orangeChild : 0,
            blue_adults: blueAdult ? blueAdult : 0,
            blue_kids: blueChild ? blueChild : 0,
            red: redAll ? redAll : 0,
            green: greenAll ? greenAll : 0,
            order_id: orderId ? orderId.value : null
        }

        try {
            await BraceletsServices.createBracelets(data)
            toastr['success']("Запись успешно создана", "Создание записи", exportToast);
            toggleReload();
            ModalToggle();
        } catch (error) {
            toastr['error'](error, "Создание записи", exportToast);
        }
    }

    const ModalToggle = () => {
        resetModalValues()
        toggle();
    }

    const searchForOrder = async (input) => {
        let orders = await BraceletsServices.searchOrder(input);
        orders = orders.data.data.items;
        let res = [];
        orders.forEach((val, key) => {
            res.push({ value: val.id, label: val.number });
        });
        return res;
    }

    const loadOptions = React.useCallback(
        _.debounce((inputText, callback) => {
            searchForOrder(inputText).then((options) => callback(options));
        }, 600),
        []
    );

    const preventNonNumeric = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    const onOrangeAdultChange = (e) => {
        if (e.length > 3) return;
        setOrangeAdult(e);
    }

    const onOrangeChildChange = (e) => {
        if (e.length > 3) return;
        setOrangeChild(e);
    }

    const onBlueAdultChange = (e) => {
        if (e.length > 3) return;
        setBlueAdult(e);
    }

    const onBlueChildChange = (e) => {
        if (e.length > 3) return;
        setBlueChild(e);
    }

    const onRedAllChange = (e) => {
        if (e.length > 3) return;
        setRedAll(e);
    }

    const onGreenAllChange = (e) => {
        if (e.length > 3) return;
        setGreenAll(e);
    }

    return (
        <div className="d-flex justify-content-center">
            <Modal isOpen={isOpen} centered size="md" toggle={ModalToggle} >
                <ModalHeader toggle={ModalToggle}>
                    Создание записи
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={6}>
                            <FormGroup className="align-items-baseline">
                                <Label md="auto">Оранжевые взрослые</Label>
                                <Input
                                    placeholder={0}
                                    type='number'
                                    min="0"
                                    onKeyPress={(e) => preventNonNumeric(e)}
                                    onChange={(e) => onOrangeAdultChange(e.target.value)}
                                    value={orangeAdult}
                                />
                            </FormGroup>
                            <FormGroup className="align-items-baseline">
                                <Label md="auto">Синие взрослые</Label>
                                <Input
                                    placeholder={0}
                                    type='number'
                                    min="0"
                                    onKeyPress={(e) => preventNonNumeric(e)}
                                    onChange={(e) => onBlueAdultChange(e.target.value)}
                                    value={blueAdult}
                                />
                            </FormGroup>
                            <FormGroup className="align-items-baseline">
                                <Label md="auto">Красные</Label>
                                <Input
                                    placeholder={0}
                                    type='number'
                                    min="0"
                                    onKeyPress={(e) => preventNonNumeric(e)}
                                    onChange={(e) => onRedAllChange(e.target.value)}
                                    value={redAll}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup className="align-items-baseline">
                                <Label md="auto">Оранжевые до 12 лет</Label>
                                <Input
                                    placeholder={0}
                                    type='number'
                                    min="0"
                                    onKeyPress={(e) => preventNonNumeric(e)}
                                    onChange={(e) => onOrangeChildChange(e.target.value)}
                                    value={orangeChild}
                                />
                            </FormGroup>
                            <FormGroup className="align-items-baseline">
                                <Label md="auto">Синие до 12 лет</Label>
                                <Input
                                    placeholder={0}
                                    type='number'
                                    min="0"
                                    onKeyPress={(e) => preventNonNumeric(e)}
                                    onChange={(e) => onBlueChildChange(e.target.value)}
                                    value={blueChild}
                                />
                            </FormGroup>
                            <FormGroup className="align-items-baseline">
                                <Label md="auto">Зеленые</Label>
                                <Input
                                    placeholder={0}
                                    type='number'
                                    min="0"
                                    onKeyPress={(e) => preventNonNumeric(e)}
                                    onChange={(e) => onGreenAllChange(e.target.value)}
                                    value={greenAll}
                                />
                            </FormGroup>
                        </Col>
                        <FormGroup className="align-items-baseline">
                            <Label md="auto">Заказ</Label>
                            <AsyncSelect
                                id='orderId'
                                name='orderId'
                                placeholder="Поиск"
                                isClearable
                                loadOptions={loadOptions}
                                onChange={e => setOrderId(e)}
                                value={orderId} />
                        </FormGroup>
                    </Row>
                </ModalBody>
                <ModalFooter className="mt-2">
                    <Button color="success" onClick={() => Apply()}>Создать</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

AddBraceletsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    toggleReload: PropTypes.func.isRequired,
};