import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { useHistory, useLocation } from 'react-router-dom'
import Select from 'react-select'
import { serviceStatuses } from './models'
import { ServiceServices } from "../../services/serviceServices";
import moment from "moment";
import toastr from "toastr";
import { exportToast } from '../../utils/toastr';

const makeOptions = options => options.map(option => ({
  value: option,
  label: option,
}));

const getDeafultValue = (options, defalutVal) => makeOptions(options)
  .find(makedOptions => makedOptions.value === defalutVal);

export default function ServiceEdit() {
  const history = useHistory();
  const { search } = useLocation();
  const [categoryList, setCategoryList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [id, setId] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const [editedService, setEditedService] = useState({});
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    const serviceId = new URLSearchParams(search).get("u");
    setId(serviceId);
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    setIsLoading(true);
    ServiceServices.getFormFilter().then(response => {
      setCategoryList(response.data.data.categories);
      setUnitList(response.data.data.units);
    }).catch(setError).finally(_ => setIsLoading(false));
  }, [id]);

  useEffect(async () => {
    if (!unitList.length || !categoryList.length) {
      return;
    }

    setIsLoading(true);
    await ServiceServices
      .getServiceDetails(id)
      .then(response => {
        setServiceData(response.data.data.service);
        setEditedService(response.data.data.service);
      })
      .catch(setError)
      .finally(_ => setIsLoading(false));
  }, [unitList, categoryList]);

  useEffect(() => {
    if (!isCreated) {
      return;
    }

    setTimeout(() => {
      history.goBack();
    }, 1500);
  }, [isCreated]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    ServiceServices.updateService(id, { ...serviceData, ...editedService })
      .then(_ => {
        setIsCreated(true);
        toastr['success']('Уcлуга успешно обновлена', 'Редактирование', exportToast);
      })
      .catch(setError);
  };

  const defaultCategory = useMemo(() => {
    if (!editedService.category) {
      return categoryList[1];
    }

    return getDeafultValue(categoryList, editedService.category);
  }, [categoryList, editedService]);

  const defaultUnit = useMemo(() => {
    if (!editedService.unit) {
      return unitList[1];
    }

    return getDeafultValue(unitList, editedService.unit);
  }, [categoryList, editedService]);

  const defaultStatus = useMemo(() => {
    if (!editedService.status) {
      return serviceStatuses[1];
    }

    return serviceStatuses.find(status => status.value == editedService.status);
  }, [editedService, serviceData]);

  return (
    <LagoonaPageWrapper title='Редактирование услуги'>
      <Col>
        <Form onSubmit={handleFormSubmit}>
          {error && <Alert color="danger">{error}</Alert>}
          <FormGroup row>
            <Col sm={12}>
              <Label sm={2} for='name' style={{ width: "100%" }}>Наименование <b className='text-danger'>*</b></Label>
              <Input id='name' name='name' required defaultValue={serviceData.name} onChange={(e) => setEditedService({ ...editedService, name: e.target.value })} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label sm={2} for='lastName' style={{ width: "100%" }}>Категория <b className='text-danger'>*</b></Label>
              <Select id='lastName' name='lastName' type="select" value={defaultCategory} options={makeOptions(categoryList)} required onChange={(value) => setEditedService({ ...editedService, category: value.value })} />
            </Col>
            <Col sm={6}>
              <Label sm={2} for='unit' style={{ width: "100%" }}>Усл. единица <b className='text-danger'>*</b></Label>
              <Select id='unit' name='unit' type="select" value={defaultUnit} options={makeOptions(unitList)} required onChange={(e) => setEditedService({ ...editedService, unit: e.value })} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label sm={3} for='persons' style={{ width: "100%" }}>Кол-во человек  <b className='text-danger'>*</b></Label>
              <Input id='persons' name='persons' defaultValue={serviceData.people_count} required onChange={(e) => setEditedService({ ...editedService, people_count: e.target.value })} />
            </Col>
            <Col sm={6}>
              <Label sm={3} for='min' style={{ width: "100%" }}>Мин. значение  <b className='text-danger'>*</b></Label>
              <Input id='min' name='min' defaultValue={serviceData.min} required onChange={(e) => setEditedService({ ...editedService, min: e.target.value })} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label sm={4} for='startDate'>Время начала</Label>
              <Input id='startDate' name='startDate' type='time' defaultValue={serviceData.start_time} onChange={(e) => setEditedService({ ...editedService, start_time: moment(e.target.value, ["H:mm"]).format("HH:mm:ss") })} />
            </Col>
            <Col sm={4}>
              <Label sm={4} for='endDate'>Время окончания</Label>
              <Input id='endDate' name='endDate' type='time' defaultValue={serviceData.finish_time} onChange={(e) => setEditedService({ ...editedService, finish_time: moment(e.target.value, ["H:mm"]).format("HH:mm:ss") })} />
            </Col>
            <Col sm={4}>
              <Label sm={4} for='price' style={{ width: "100%" }}>Стоимость  <b className='text-danger'>*</b></Label>
              <Input id='price' name='price' required defaultValue={serviceData.price} onChange={(e) => setEditedService({ ...editedService, price: e.target.value })} />
            </Col>
          </FormGroup>

          <Row className='mb-2'>
            <Col md={6}>
              <FormGroup>
                <Label for='status-select' style={{ width: "100%" }}>Статус  <b className='text-danger'>*</b></Label>
                <Select options={serviceStatuses} value={defaultStatus} required onChange={(e) => setEditedService({ ...editedService, status: e.value })} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for='id' style={{ width: "100%" }}>Id услуги  <b className='text-danger'>*</b></Label>
                <Input id='id' name='id' required defaultValue={serviceData.type_id} onChange={(e) => setEditedService({ ...editedService, type_id: e.target.value })} />
              </FormGroup>
            </Col>
          </Row>

          <Button type="submit" sm={2} color='success' onClick={function noRefCheck() { }}>
            Редактировать услугу
          </Button>{' '}
          <Button onClick={() => history.goBack()} className='mx-2'>
            Отмена
          </Button>
        </Form>
      </Col>
    </LagoonaPageWrapper>
  )
}
