import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export function ChangePassword(props) {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const handleFormSubmit = useCallback(() => {
    props.onApply({
      oldPassword: password,
      newPassword,
      confirmPassword,
    });
  }, [password, newPassword, confirmPassword]);

  const isSubmitEnabled = useMemo(() => {
    return password && newPassword && confirmPassword && !props.loading;
  }, [password, newPassword, confirmPassword]);

  return (
    <Modal isOpen={props.show} centered size="md">
      <ModalHeader toggle={props.onClose}>
        Изменение пароля
      </ModalHeader>
      <ModalBody>
        {props.errors && <Alert color="danger">{props.errors}</Alert>}
        <FormGroup row className={"align-items-baseline"}>
          <Col md={4}>
            <Label for="currentPassword">Текущий пароль:</Label>
          </Col>
          <Col>
            <Input id="currentPassword" type="password" value={password} onChange={handlePasswordChange} />
          </Col>
        </FormGroup>
        <FormGroup row className={"align-items-baseline"}>
          <Col md={4}>
            <Label for="newPassword">Новый пароль:</Label>
          </Col>
          <Col>
            <Input id="newPassword" type="password" value={newPassword} onChange={handleNewPasswordChange} />
          </Col>
        </FormGroup>
        <FormGroup row className={"align-items-baseline"}>
          <Col md={4}>
            <Label for="repeatPassword">Повторите пароль:</Label>
          </Col>
          <Col>
            <Input id="repeatPassword" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
          </Col>
        </FormGroup>
        <ModalFooter>
          <Button color="success" onClick={handleFormSubmit} disabled={!isSubmitEnabled}>Сохранить изменения</Button>
          <Button onClick={props.onClose}>Отменить</Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}
ChangePassword.propTypes = {
  show: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.string,
  loading: PropTypes.bool,
}