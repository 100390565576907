import React, { useEffect, useState } from 'react'
import { Col, FormGroup } from 'reactstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import LagoonaTable from '../../components/LagoonaTable'
import {
  defaultColumnStyle,
  getPagSettings,
  getDefaultPaginationSettings,
} from '../../common/tableSettings'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import BillingAnalyticsFilter from './analytics-filter'
import { ExportButton } from '../../components/ExportButton';
import { useHistory } from 'react-router-dom'
import { BillingAnalyticsServices } from '../../services/billingAnalyticsServices'
import { Preloader } from '../../components/Preloader'
import BootstrapTable from 'react-bootstrap-table-next';
import { hMoney } from '../../utils/toHuman'
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';

/* eslint-disable react/display-name */

const MakeColumns = (total) => {
  const columns = [
    {
      dataField: 'date',
      text: 'Дата',
      sort: true,
      // formatter: (cell) => moment(cell).format('D.MM.yyyy'),
      headerStyle: {
        width: 120,
      },
      style: defaultColumnStyle,
      footer: 'Всего'
    },
    {
      dataField: 'invoices_count',
      text: 'Выставленных счетов',
      sort: true,
      style: defaultColumnStyle,
      footer: `${total?.invoices_count || 0}`
    },
    {
      dataField: 'payments_count',
      text: 'Полученных платежей',
      sort: true,
      style: defaultColumnStyle,
      footer: `${total?.payments_count || 0}`,
    },
    {
      dataField: 'orders_count',
      text: 'Всего заказов',
      sort: true,
      style: defaultColumnStyle,
      footer: `${total?.orders_count || 0}`
    },
    {
      dataField: 'services_count',
      text: 'Всего услуг',
      sort: true,
      style: defaultColumnStyle,
      footer: `${total?.services_count || 0}`
    },
    {
      dataField: 'total_amount',
      text: 'Общая сумма',
      sort: true,
      style: defaultColumnStyle,
      formatter: (cell) => <span>{cell}&#8381;</span>,
      footer: `${total?.total_amount || 0}`
    },
  ];
  return columns;
}

const getOneMonthAgo = (date) => {
  const d = date;
  const m = date.getMonth();
  d.setMonth(d.getMonth() - 1);
  if (d.getMonth() == m) d.setDate(0);
  d.setHours(0, 0, 0, 0);
  return d;
}

const formatDate = (date) => {
  if (!date) {
    return;
  }
  let d = date;
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}
/* eslint-enable react/display-name */

export default function BillingAnalytics() {
  const [sortField, setSortField] = useState('date');
  const [sortOrder, setSortOrder] = useState();
  const [dateChange, setDateChange] = useState(0);
  const [filter, setFilter] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [startDate, setStartDate] = useState(getOneMonthAgo(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const [incomeList, setIncomeList] = useState([]);
  const [columns, setColumns] = useState(MakeColumns());
  const [loading, setLoading] = useState(true);
  const [exportStarted, setExportStarted] = useState(false);
  const [total, setTotal] = useState(0);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const history = useHistory();

  function get_params() {
    return { pageSize, page: page - 1, sort: sortField, direction: sortOrder, dateFrom: formatDate(startDate), dateTo: formatDate(endDate), ...filter }
  }

  const handleExport = async () => {
    const dateFrom = moment().subtract(1, "month").format("YYYY-MM-DD");
    const dateTo = moment().format("YYYY-MM-DD");

    setExportStarted(true);
    toastr["success"]("Экспорт данных запущен", "Экспорт", exportToast)
    await BillingAnalyticsServices.makeExport({ dateTo, dateFrom }).finally(() => {
      setTimeout(() => {
        setExportStarted(false);
      }, 1500);
    });
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) setDateChange(dateChange + 1);
  };

  const onTableChanged = (type, { page, sizePerPage, sortOrder, sortField }) => {
    setPage(page);
    setPageSize(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  }

  const applyFilters = (newFilter) => {
    setFilter(newFilter);
    setShowFilters(false);
  };

  useEffect(async () => {
    setLoading(true)
    const response = await BillingAnalyticsServices.getIncomeList(get_params());
    const data = response.data.data;
    setIncomeList(makeData(data));
    setTotal(response.data.data.total);
    setLoading(false);
  }, [pageSize, page, filter, dateChange, sortField, sortOrder]);

  const makeData = (a) => {
    const data = (Array.isArray(a.items) ? a.items : Object.values(a.items)).map((e, i) => ({
      id: i + 1,
      date: e.date,
      invoices_count: e.invoices_count,
      payments_count: e.payments_count,
      orders_count: e.orders_count,
      services_count: e.services_count,
      total_amount: hMoney(e.total_amount || 0)
    }));
    return data;
  }

  useEffect(async () => {
    const dateFrom = formatDate(startDate);
    const dateTo = formatDate(endDate);
    const response = await BillingAnalyticsServices.getIncomeSummary({ dateFrom, dateTo })
    const data2 = response.data.data;
    setColumns(MakeColumns(data2));
  }, [dateChange])

  return (
    <>
      {/* {exportStarted && <ToastNotification title="Экспорт" content="Экспорт данных запущен" />} */}
      <LagoonaPageWrapper title='Аналитика.Выручка'>
        <div style={{ display: 'flex', gap: 15 }}>
          <Col md={2}>
            <FormGroup>
              <DatePicker
                locale='ru'
                dateFormat='dd.MM.yyyy'
                className='form-control'
                placeholderText="Выберите период"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(range) => onDateChange(range)}
                isClearable={true}
              />
            </FormGroup>
          </Col>
          <div style={{ marginLeft: 'auto' }}>
            <button
              type='button'
              className='btn btn-outline-success mx-2'
              onClick={() => setShowFilters(true)}
            >
              Фильтры
            </button>
            <ExportButton onClick={handleExport} action={"analytics_income_list"} />
          </div>
        </div>
        {loading ? <Preloader /> : (
          <BootstrapTable
            remote
            keyField="id"
            bordered={false}
            sort={{ dataField: sortField, order: sortOrder }}
            data={incomeList}
            columns={columns}
            headerWrapperClasses='table-light'
            wrapperClasses='mt-3'
            hover
            pagination={
              getPagSettings({ page, sizePerPage: pageSize, totalSize: total, length: total })
            }
            onTableChange={onTableChanged}
          />)}
        <BillingAnalyticsFilter show={showFilters} onApply={applyFilters} onCancel={() => setShowFilters(false)} />
      </LagoonaPageWrapper>
    </>
  )
}
