import {setEmplError, setEmplList, setEmplLoading, setEmplRoles, setUserCreated} from "./actions";
import {put, call, takeEvery} from "redux-saga/effects";
import {EmployeeService} from "../../services/employeeService";
import {CREATE_USER, GET_EMPL_LIST, GET_EMPL_ROLES} from "./actionTypes";

export function* getEmployeList({payload}) {
    try {
        yield put(setEmplLoading(true));
        const response = yield call(EmployeeService.getEmployeeList, {
            term: payload.searchTerm,
            pageSize: payload.pageSize,
        });
        yield put(setEmplLoading(false));
        yield put(setEmplList(response.data.data));
    } catch (e) {
        yield put(setEmplError(e))
    }
}

export function* getRoleList() {
    try {
        yield put(setEmplLoading(true));
        const response = yield call(EmployeeService.getRolesList, {});
        yield put(setEmplLoading(false));
        yield put(setEmplRoles(response.data.data));
    } catch (e) {
        yield put(setEmplError(e));
    }
}

export function* createUser({payload}) {
    try {
        yield put(setEmplLoading(true));
        yield call(EmployeeService.createUser, payload);
        yield put(setUserCreated(true));
        yield put(setEmplLoading(false));
    } catch (e) {
        yield put(setEmplError(e));
    }
}

function* employeeSaga() {
    yield takeEvery(GET_EMPL_LIST, getEmployeList);
    yield takeEvery(GET_EMPL_ROLES, getRoleList);
    yield takeEvery(CREATE_USER, createUser);
}

export default employeeSaga;