import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Badge, Button, Card, Col, Container, Row } from 'reactstrap'
import ConfirmDialog from '../../components/ConfirmDialog'
import { ServiceServices } from "../../services/serviceServices";
import { Preloader } from "../../components/Preloader";
import toastr from "toastr";
import { exportToast } from '../../utils/toastr';


export default function ServiceDetails() {

  const history = useHistory();
  const { search } = useLocation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [id, setId] = useState(null);
  const [serviceData, setServiceData] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);
  const [error, setError] = useState(false);

  const deleteService = async () => {
    await ServiceServices
      .deleteSerivce(id)
      .then(_ => setShowConfirm(false))
      .then(_ => {
        toastr['success']('Услуга успешно удалена', 'Удаление', exportToast);
        setIsDeleted(true)
      })
      .catch(setError);
  };

  useEffect(() => {
    const serviceId = new URLSearchParams(search).get("u");
    console.log({ serviceId });
    setId(serviceId);
  }, []);

  useEffect(async () => {
    if (!id) {
      return;
    }

    setIsLoading(true);
    const response = await ServiceServices.getServiceDetails(id);
    setServiceData(response.data.data.service);
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    if (!isDeleted) {
      return;
    }

    setTimeout(() => {
      setIsDeleted(false);
      history.goBack();
    }, 1500);
  }, [isDeleted]);

  return (
    <div className='page-content'>
      <Container fluid>
        <h4 className='mb-4'>Карточка услуги</h4>
        <Row>
          <Col md={6}>
            <Card className='p-4'>
              {loading ? <Preloader /> : (
                <>
                  <h5 className='mb-5'>Общая информация</h5>
                  <Row className='mb-4'>
                    <Col>№</Col>
                    <Col>{serviceData.id}</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>Наименование:</Col>
                    <Col>{serviceData.name}</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>Категория:</Col>
                    <Col>{serviceData.category}</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>Усл. единица:</Col>
                    <Col>{serviceData.unit}</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>Минимальное значение</Col>
                    <Col>{serviceData.min}</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>Дата начала:</Col>
                    <Col>{serviceData.start_time}</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>Дата завершения:</Col>
                    <Col>{serviceData.finish_time}</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>Кол-во человек:</Col>
                    <Col>{serviceData.people_count}</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>Стоимость:</Col>
                    <Col>{serviceData.price}</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col>ID услуги:</Col>
                    <Col>{serviceData.type_id}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <Link to={`/edit-service?u=${id}`}>
                        <Button color='primary' outline block>
                          Редактировать
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      <Button
                        color='danger'
                        outline block
                        onClick={() => setShowConfirm(true)}
                      >
                        Удалить
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          </Col>
          <Col md={6}>
            <Card className='p-4'>
              <Link to={`/timetable?service_id=${id}`} className='unstyled'>
                <div className='d-flex'>
                  <div className='me-4'>
                    <i className='bx bxs-calendar bx-lg p-2' style={{ color: '#1EB34A', backgroundColor: '#BDECCB', borderRadius: '10px' }} />
                  </div>
                  <div>
                    <h5>Расписание услуги</h5>
                    <p>Просматривайте расписание конкретной услуги для анализа</p>
                  </div>
                </div>
              </Link>
            </Card>
            <Card className='p-4'>
              <Link to={`/analytics-service?name=${serviceData.name}`} className='unstyled'>
                <div className='d-flex'>
                  <div className='me-4'>
                    <i className='bx bxs-bar-chart-alt-2 bx-lg p-2' style={{ color: '#7E00FF', backgroundColor: '#E4C3FF', borderRadius: '10px' }} />
                  </div>
                  <div>
                    <h5>Аналитика услуги</h5>
                    <p>Просматривайте количество взаимодействий клиентов с данной услугой</p>
                  </div>
                </div>
              </Link>
            </Card>
          </Col>
        </Row>
      </Container>
      <ConfirmDialog
        title="Удаление"
        body="Вы действительно хотите удалить услугу?"
        show={showConfirm}
        onClick={() => deleteService()}
        onCancel={() => setShowConfirm(false)}
      />
    </div>
  )
}
