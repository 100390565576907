import React, { useState, useEffect } from 'react';
import { Card, CardTitle, FormGroup } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import LagoonaTable from '../../components/LagoonaTable'
import { defaultColumnStyle, getDefaultPaginationSettings } from '../../common/tableSettings'
import { OrdersService } from "../../services/ordersService";
import PropTypes from 'prop-types';
import moment from 'moment'

const transactions = [
  {
    id: 1,
    name: 'Оплата заказа №1469',
    target: 'Оплата бронирования 10%',
    total: '539 ₽',
    payment: 'Онлайн платёж',
    date: '24.04.2022',
  }
];

const translatePayment = (payment) => {
  switch (payment) {
    case 'ONLINE_PAYMENT':
      return 'Онлайн-платеж'
    case 'CASH':
      return "Наличные"
    case 'NONCASH':
      return "Безналичные"
    default:
      return ''
  }
}

/* eslint-disable react/display-name */
const transactionsColumns = [
  {
    dataField: 'id',
    text: '№',
    headerStyle: {
      width: 80
    },
    style: defaultColumnStyle,
    formatter: (cell, row, index) => index + 1
  },
  {
    dataField: 'name',
    text: 'Наименование',
    style: defaultColumnStyle
  },
  {
    dataField: 'purpose',
    text: 'Назначение',
    style: defaultColumnStyle
  },
  {
    dataField: 'amount',
    text: 'Сумма',
    style: defaultColumnStyle
  },
  {
    dataField: 'method',
    text: 'Способ оплаты',
    style: defaultColumnStyle,
    formatter: (cell) => <span>{translatePayment(cell)}</span>
  },
  {
    dataField: 'created_at',
    text: 'Дата платежа',
    style: defaultColumnStyle,
    formatter: (cell) => (<>
      {moment(cell).format('D.MM.yyyy')}
      <br />
      <span className='text-black-50'>{moment(cell).format('HH:mm')}</span>
    </>),
  }
];
/* eslint-enable react/display-name */

export default function OrderTransactionsCard(props) {
  const history = useHistory();
  const [transactions, setInvoices] = useState([]);

  useEffect(async () => {
    if (props.order_id) {

      let items = await OrdersService.payments(props.order_id);

      items = items.data.data.items;
      console.log(items);
      setInvoices(items);
    }
  }, [props.order_id])


  // const makeData = (a) => {
  //   const data = (Array.isArray(a.items) ? a?.items : Object.values(a?.items)).map((e, i) => ({
  //     id: a.id,
  //     name: a.name,
  //     purpose: a.purpose,
  //     amount: a.amount,
  //     method: a.method,
  //     created_at: a.created_at,
  //   }));
  //   return data;
  // }



  return (
    <Card className="p-4">
      <CardTitle>История транзакций</CardTitle>
      <ToolkitProvider keyField='id' data={transactions} columns={transactionsColumns}>
        {(props) => (
          <>
            <LagoonaTable
              // eslint-disable-next-line react/prop-types
              {...props.baseProps}
              rowEvents={{
                onClick: (e, row) => {
                  history.push(`/pay-details?u=${row.id}`);
                }
              }}
            />
          </>
        )}
      </ToolkitProvider>
    </Card>
  )
}
OrderTransactionsCard.propTypes = {
  order_id: PropTypes.any
}