import React, { useEffect, useState } from 'react';
import { Button, Card, CardFooter, CardTitle } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import LagoonaTable from '../../components/LagoonaTable'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Preloader } from '../../components/Preloader';
import { defaultColumnStyle } from '../../common/tableSettings';
import { getTop5OrdersData } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { hMoney } from '../../utils/toHuman';

const data = Array.from(Array(83)).map((_, id) => ({
  id: id + 1,
  orderId: 1010 + id,
  clientName: 'Эдуард',
  phone: '+7 913 123 0501',
  daysCount: Math.round(Math.random() * 10),
  totalServices: Math.round(Math.random() * 10),
  totalPrice: Math.round(Math.random() * 124560)
}));

const columns = [
  {
    dataField: 'id',
    text: '#',
    style: defaultColumnStyle,
    headerStyle: {
      width: '80px',
    },
  },
  {
    dataField: 'orderNum',
    text: '№ заказа',
    sort: false,
    style: defaultColumnStyle,
    headerStyle: {
      width: '120px',
    },
  },
  { dataField: 'clientName', text: 'Клиент', sort: false, style: defaultColumnStyle },
  { dataField: 'phone', text: 'Телефон', sort: false, style: defaultColumnStyle },
  { dataField: 'daysCount', text: 'Кол-во суток', sort: false, style: defaultColumnStyle },
  {
    dataField: 'totalServices',
    text: 'Всего услуг',
    sort: false,
    style: defaultColumnStyle,
  },
  {
    dataField: 'totalPrice',
    text: 'Общая сумма',
    sort: false,
    style: defaultColumnStyle,
    // formatter: (cell) => <span>{cell}&#8381;</span>,
  },
];


export default function TopOrdersCard(props) {
  const history = useHistory();
  const [info, setInfo] = useState([])
  const { dateRange, dateChange } = props;

  const dispatch = useDispatch()

  const { top5OrdersState, top5OrdersData } = useSelector(state => {
    return {
      top5OrdersState: state.Dashboard.top5OrdersState,
      top5OrdersData: state.Dashboard.top5OrdersData,
    }
  });

  useEffect(() => {
    dispatch(getTop5OrdersData(dateRange[0], dateRange[1]))
  }, [dateChange])

  useEffect(() => {
    const d = top5OrdersData?.data.items.map((e, i) => ({
      id: i + 1,
      orderId: e.order_id,
      orderNum: e.number,
      clientName: e.client_fio,
      phone: e.client_phone,
      daysCount: e.days,
      totalServices: e.services_count,
      totalPrice: hMoney(e.amount_paid || 0) + '₽',
    }))
    setInfo(d);
  }, [top5OrdersData])

  return (
    <Card className='p-2'>
      <CardTitle>ТОП-5 заказов</CardTitle>
      {top5OrdersState?.type === 'loading' && top5OrdersState?.data ? (
        <Preloader />
      ) : (
        <ToolkitProvider keyField='id' data={info || []} columns={columns} search>
          {(props) => (
            <LagoonaTable
              // eslint-disable-next-line react/prop-types
              {...props.baseProps}
              rowEvents={{
                onClick: (e, row) => {
                  history.push(`/order-details?id=${row.orderId}`);
                }
              }}
            />
          )}
        </ToolkitProvider>)}
      <CardFooter>
        <div className="text-end">
          <Link to="/analytics-order">Все заказы</Link>
        </div>
      </CardFooter>
    </Card>
  )
}

TopOrdersCard.propTypes = {
  dateRange: PropTypes.array,
  dateChange: PropTypes.number
}
