import { result } from "lodash";
import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class BillingServices {
    static getInvoicesList = async ({ pageSize = 10, page = 0, search = "",
        client_id = false,
        date_from = false,
        date_to = false,
        status = false,
        amount_from = false,
        amount_to = false,
        sort = "created_at",
        direction = "desc",
    }) => {
        return BaseApi.getRequest({
            url: `invoice/list?page=${page}&pageSize=${pageSize}&sort=${sort}&direction=${direction}` +
                (search ? `&column=${search}` : ``) +
                (client_id ? `&client_id=${client_id}` : ``) +
                (date_from ? `&date_from=${date_from}` : ``) +
                (date_to ? `&date_to=${date_to}` : ``) +
                (status ? `&status=${status.value}` : ``) +
                (amount_from ? `&amount_from=${amount_from}` : ``) +
                (amount_to ? `&amount_to=${amount_to}` : ``) +
                (client_id ? `&client_id=${client_id}` : ''),
            jwt: getToken(),
        });
    };

    static getPaymentsList = async ({ pageSize = 10, page = 0, search = "",
        client_id = false,
        date_from = false,
        date_to = false,
        status = false,
        amount_from = false,
        amount_to = false,
        sort = "created_at",
        direction = "desc",
        purpose = false,
        pay = false
    }) => {
        return BaseApi.getRequest({
            url: `payment/list?page=${page}&pageSize=${pageSize}&sort=${sort}&direction=${direction}` +
                (search ? `&column=${search}` : ``) +
                (client_id ? `&client_id=${client_id}` : ``) +
                (date_from ? `&date_from=${date_from}` : ``) +
                (date_to ? `&date_to=${date_to}` : ``) +
                (status ? `&status=${status.value}` : ``) +
                (amount_from ? `&amount_from=${amount_from}` : ``) +
                (amount_to ? `&amount_to=${amount_to}` : ``) +
                (purpose ? `&purpose=${purpose.value}` : ``) +
                (pay ? `&method=${pay.value}` : ``),
            jwt: getToken(),
        });
    };


    static getInvoicesFilter = async () => {
        return BaseApi.getRequest({
            url: `invoice/form-filter`,
            jwt: getToken(),
        });
    };

    static getPaymentsFilter = async () => {
        return BaseApi.getRequest({
            url: `payment/form-filter`,
            jwt: getToken(),
        });
    };

    static getUsers = async ({ phone = false, fio = false }) => {
        return BaseApi.getRequest({
            url: `client/input-search?` +
                (phone ? `&phone=${phone}` : ``) +
                (fio ? `&fio=${fio}` : ``),
            jwt: getToken(),
        });
    };





    static makeExport = async ({ dateFrom, dateTo, status = false }) => {
        const formData = new FormData();
        formData.append("format", "xlsx");
        formData.append("action", "payments_list");
        formData.append("params[from]", dateFrom);
        formData.append("params[to]", dateTo);
        if (status) {
            formData.append("params[status]", status);
        }
        return BaseApi.postRequest({
            url: 'export/create',
            data: formData,
            jwt: getToken(),
        });
    };



    static getPay = async (id) => {
        return BaseApi.getRequest({
            url: `payment/${id}/read`,
            jwt: getToken(),
        });
    }

    static delPay = async (id) => {
        return BaseApi.deleteRequest({
            url: `payment/${id}/delete`,
            jwt: getToken(),
        });
    }

    static delBill = async (id) => {
        return BaseApi.deleteRequest({
            url: `invoice/${id}/delete`,
            jwt: getToken(),
        });
    }



    static searchOrder = async (search = "") => {
        return BaseApi.getRequest({
            url: `order/list?page=1&pageSize=20&sort=id&direction=asc&number=${search}`,
            jwt: getToken(),
        });
    };

    static readOrder = async (id) => {
        return BaseApi.getRequest({
            url: `order/${id}/read`,
            jwt: getToken(),
        });
    }

    static attach = async ({ order, payment, purpose }) => {
        const formData = new FormData();
        formData.append("payment_id", payment);
        formData.append("payment_purpose", purpose);
        return BaseApi.postRequest({
            url: `order/${order}/attach-payment`,
            data: formData,
            jwt: getToken(),
        });
    };

    static attachJustEntry = async (payment_id) => {
        const formData = new FormData();

        formData.append("payment_id", payment_id);
        formData.append("payment_purpose", 'Оплата заказа 100%');

        return BaseApi.postRequest({
            url: `order/0/attach-payment`,
            data: formData,
            jwt: getToken(),
        });
    };

    static detach = async ({ order, payment }) => {
        const formData = new FormData();
        formData.append("payment_id", payment);
        return BaseApi.postRequest({
            url: `order/${order}/detach-payment`,
            data: formData,
            jwt: getToken(),
        });
    };

    static getInvoice = async (id) => {
        return BaseApi.getRequest({
            url: `invoice/${id}/read`,
            jwt: getToken(),
        });
    }


    static sendChannel = async (id, channel) => {
        const formData = new FormData();
        formData.append("channel", channel);
        return BaseApi.postRequest({
            url: `invoice/${id}/resend`,
            data: formData,
            jwt: getToken(),
        });
    }

    static preCreate = async (id) => {
        return BaseApi.getRequest({
            url: `invoice/pre-create?order_id=` + id,
            jwt: getToken(),
        });
    }

    static invoiceCreate = async (id) => {
        const formData = new FormData();
        formData.append("order_id", id);
        return BaseApi.postRequest({
            url: `invoice/create`,
            data: formData,
            jwt: getToken(),
        });
    }
}