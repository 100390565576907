import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class BraceletsServices {

    static getBraceletsList = async ({ page, pageSize, sort = "id", direction = "desc" }) => {
        return BaseApi.getRequest({
            url: `bracelet/list?page=${page}&pageSize=${pageSize}&sort=${sort}&direction=${direction}`,
            jwt: getToken(),
        });
    };

    static getBraceletsById = async (id) => {
        return BaseApi.getRequest({
            url: `bracelet/${id}/read`,
            jwt: getToken(),
        });
    };

    static createBracelets = async (data) => {
        const formData = new FormData();

        formData.append("orange_adults", data.orange_adults);
        formData.append("orange_kids", data.orange_kids);
        formData.append("blue_adults", data.blue_adults);
        formData.append("blue_kids", data.blue_kids);
        formData.append("red", data.red);
        formData.append("green", data.green);

        if (data.order_id) {
            formData.append("order_id", data.order_id);

        }

        return BaseApi.postRequest({
            url: "bracelet/create",
            jwt: getToken(),
            data: formData,
        })
    };

    static deleteBracelets = async (id) => {
        return BaseApi.deleteRequest({
            url: `bracelet/${id}/delete`,
            jwt: getToken(),
        });
    };

    static updateBracelets = async (id, data) => {
        const formData = new FormData();

        formData.append("orange_adults", data.orange_adults);
        formData.append("orange_kids", data.orange_kids);
        formData.append("blue_adults", data.blue_adults);
        formData.append("blue_kids", data.blue_kids);
        formData.append("red", data.red);
        formData.append("green", data.green);

        if (data.order_id) {
            formData.append("order_id", data.order_id);

        }

        return BaseApi.postRequest({
            url: `bracelet/${id}/update`,
            data: formData,
            jwt: getToken(),
        });
    };

    static searchOrder = async (search = "") => {
        return BaseApi.getRequest({
            url: `order/list?page=1&pageSize=20&sort=id&direction=asc&number=${search}`,
            jwt: getToken(),
        });
    };

    static readOrderBraceletHistory = async (id) => {
        return BaseApi.getRequest({
            url: `order/${id}/bracelets`,
            jwt: getToken(),
        });
    }

    static createReport = async ({ dateFrom, dateTo }) => {
        return BaseApi.getRequest({
            url: `bracelet/report?from=${dateFrom}&to=${dateTo}`,
            jwt: getToken(),
        })
    }

    static getReportList = async (page = 1, pageSize = 999, sort = 'id', direction = 'asc') => {
        return BaseApi.getRequest({
            url: `bracelet/report-list?page=${page}&pageSize=${pageSize}&sort=${sort}&direction=${direction}`,
            jwt: getToken(),
        })
    }

    static deleteReport = async (id) => {
        return BaseApi.deleteRequest({
            url: `bracelet/${id}/report-delete`,
            jwt: getToken(),
        })
    }
}