import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {
  Button, Col,
  FormGroup,
  Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

export default function OrdersAnalyticsFilter(props) {
  const { onApply } = props;

  const [days_from, setTotalDaysFrom] = useState('');
  const [days_to, setTotalDaysTo] = useState('');

  const [services_count_from, setTotalServiceFrom] = useState('');
  const [services_count_to, setTotalServiceTo] = useState('');

  const [amount_paid_from, setTotalAmountFrom] = useState('');
  const [amount_paid_to, setTotalAmountTo] = useState('');

  const resetFilters = () => {
    setTotalDaysFrom('');
    setTotalDaysTo('');
    setTotalServiceFrom('');
    setTotalServiceTo('');
    setTotalAmountFrom('');
    setTotalAmountTo('');
    onApply();
  };

  const Apply = () => {
    onApply({
      days_from,
      days_to,
      services_count_from,
      services_count_to,
      amount_paid_from,
      amount_paid_to,
    })
  }

  return (
    <div className="d-flex justify-content-center">
      <Modal isOpen={props.show} centered size="lg" style={{ height: "600px" }}>
        <ModalHeader toggle={props.onCancel}>
          Фильтр
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Col>
              <Label md="auto" for="fromServices">Кол-во услуг</Label>
              <FormGroup row className={"align-items-baseline"}>
                <Col>
                  <Input id="fromServices" value={services_count_from} onChange={(e) => setTotalServiceFrom(e.target.value)} placeholder={0} />
                </Col>
                -
                <Col>
                  <Input id="toServices" value={services_count_to} onChange={(e) => setTotalServiceTo(e.target.value)} placeholder={0} />
                </Col>
              </FormGroup>
              <Label md="auto" for="fromPrice">Общая сумма</Label>
              <FormGroup row className={"align-items-baseline"}>
                <Col>
                  <Input id="fromPrice" value={amount_paid_from} onChange={(e) => setTotalAmountFrom(e.target.value)} placeholder={0} />
                </Col>
                -
                <Col>
                  <Input id="toPrice" value={amount_paid_to} onChange={(e) => setTotalAmountTo(e.target.value)} placeholder={0} />
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <Label md="auto" for="fromDays">Кол-во суток</Label>
              <FormGroup row className={"align-items-baseline"}>
                <Col>
                  <Input id="fromDays" value={days_from} onChange={(e) => setTotalDaysFrom(e.target.value)} placeholder={0} />
                </Col>
                -
                <Col>
                  <Input id="toDays" value={days_to} onChange={(e) => setTotalDaysTo(e.target.value)} placeholder={0} />
                </Col>
              </FormGroup>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => Apply()}>Применить</Button>
          <Button onClick={resetFilters}>Сброс</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
OrdersAnalyticsFilter.propTypes = {
  show: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
