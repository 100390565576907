import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

export const ObjectMap = ({ model: { title, columns, rooms } }) => {
  const rows = useMemo(() => {
    const rows = []
    let row = []
    const sortRooms = _.orderBy(rooms, (obj)=>{return obj.title - 0},['asc']);
    for (const room of sortRooms) {
      row.push(room)
      if (row.length >= columns) {
        rows.push(row)
        row = []
      }
    }
    if (row.length > 0) {
      row.push({ colspan: columns - row.length })
      rows.push(row)
    }
    while (rows.length < 3) {
      rows.push([{ colspan: columns }])
    }
    return rows
  }, [columns, rooms])
  
  return (
    <div className='obj-map'>
      <div className='obj-map-title'>{title}</div>
      <div className='obj-map-table'>
        <table>
          <tbody>
            {rows.map((row, rowIdx) => (
              <tr key={rowIdx}>
                {row.map(({ colspan, title, free }, colIdx) => (
                  <td key={colIdx} colSpan={colspan}>
                    {!colspan && (
                      <div
                        className={
                          'obj-map-room ' + (free ? 'm--free' : 'm--busy')
                        }
                      >
                        {title}
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

ObjectMap.propTypes = {
  model: PropTypes.object,
}
