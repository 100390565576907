import React, { useState } from 'react'
import { Button, Container } from 'reactstrap'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'

import { useHistory } from 'react-router-dom'
import OrderGeneralCard from "./add-general";
import OrderServicesCard from "./add-services";
import CreateUserModal from './create-user-modal'
import { set } from 'lodash';
import { OrdersService } from 'services/ordersService';
import { BaseApi } from "../../helpers/api";
import moment from 'moment'
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr'

export default function ServiceCreate() {
  const history = useHistory();
  const [createUser, setCreateUser] = useState(false);
  const [modalUser, setModalUser] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [message, setMessage] = useState(false);

  const [generalData, setGenData] = useState({});
  const [items, setItemsData] = useState([]);
  const [status, setStatus] = useState(false);


  const [sDis, setSDIS] = useState(false);
  const [sCurDis, setCurSDIS] = useState(false);
  const [bilet, setBilet] = useState(false);
  const [pPeriod, setPPeriod] = useState(false);
  const [sPeriod, setSPeriod] = useState(false);

  const setGenDataF = (data) => {
    setGenData(data);
  }
  const setItemsDataF = (data) => {
    setItemsData(data);
  }
  function Toastmessage(message) {
    setMessage({ title: 'Ошибка', content: message });
    setTimeout(() => setMessage({ title: 'Ошибка', content: message }), 1500);
  };

  async function save(e) {
    e.preventDefault();
    let data = { ...generalData };
    data.items = [];
    items.forEach(item => {
      data.items.push({
        service_id: item.id,
        service_count: item.people_count,
        service_discount: item.discount,
        booking_date_from: moment(item.start_time).format('YYYY-MM-DD HH:mm:ss'),
        booking_date_to: moment(item.start_to).format('YYYY-MM-DD HH:mm:ss')
      });
    });
    data.status = status;
    if (data.client_phone) {
      data.client_phone = data.client_phone.label;
    }
    else {
      // Toastmessage('Выберите телефон');
      toastr["info"]("Выберите телефон", "Форма", exportToast)
      return false;
    }

    if (data.order_source) {
      data.order_source = data.order_source.value;
    }
    else {
      // Toastmessage('Выберите источник заказа');
      toastr["info"]("Выберите источник заказа", "Форма", exportToast)
      return false;
    }

    if (data.traffic_source) {
      data.traffic_source = data.traffic_source.value;
    }
    else {
      // Toastmessage('Выберите источник трафика');
      toastr["info"]("Выберите источник трафика", "Форма", exportToast)
      return false;
    }

    if (!data.adults_count) {
      // Toastmessage('Введите количество взрослых');
      toastr["info"]("Введите количество взрослых", "Форма", exportToast)
      return false;
    }

    await OrdersService.fullCreate(data).then(data => {
      // Toastmessage(data.message);
      toastr["success"](data.message, "Заказ", exportToast)
      setTimeout(() => {
        history.push('/orders')
      }, 500)
    }).catch(e => {
      // Toastmessage(e);
      toastr["error"](e, "Ошибка", exportToast)
    })
  }

  const genBillet = (data) => {
    setBilet(data)
  }
  return (
    <LagoonaPageWrapper title="Создание заказа" disableContainer>
      <form onSubmit={save}>
        {/* {message && <ToastNotification {...message} />} */}
        <OrderGeneralCard setSPeriod={setSPeriod} setPeriod={setPPeriod} setSCURDIS={setCurSDIS} setSDIS={setSDIS} setBilet={genBillet} setGenData={setGenDataF} modalUser={modalUser} modalError={modalError} setModalError={setModalError} onCreateUser={() => setCreateUser(true)} />
        <OrderServicesCard s_period={sPeriod} period={pPeriod} cur_discount={sCurDis} amount_left={0} bilet={bilet} discount={sDis} setItemsData={setItemsDataF} />
        <Button type="submit" sm={2} color='success' onClick={async () => {
          setStatus(3);
        }}>
          Создать заказ
        </Button>{' '}
        <Button type="submit" sm={2} color='warning' onClick={async () => {
          setStatus(2);
        }}>
          Создать черновик
        </Button>{' '}
        <Button onClick={() => history.goBack()}>
          Отменить
        </Button>
      </form>
      <CreateUserModal
        isOpen={createUser}
        onClose={() => setCreateUser(false)}

        onApply={(data) => {
          setCreateUser(false);
          if (data) {
            setModalUser(data);
          }
        }}

        onError={(data) => {
          setCreateUser(false);
          if (data) {
            setModalError(data);
          }
        }}

      />

    </LagoonaPageWrapper>
  )
}
