import React, { useEffect, useState } from 'react'
import { Button, ButtonDropdown, DropdownToggle, Modal } from 'reactstrap'
import { ClientsServices } from "../services/clientsServices";
import { Preloader } from "./Preloader";
import PropTypes from 'prop-types';
import { BaseApi } from '../helpers/api';
import { getToken } from '../helpers/common';


const getExportList = async (action = "clients_list") => {
    return BaseApi.getRequest({
        url: `export/list?page=0&pageSize=1000&sort=id&direction=desc&action=${action}`,
        jwt: getToken(),
    });
};

export const TestModalContent = (props) => {
    const { action } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [exportList, setExportList] = useState([]);

    const loadExportList = async () => {
        setIsLoading(true);
        const response = await getExportList(action);
        setExportList(response.data.data.items);
        setIsLoading(false);
    }

    const handleDeleteItem = async id => {
        setIsLoading(true)
        await ClientsServices.deleteExportItem(id).then(loadExportList);
    }

    useEffect(() => {
        loadExportList();
    }, []);

    return (
        <>
            <div className='modal-header'>
                {/* <div className='d-flex justify-content-between'> */}
                <h5 className='modal-title mt-0' id='export-modal-label'>
                    История экспорта
                </h5>
                <Button className='btn-rounded' color='link' size='sm'>
                    <i className='mdi mdi-reload' onClick={loadExportList} />
                </Button>
                {/* </div> */}
            </div>
            <div className='modal-body' style={{ minHeight: '50vh' }}>
                {isLoading ? <Preloader /> : (
                    <>
                        {!exportList.length ? ("Нет данных для экспорта") : (
                            <ul className={'export-list'}>
                                {exportList.map(exportItem => (
                                    <li key={exportItem.id} className={'export-row'}>
                                        {exportItem.status == 2 ?
                                            <a href={exportItem.file.url} >
                                                {exportItem.file.name}
                                            </a> : <span>{exportItem.file.name}</span>}
                                        <i className="mdi mdi-delete" style={{ cursor: "pointer" }} onClick={() => handleDeleteItem(exportItem.id)} />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export const ExportButton = (props) => {

    const { onClick, action } = props;
    const [isOpened, setIsOpened] = useState(false)

    return (
        <>
            <ButtonDropdown toggle={() => setIsOpened(!isOpened)}>
                {/* eslint-disable-next-line react/prop-types */}
                <Button id='caret' color='success' onClick={onClick}>
                    Экспорт
                </Button>
                <DropdownToggle caret color='success'>
                    <i className='mdi mdi-chevron-down' />
                </DropdownToggle>
            </ButtonDropdown>
            <Modal isOpen={isOpened} toggle={() => setIsOpened(!isOpened)} size='lg'>
                <TestModalContent action={action} />
                <div className='modal-footer'>
                    <Button color='primary' onClick={() => setIsOpened(false)}>Закрыть</Button>
                </div>
            </Modal>
        </>
    )
}




ExportButton.propTypes = {
    onClick: PropTypes.func,
    action: PropTypes.string,
}

TestModalContent.propTypes = {
    action: PropTypes.string
}
