import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React, { useState } from 'react'

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from 'reactstrap'

//redux
import { useSelector, useDispatch } from 'react-redux'

import { withRouter, Link, Redirect } from 'react-router-dom'

// Formik validation
import * as Yup from 'yup'
import { ErrorMessage, Field, Formik, useFormik } from 'formik'

//Social Media Imports
// import { GoogleLogin } from 'react-google-login'
// import TwitterLogin from 'react-twitter-auth'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

// actions
import { loginUser, socialLogin } from '../../store/actions'

// import images
import profile from 'assets/images/profile-img.png'
import logo from 'assets/images/logo.svg'

import laguna from "../../assets/images/laguna_logo.svg";

//Import config
import { facebook, google } from '../../config'
import { isLoggedIn } from "../../helpers/common";
import CarouselPage from '../../components/CarouselPage'

const Login = props => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Введите номер телефона'),
      password: Yup.string().required('Введите пароль'),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
  }

  const signIn = (res, type) => {
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, 'google')
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, 'facebook')
  }



  return (
    isLoggedIn() ? (
      <Redirect to="/dashboard" />
    ) : (
      <React.Fragment>
        <div>
          <MetaTags>
            <title>Войти</title>
          </MetaTags>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage number={1} />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="dashboard" className="d-block auth-logo">
                          <img
                            src={laguna}
                            alt="logo"
                            height="64"
                            className="auth-logo-dark"
                          />
                        </Link>
                      </div>

                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Добро пожаловать !</h5>
                          <p className="text-muted">
                            Для продолжения необходимо войти
                          </p>
                        </div>

                        <div className="mt-4">
                          <Form className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            {error ? <Alert color='danger'>{error}</Alert> : null}


                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Номер телефона
                              </Label>
                              <Input
                                name='email'
                                className='form-control'
                                placeholder='Введите номер телефона'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ''}
                                invalid={
                                  !!(validation.touched.email && validation.errors.email)
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}

                            </div>

                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Пароль
                              </Label>
                              <Input
                                name='password'
                                value={validation.values.password || ''}
                                type='password'
                                placeholder='Введите пароль'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  !!(validation.touched.password &&
                                    validation.errors.password)
                                }
                              />
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}

                            </div>

                            {/* <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="remember-check"
                                  >
                                    Remember me
                                  </label>
                                </div> */}

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                {" "}
                                Войти{" "}
                              </button>
                            </div>

                            {/* <div className="mt-4 text-center">
                                  <h5 className="font-size-14 mb-3">
                                    Sign in with
                                  </h5>

                                  <ul className="list-inline">
                                    <li className="list-inline-item">
                                      <Link
                                        to="#"
                                        className="social-list-item bg-primary text-white border-primary"
                                      >
                                        <i className="mdi mdi-facebook"></i>
                                      </Link>
                                    </li>
                                    <li className="list-inline-item">
                                      <Link
                                        to="#"
                                        className="social-list-item bg-info text-white border-info"
                                      >
                                        <i className="mdi mdi-twitter"></i>
                                      </Link>
                                    </li>
                                    <li className="list-inline-item">
                                      <Link
                                        to="#"
                                        className="social-list-item bg-danger text-white border-danger"
                                      >
                                        <i className="mdi mdi-google"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </div> */}
                          </Form>

                          {/* <div className="mt-5 text-center">
                            <p>
                              Don&apos;t have an account ?{" "}
                              <a
                                href="pages-register-2"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Signup now{" "}
                              </a>{" "}
                            </p>
                          </div> */}
                        </div>
                        <div className="mt-4 text-center">
                          <p>
                            Забыли пароль?{" "}
                            <Link to="/forgot-password"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Восстановить{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
