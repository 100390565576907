import {
  RESTORE_PASSWORD_ERROR,
  RESTORE_PASSWORD_STATUS,
  RESTORE_PASSWORD_TOKEN,
  RESTORE_PASSWORD_CONFIRM,
} from "./actionTypes"

const initialState = {
  error: "",
  token: null,
  status: null,
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {

    case RESTORE_PASSWORD_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case RESTORE_PASSWORD_STATUS:
      return {
        ...state,
        status: action.payload,
      }
    case RESTORE_PASSWORD_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case RESTORE_PASSWORD_CONFIRM:
      return {
        ...state,
        status: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
