import { BaseApi } from "../helpers/api";
import { getRefreshToken, getToken } from "../helpers/common";

export class AuthServices {
    static login = async ({ phone, password }) => {
        const credentialsFormData = new FormData();
        credentialsFormData.append("phone", phone);
        credentialsFormData.append("password", password);

        return BaseApi.postRequest({
            url: "auth/login",
            data: credentialsFormData,
        });
    };

    static logout = async () => {
        const credentialsFormData = new FormData();
        credentialsFormData.append("refresh_token", getRefreshToken());

        return BaseApi.postRequest({
            url: "auth/logout",
            data: credentialsFormData,
        });
    }

    static refreshToken = async () => {
        const credentialsFormData = new FormData();
        credentialsFormData.append("refresh_token", getRefreshToken());

        return BaseApi.postRequest({
            url: "auth/refresh",
            data: credentialsFormData,
        });
    }

    static getProfile = async () => {
        const jwtToken = getToken();

        return BaseApi.getRequest({
            url: "auth/profile/info",
            jwt: jwtToken,
        });
    };

    static getLastActivity = async () => {
        const jwtToken = getToken();

        return BaseApi.getRequest({
            url: "auth/activity/last",
            jwt: jwtToken,
        });
    };

    static requestPasswordRestore = async (phone) => {
        const credentialsFormData = new FormData();

        credentialsFormData.append("phone", phone)

        return BaseApi.postRequest({
            url: "auth/restore-password-request",
            data: credentialsFormData,
        })
    }

    static confirmPasswordRestore = async (token, code) => {
        const credentialsFormData = new FormData();

        credentialsFormData.append("token", token);
        credentialsFormData.append("code", code);

        return BaseApi.postRequest({
            url: "auth/restore-password-confirm",
            data: credentialsFormData,
        })
    }

    static passwordRestoreResend = async (token) => {
        const credentialsFormData = new FormData();

        credentialsFormData.append("token", token);

        return BaseApi.postRequest({
            url: "/auth/restore-password-resend",
            data: credentialsFormData,
        })
    }

    static changePassword = async ({ oldPassword, newPassword, confirmationPassword }) => {
        const credentialsFormData = new FormData();
        credentialsFormData.append("old_password", oldPassword);
        credentialsFormData.append("new_password", newPassword);
        credentialsFormData.append("confirmed_password", confirmationPassword);

        const jwtToken = getToken();

        return BaseApi.postRequest({
            url: "auth/change-password",
            jwt: jwtToken,
            data: credentialsFormData,
        });
    }

    static changeProfilePicture = async ({ picture, id }) => {
        try {
            const jwtToken = getToken();

            const credentialsFormData = new FormData();

            credentialsFormData.append("file", picture);
            credentialsFormData.append("action", "avatar");
            credentialsFormData.append("params[employee_id]", id)

            return BaseApi.postRequest({
                url: "files/upload",
                jwt: jwtToken,
                data: credentialsFormData,
            })

        } catch (error) {
            console.log(error)
        }
    }
}