import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import {getToken, isJwtExpired, isLoggedIn, parseJwt} from "../helpers/common";
import {useDispatch} from "react-redux";
import {refreshToken} from "../store/auth/login/actions";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthProtected && isLoggedIn() && isJwtExpired()) {
          dispatch(refreshToken());
        }

        let refreshInterval = setInterval(() => {
          dispatch(refreshToken());
        }, 1000 * 60 * 14)

        return () => {
          clearInterval(refreshInterval)
        }
    }, []);

    return <Route
        {...rest}
        render={props => {
            if (isAuthProtected && !localStorage.getItem("authUser")) {
                return (
                    <Redirect
                        to={{ pathname: "/login", state: { from: props.location } }}
                    />
                )
            }

            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            )
        }}
    />
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
