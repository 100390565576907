import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  SET_DASHBOARD_SUMMARY,
  SET_DASHBOARD_STATE,
  GET_DASHBOARD_SUMMARY,
  SET_PAYMENT_METHOD_DATA,
  GET_PAYMENT_METHOD_DATA,
  SET_PAYMENT_METHOD_STATE,
  GET_ORDER_SOURCE_DATA,
  SET_ORDER_SOURCE_DATA,
  SET_ORDER_SOURCE_STATE,
  GET_GRAPH_INCOME_DATA,
  SET_GRAPH_INCOME_DATA,
  SET_GRAPH_INCOME_STATE,
  SET_TRAFFIC_SOURCE_DATA,
  GET_TRAFFIC_SOURCE_DATA,
  SET_TRAFFIC_SOURCE_STATE,
  GET_TOP5_CLIENTS_DATA,
  SET_TOP5_CLIENTS_DATA,
  SET_TOP5_CLIENTS_STATE,
  GET_TOP5_ORDERS_DATA,
  SET_TOP5_ORDERS_DATA,
  SET_TOP5_ORDERS_STATE,
  GET_TOP5_SERVICE_DATA,
  SET_TOP5_SERVICE_DATA,
  SET_TOP5_SERVICE_STATE,
} from './actionTypes'

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
})

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
})

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
})

export const setDashboardSummary = dashboardSummaryData => {
  return {
    type: SET_DASHBOARD_SUMMARY,
    payload: dashboardSummaryData,
  }
}

export const getDashboardSummary = (dateFrom, dateTo) => {
  return {
    type: GET_DASHBOARD_SUMMARY,
    dateFrom,
    dateTo,
  }
}

export const setDashboardState = state => {
  return {
    type: SET_DASHBOARD_STATE,
    payload: state,
  }
}

export const setPaymentMethodData = paymentMethodData => {
  return {
    type: SET_PAYMENT_METHOD_DATA,
    payload: paymentMethodData,
  }
}

export const getPaymentMethodData = (dateFrom, dateTo) => {
  return {
    type: GET_PAYMENT_METHOD_DATA,
    dateFrom,
    dateTo,
  }
}

export const setPaymentMethodState = state => {
  return {
    type: SET_PAYMENT_METHOD_STATE,
    payload: state,
  }
}

export const getOrderSourceData = (dateFrom, dateTo) => {
  return {
    type: GET_ORDER_SOURCE_DATA,
    dateFrom,
    dateTo,
  }
}

export const setOrderSourceData = orderSourceData => {
  return {
    type: SET_ORDER_SOURCE_DATA,
    payload: orderSourceData,
  }
}

export const setOrderSourceState = state => {
  return {
    type: SET_ORDER_SOURCE_STATE,
    payload: state,
  }
}

export const getGraphIncomeData = (group, dateFrom, dateTo) => {
  return {
    type: GET_GRAPH_INCOME_DATA,
    group,
    dateFrom,
    dateTo,
  }
}

export const setGraphIncomeData = (graphIncomeData) => {
  return {
    type: SET_GRAPH_INCOME_DATA,
    payload: graphIncomeData,
  }
}

export const setGraphIncomeState = (state) => {
  return {
    type: SET_GRAPH_INCOME_STATE,
    payload: state,
  }
}

export const getTrafficSourceData = (dateFrom, dateTo) => {
  return {
    type: GET_TRAFFIC_SOURCE_DATA,
    dateFrom,
    dateTo,
  }
}

export const setTrafficSourceData = (trafficSourceData) => {
  return {
    type: SET_TRAFFIC_SOURCE_DATA,
    payload: trafficSourceData,
  }
}

export const setTrafficSourceState = (state) => {
  return {
    type: SET_TRAFFIC_SOURCE_STATE,
    payload: state,
  }
}

export const getTop5ClientsData = (dateFrom, dateTo) => {
  return {
    type: GET_TOP5_CLIENTS_DATA,
    dateFrom,
    dateTo,
  }
}

export const setTop5ClientsData = (top5ClientsData) => {
  return {
    type: SET_TOP5_CLIENTS_DATA,
    payload: top5ClientsData,
  }
}

export const setTop5ClientsState = (state) => {
  return {
    type: SET_TOP5_CLIENTS_STATE,
    payload: state,
  }
}

export const getTop5OrdersData = (dateFrom, dateTo) => {
  return {
    type: GET_TOP5_ORDERS_DATA,
    dateFrom,
    dateTo,
  }
}

export const setTop5OrdersData = (top5OrdersData) => {
  return {
    type: SET_TOP5_ORDERS_DATA,
    payload: top5OrdersData,
  }
}

export const setTop5OrdersState = (state) => {
  return {
    type: SET_TOP5_ORDERS_STATE,
    payload: state,
  }
}

export const getTop5ServiceData = (dateFrom, dateTo) => {
  return {
    type: GET_TOP5_SERVICE_DATA,
    dateFrom,
    dateTo,
  }
}

export const setTop5ServiceData = (top5ServiceData) => {
  return {
    type: SET_TOP5_SERVICE_DATA,
    payload: top5ServiceData
  }
}

export const setTop5ServiceState = (state) => {
  return {
    type: SET_TOP5_SERVICE_STATE,
    payload: state,
  }
}