import {SET_EMPL_ERROR, SET_EMPL_LIST, SET_EMPL_LOADING, SET_EMPL_ROLES, USER_CREATED} from "./actionTypes";

const initialState = {
    error: false,
    loading: false,
    emplList: [],
    rolesList: [],
    isUserCreated: false,
};

export const emplReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_CREATED: {
            return {
                ...state,
                isUserCreated: action.payload,
            }
        }

        case SET_EMPL_ROLES: {
            return {
                ...state,
                rolesList: action.payload,
            }
        }

        case SET_EMPL_ERROR: {
            return {
                ...state,
                error: action.payload,
            }
        }

        case SET_EMPL_LOADING: {
            return {
                ...state,
                loading: action.payload,
            }
        }

        case SET_EMPL_LIST: {
            return {
                ...state,
                emplList: action.payload,
            }
        }

        default: {
            return state;
        }
    }
}