import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, Col, FormGroup, Spinner } from 'reactstrap'
import user1 from '../../assets/images/users/avatar-1.jpg'
import { Preloader } from "../../components/Preloader";
import { hMillion, hMoney } from '../../utils/toHuman';

export default function OrdersStatsCard(props) {
  const items = [
    { value: props.totalOrders, label: 'Всего заказов' },
    { value: hMillion(props.totalIncome) + ' млн', label: 'Выручка' },
    { value: hMoney(props.middleCheck || 0) + ' ₽', label: 'Ср.чек' },
    { value: props.totalBills, label: 'Всего счетов' },
    { value: props.totalPays, label: 'Всего платежей' }
  ];
  const itemList = items.map((item, idx) => (
    <Col key={`order-source-card-item${idx}`} className="mr-1 ml-1">
      <Card className='py-2 px-3'>
        <FormGroup row>
          <Col md="auto">
            <span className="text-muted">{item.label}</span>
            <h4 className="mt-3">{item.value}</h4>
          </Col>
        </FormGroup>
      </Card>
    </Col>
  ))
  return (
    <FormGroup row>
      {
        props.state?.type === "loading" && props.state?.data ? (
          <Preloader />
        ) : (
          itemList
        )
      }
    </FormGroup>
  )
}
OrdersStatsCard.propTypes = {
  totalOrders: PropTypes.number.isRequired,
  totalIncome: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  middleCheck: PropTypes.number.isRequired,
  totalBills: PropTypes.number.isRequired,
  totalPays: PropTypes.number.isRequired,
  state: PropTypes.object,
}
