import React, { useEffect, useState, updateState } from 'react';
import { Button, Card, Input, CardText, CardTitle, Col, FormGroup } from "reactstrap";
import Select from "react-select";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { FaTrash } from 'react-icons/fa';
import LagoonaTable from "../../components/LagoonaTable";
import { defaultColumnStyle, getDefaultPaginationSettings } from "../../common/tableSettings";
import moment from 'moment'
import { ServiceServices } from '../../services/serviceServices';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { OrdersService } from "../../services/ordersService";
import { forEach } from 'lodash';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr'


export default function OrderServicesCard(props) {

  const [selectedService, setSelectedService] = useState();
  const [addedServices, setAddedServices] = useState([]);

  useEffect(() => props.setItemsData(addedServices), [addedServices]);

  function totalServ(row) {
    let sum = parseInt(row.people_count) * parseInt(row.price);
    let discount_sum = (sum / 100) * parseInt(row.discount);
    sum -= discount_sum;
    row.total = sum;

    return row;
  }

  useEffect(() => {
    if (props.discount) {

      addedServices.forEach((item, key) => {
        changeAdServ(key, 'discount', props.discount);
      });
    }
  }, [props.discount])

  useEffect(() => {
    if (props.period) {
      addedServices.forEach((item, key) => {
        if (props.period[0]) {
          changeAdServ(key, 'start_time', moment.utc(props.period[0]).format('YYYY-MM-DDT') + moment.utc(item.start_time).format('HH:mm:ss'));
          changeAdServ(key, 'ssfinish_time', moment.utc(props.period[0]).format('YYYY-MM-DDT') + moment.utc(item.ssfinish_time).format('HH:mm:ss'));
        }
        if (props.period[1]) {
          changeAdServ(key, 'finish_time', moment.utc(props.period[1]).format('YYYY-MM-DDT') + moment.utc(item.ssfinish_time).format('HH:mm:ss'));

        }
      });
    }
  }, [props.period])


  function changeAdServ(key, column, value) {
    let newArr = addedServices;
    newArr[parseInt(key)][column] = value;
    if (column == 'people_count') {
      newArr[parseInt(key)].finish_time = moment.utc(newArr[parseInt(key)].ssfinish_time).add(value, 'days').format('YYYY-MM-DDTHH:mm')
    }

    newArr[parseInt(key)] = totalServ(newArr[parseInt(key)]);

    newArr[parseInt(key)].noChange = false;
    setAddedServices(newArr);
    setTimeout(async () => document.body.click(), 200);
  }

  const getServices = async (input) => {
    if (!props.s_period[0] || !props.s_period[1]) {
      toastr['info']('Перед добавлением услуг необходимо заполнить период', 'Добавление услуг', exportToast);
      return;
    }

    let services = await ServiceServices.getOrderInputSearch({ booking_date_from: props.s_period[0], booking_date_to: props.s_period[1], service_name: input })
    services = services.data.data.items;
    console.log("props services", services)
    let res = [];
    services.forEach((val, key) => {
      res.push({ value: val.id, label: val.name });
    });
    return res;
  }




  const removeServiceFromOrder = (key) => {
    let newServices = addedServices;
    newServices.splice(key, 1);
    setAddedServices(newServices);
    setTimeout(async () => document.body.click(), 200);
  }

  const addServiceToOrder = async () => {

    if (selectedService != null) {
      let newArr = addedServices;


      let adP = false;
      newArr.forEach((el, key) => {
        if (el.id == selectedService) {
          adP = [key, el.people_count + 1];
        }
      });
      if (adP) {

        changeAdServ(adP[0], 'people_count', adP[1])
      }
      else {
        addSerbiceById(selectedService);
      }
    }
  };

  async function addSerbiceById(id) {

    let addServ = await ServiceServices.getServiceDetails(id);

    addServ = addServ.data.data.service;

    const start_time = addServ.start_time ? addServ.start_time : false;
    const finish_time = addServ.finish_time ? addServ.finish_time : false;

    addServ.start_time = moment().format('YYYY-MM-DDT') + start_time;
    addServ.ssfinish_time = moment().format('YYYY-MM-DDT') + finish_time,
      addServ.finish_time = moment().format('YYYY-MM-DDT') + finish_time;

    if (props.s_period) {
      if (props.s_period[0]) {
        addServ.start_time = moment.utc(props.s_period[0]).format('YYYY-MM-DDT') + start_time;
        addServ.ssfinish_time = moment.utc(props.s_period[0]).format('YYYY-MM-DDTHH:mm:ss');

      }
      if (props.s_period[1]) {
        addServ.finish_time = moment.utc(props.s_period[1]).format('YYYY-MM-DDT') + finish_time;

      }
    }
    addServ.discount = 0;
    if (props.cur_discount) {
      addServ.discount = props.cur_discount;
    }
    addServ.noChange = false;
    addServ.bilet_id = id;
    addServ.people_count = 0;
    addServ = totalServ(addServ);
    setAddedServices([...addedServices, addServ]);
  }

  const selectService = (option) => {
    setSelectedService(option.value);
  };

  let amount = 0;
  addedServices.map(service => {
    let sum = parseInt(service.people_count) * parseInt(service.price);
    amount += parseInt(sum);
  });
  let discount = 0;
  addedServices.map(service => {
    let sum = parseInt(service.people_count) * parseInt(service.price);
    discount += (parseInt(sum) / 100) * parseInt(service.discount)
  });
  let total = 0;
  addedServices.map(service => {
    total += parseInt(service.total);
  });

  useEffect(async () => {

    document.body.click()
  }, [addedServices]);

  function adder(add) {
    setAddedServices([...addedServices, add]);
  }



  useEffect(async () => {
    if (props.bilet) {
      if (props.bilet.booking_date_from &&
        props.bilet.booking_date_to &&
        props.bilet.adults_count) {

        let bilQu = props.bilet;
        bilQu.service_ids = [];
        let newArr = addedServices;

        newArr.forEach((e, key) => {
          if (e.bilet) {

            newArr.splice(key, 1);
          }
        });

        newArr.forEach((e, key) => {
          if (e.bilet) {

            newArr.splice(key, 1);
          }
        });


        newArr.forEach(e => {
          bilQu.service_ids.push(e.id);
        });
        bilQu.service_ids = bilQu.service_ids.join(',');
        bilQu.booking_date_from = moment(bilQu.booking_date_from).format('YYYY-MM-DD')
        bilQu.booking_date_to = moment(bilQu.booking_date_to).format('YYYY-MM-DD')



        let bilets = await OrdersService.getBilets(bilQu);

        let result = newArr;

        console.log(result);


        bilets = bilets.data.data.items;


        await bilets.forEach(async bilet => {
          let addServ = await ServiceServices.getServiceDetails(bilet.service_id);
          addServ = addServ.data.data.service;

          if (addServ.start_time) {
            addServ.start_time = moment().format('YYYY-MM-DDT') + addServ.start_time;
          }
          else {
            addServ.start_time = moment().format('YYYY-MM-DDTHH:mm:ss')
          }
          console.log("props", addServ)
          if (addServ.finish_time) {
            addServ.ssfinish_time = moment().format('YYYY-MM-DDT') + addServ.start_time,
              addServ.finish_time = moment().format('YYYY-MM-DDT') + addServ.finish_time;
          }
          else {
            addServ.ssfinish_time = moment().format('YYYY-MM-DDTHH:mm:ss')
            addServ.finish_time = moment().format('YYYY-MM-DDTHH:mm:ss')
          }


          addServ.discount = 0;
          if (props.cur_discount) {
            addServ.discount = props.cur_discount;
          }

          addServ.noChange = false;
          addServ.bilet = true;
          addServ.people_count = bilet.service_count;
          addServ = totalServ(addServ);

          result = [...result, addServ];

          console.log(result);
          await setAddedServices(result);




        })

        // setAddedServices([...addedServices, ...result]);




      }
    }
  }, [props.bilet]);

  useEffect(async () => {
    if (props.order_id) {
      let ids = await OrdersService.items(props.order_id);
      ids = ids.data.data.items;

      let result = [];
      await ids.forEach(async (item) => {
        // let addServ = await ServiceServices.getServiceDetails(item.id);
        // addServ = addServ.data.data.service;
        let addServ = item;
        addServ.price = item.service_price;
        addServ.discount = item.service_discount;
        addServ.start_time = moment.utc(item.booking_date_from).format('YYYY-MM-DDTHH:mm:ss');
        addServ.finish_time = moment.utc(item.booking_date_to).format('YYYY-MM-DDTHH:mm:ss');
        addServ.ssfinish_time = moment.utc(item.booking_date_from).format('YYYY-MM-DDTHH:mm:ss');

        addServ.discount = item.service_discount;
        addServ.people_count = item.service_count;
        addServ.name = item.service_name;
        addServ.unit = item.service_unit;
        addServ.noChange = true;
        addServ.id = item.service_id;
        addServ.bilet_id = item.id;
        addServ = totalServ(addServ);
        //addServ.min = item.min;
        result.push(addServ);
        //adder(addServ);

      });

      setAddedServices(result);
      setTimeout(async () => document.body.click(), 500);
    }
  },
    [props.order_id]);

  return (
    <Card className="p-4">
      <CardTitle>Услуги</CardTitle>
      <CardText>Выберите услуги, которые необходимы данному клиенту и добавьте их в общий список</CardText>
      <FormGroup row>
        <Col md={8}>
          <AsyncSelect loadOptions={getServices} onChange={selectService} placeholder="Поиск" />
        </Col>
        <Col md={2}>
          <Button color="success" onClick={addServiceToOrder}>Добавить</Button>
        </Col>
      </FormGroup>


      <div className="react-bootstrap-table mt-3">
        <table className="table table-hover">
          <thead className="table-light">
            <tr>
              <th tabIndex={0} style={{ width: '80px' }}>№</th>
              <th tabIndex={0}>Наименование</th>
              <th tabIndex={0}>Усл. единица</th>
              <th tabIndex={0}>Кол-во</th>
              <th tabIndex={0}>Стоимость</th>
              <th tabIndex={0}>Скидка</th>
              <th tabIndex={0} style={{ width: '175px' }}>Период</th>
              <th tabIndex={0}>Итого</th>
              <th tabIndex={0} style={{ width: '60px' }} />
            </tr>
          </thead>
          <tbody>
            {addedServices.map((service, key) => {
              if (service.category == "Билет") {
                service.bilet = true
              }
              //TODO: U must fix this, its dirty hack
              const reg = /билет/i;
              if (reg.test(service.name) == true) {
                service.bilet = true
              }
              return (
                <tr key={key}>
                  <td style={{ verticalAlign: 'middle' }}><span>{key + 1}</span></td>
                  <td style={{ verticalAlign: 'middle' }}>{service.name}</td>
                  <td style={{ verticalAlign: 'middle' }}>{service.unit}</td>

                  <td style={{ verticalAlign: 'middle' }}>
                    <Input required value={service.people_count} onChange={e => changeAdServ(key, 'people_count', e.target.value)} type="number" />
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>{service.price}</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <span><Input required value={service.discount} onChange={e => changeAdServ(key, 'discount', e.target.value)} type="number" /></span>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input readOnly={service.unit != 'Час'} id={service.id + 'start_time'} style={{ display: 'block' }} value={service.bilet ? '' : service.start_time} onChange={e => changeAdServ(key, 'start_time', e.target.value)} type="datetime-local" />
                    <br />
                    <Input readOnly={service.unit != 'Час'} id={service.id + 'finish_time'} style={{ display: 'block' }} value={service.bilet ? '' : service.finish_time} onChange={e => changeAdServ(key, 'finish_time', e.target.value)} type="datetime-local" />
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>{service.total}</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Button key={`close-btn-${key}`} onClick={() => removeServiceFromOrder(key)} outline color="danger" >
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>


      <div className='d-flex justify-content-between'>
        <div>
          Всего услуг: <strong>{addedServices.length}</strong>
        </div>
        <div>
          Сумма без скидки: <strong>{amount}&#8381;</strong>
        </div>
        <div>
          Скидка составила: <strong style={{ color: "green" }}>{discount}&#8381;</strong>
        </div>
        <div>
          Итого: <strong>{total}&#8381;</strong>
        </div>
        <div>
          Осталось: <strong>{props.amount_left}&#8381;</strong>
        </div>
      </div>
    </Card>
  )
}
OrderServicesCard.propTypes = {
  setItemsData: PropTypes.func,
  order_id: PropTypes.any,
  discount: PropTypes.any,
  cur_discount: PropTypes.any,
  bilet: PropTypes.any,
  amount_left: PropTypes.any,
  period: PropTypes.any,
  s_period: PropTypes.any
}
