import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { BillingServices } from 'services/billingService';

export default function BillFilters(props) {


  const [client, setClient] = useState(null);
  const [date_from, setDateFrom] = useState('');
  const [date_to, setDateTo] = useState('');
  const [status, setStatus] = useState(null);
  const [phone, setPhone] = useState(null);
  const [amount_from, setAmountFrom] = useState('');
  const [amount_to, setAmountTo] = useState('');


  const resetFilters = () => {
    setClient(null);
    setDateFrom('');
    setDateTo('');
    setStatus(null);
    setPhone(null);
    setAmountFrom('');
    setAmountTo('');
    sub();
  };

  const [statusList, setStatuses] = useState([]);
  useEffect(async () => {
    const response = await BillingServices.getInvoicesFilter();
    let res = [];
    let statuses = response.data.data.statuses;
    Object.keys(statuses).forEach(key => {
      res.push({ value: key, label: statuses[key] });
    });
    setStatuses(res);
  }, [true]);



  const getOptionsPhone = async (input) => {
    let users = await BillingServices.getUsers({ phone: input });
    users = users.data.data.items;
    let res = [];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.phone });
    });
    return res;
  }

  const getOptionsFio = async (input) => {
    let users = await BillingServices.getUsers({ fio: input });
    users = users.data.data.items;
    let res = [];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.fullName });
    });
    return res;
  }

  const sub = () => {
    let client_id = null;
    if (client) {
      client_id = client.value;
    }
    else if (phone) {
      client_id = phone.value;
    }
    props.onApply({
      date_from,
      date_to,
      status,
      amount_from,
      amount_to,
      client_id
    })
  }

  return (
    <div className="d-flex justify-content-center">
      <Modal isOpen={props.show} centered size="lg">
        <ModalHeader toggle={props.onCancel}>
          Фильтр
        </ModalHeader>
        <ModalBody>
          <Card>
            <Row>
              <Col>
                <FormGroup row>
                  <Label for="user">Клиент</Label>
                  <AsyncSelect loadOptions={getOptionsFio} onChange={e => { setClient(e); setPhone(null) }} value={client} id="user" placeholder="Поиск" />
                </FormGroup>
                <Label md="auto" for="startDate">Дата создания</Label>
                <FormGroup row className={"align-items-baseline"}>
                  <Col>
                    <Input value={date_from} id="startDate" onChange={e => setDateFrom(e.target.value)} type="date" />
                  </Col>
                  -
                  <Col>
                    <Input value={date_to} id="toDate" onChange={e => setDateTo(e.target.value)} type="date" className={"mb-0"} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md="auto" for="statuses">Статус</Label>
                  <Select value={status} id="statuses" onChange={e => setStatus(e)} options={statusList} placeholder="Поиск" />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup row>
                  <Label for="phone">Номер телефона</Label>
                  <AsyncSelect id="phone" onChange={e => { setPhone(e); setClient(null) }} value={phone} loadOptions={getOptionsPhone} placeholder="Поиск" />
                </FormGroup>
                <Label md="auto" for="fromCost">Сумма</Label>
                <FormGroup row className={"align-items-baseline"}>
                  <Col>
                    <Input value={amount_from} onChange={e => setAmountFrom(e.target.value)} id="fromCost" />
                  </Col>
                  -
                  <Col>
                    <Input value={amount_to} onChange={e => setAmountTo(e.target.value)} id="toCost" />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={sub}>Применить</Button>
          <Button onClick={resetFilters}>Сброс</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

BillFilters.propTypes = {
  show: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
