import React, { useEffect, useState } from 'react';
import { Button, Card, Form, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import Select from 'react-select'
import ConfirmDialog from 'components/ConfirmDialog';
import { BillingServices } from 'services/billingService';
import { OrdersService } from 'services/ordersService';
import { Link, useHistory, useLocation } from 'react-router-dom'
import AsyncSelect from 'react-select/async';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';

const STATUS_LIST = {
  'Создан': 'warning',
  'Выставлен': 'warning',
  'Оплачен': 'success',
  'Отменен': 'danger',
  'Удален': 'danger',
  'Ошибка': 'danger'
};

export default function PayDetails() {

  const history = useHistory();
  const { search } = useLocation();
  const [showConfirm, setShowConfirm] = useState(false);

  const [data, setData] = useState({
    client_phone: '',
    client_email: '',
    name: '',
    purpose: '',
    amount: '',
    total_amount: '',
    statusText: '',
    id: '',
    order: null
  });

  const [purposeTypes, setPurTypes] = useState([]);
  const [payTypes, setPayTypes] = useState([]);

  const deletePayment = async (id) => {
    setShowConfirm(false);
    await BillingServices.delPay(data.id).catch(e => {
      toastr['error'](e, 'Ошибка', exportToast);
    });

    history.goBack();
    toastr['success']('Платёж удален', '', exportToast);
  };

  useEffect(async () => {
    const payId = new URLSearchParams(search).get("u");
    let pay = await BillingServices.getPay(payId);

    if (pay.data.data.method) pay.data.data.method = { value: pay.data.data.method, label: pay.data.data.methodText };
    if (pay.data.data.purpose) pay.data.data.purpose = { value: pay.data.data.purpose, label: pay.data.data.purpose };

    if (pay.data.data.order_id) {
      // let order = await BillingServices.searchOrder(pay.data.data.order_id);
      let order = await BillingServices.readOrder(pay.data.data.order_id);
      order = order.data.data.order;
      pay.data.data.order = { value: order.id, label: order.number }
    }
    setData(pay.data.data);
  }, [true]);

  async function reset() {
    const response = await BillingServices.getInvoicesFilter();
    let res = [];
    let purposes = response.data.data.purposes;
    Object.keys(purposes).forEach(key => {
      res.push({ value: key, label: purposes[key] });
    });
    setPurTypes(res);

    res = [];
    let methods = response.data.data.methods;
    Object.keys(methods).forEach(key => {
      res.push({ value: key, label: methods[key] });
    });
    setPayTypes(res);
  }

  useEffect(async () => {
    await reset();
  }, [true]);


  const searchOrder = async (input) => {
    let users = await BillingServices.searchOrder(input);
    users = users.data.data.items;
    let res = [];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.number });
    });
    return res;
  }

  const setOrder = async (order) => {
    let order_data = await (await OrdersService.read(order.value)).data.data.order;
    setData({
      ...data, order,
      client_phone: order_data.client_phone,
      client_email: order_data.client_email,
      amount: order_data.cost,
      total_amount: order_data.total_amount,
    });
  }

  const attach = async (e) => {
    e.preventDefault();
    if (data.order) {
      let purpose = '';
      if (data.purpose) purpose = data.purpose.label;
      else {
        toastr['error']('Выберите назначение', 'Ошибка', exportToast);
        return;
      }
      toastr['success']('Успешно', 'Прикрепление платежа', exportToast);

      await BillingServices.attach({ order: data.order.value, payment: data.id, purpose }).catch((e) => {
        toastr['error'](e, 'Прикрепление платежа', exportToast);
      }).then((response) => {
        if (response && response.status == "ok") setTimeout(() => { history.goBack(); }, 1000)
      })
      //reset();
    }
    else {
      toastr['error']('Выберите заказ', 'Ошибка', exportToast);
    }
  }

  const detach = async () => {
    if (data.order) {
      toastr['success']('Платеж успешно откреплён от заказа', 'Открепление платежа', exportToast)
      await BillingServices.detach({ order: data.order.value, payment: data.id }).catch(e => {
        toastr['error'](e, 'Открепление платежа', exportToast)
      }).then((response) => {
        if (response && response.status == "ok") setTimeout(() => { history.goBack(); }, 1000)
      })
      //reset();
    }
  }

  const justEntryAttach = async () => {
    try {
      await BillingServices.attachJustEntry(data.id);
      toastr['success']('Платеж успешно прикреплен', 'Прикрепление платежа', exportToast);
      setTimeout(() => { history.goBack(); }, 2000);
    }
    catch (error) {
      toastr['error'](error, 'Прикрепление платежа', exportToast)
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Form onSubmit={attach}>
          <Card className="p-4">

            <FormGroup row>
              <Col md={10}>
                <CardTitle>Платеж №{data.id}</CardTitle>
              </Col>
              <Col md={2} className="d-flex justify-content-end">
                <Button color={STATUS_LIST[data.statusText]} style={{ minWidth: "120px" }}>{data.statusText}</Button>
              </Col>
            </FormGroup>

            <CardBody>
              <FormGroup row>
                <Col>
                  <Label md="auto" for='orderId'>Заказ <b className='text-danger'>*</b></Label>
                  <AsyncSelect id='orderId' name='orderId' placeholder="Поиск"
                    loadOptions={searchOrder} onChange={e => setOrder(e)} value={data.order} />
                </Col>
                <Col>
                  <Label md="auto" for='phone'>Номер телефона</Label>
                  <Input defaultValue={data.client_phone} id='phone' name='phone' readOnly />
                </Col>
                <Col>
                  <Label md="auto" for='email'>Эл.почта</Label>
                  <Input defaultValue={data.client_email} id='email' name='email' readOnly />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label md="auto" for='orderName'>Наименование платежа</Label>
                  <Input defaultValue={data.name} id='orderName' name='orderName' readOnly />
                </Col>
                <Col>
                  <Label md="auto" for='orderTarget'>Назначение платежа <b className='text-danger'>*</b></Label>
                  <Select required id='orderTarget' name='orderTarget' value={data.purpose} options={purposeTypes} onChange={e => setData({ ...data, purpose: e })} placeholder="Поиск" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label md="auto" for='totalOrder'>Сумма заказа</Label>
                  <Input value={data.total_amount} id='totalOrder' name='totalOrder' readOnly />
                </Col>
                <Col>
                  <Label md="auto" for='totalPayment'>Сумма оплаты</Label>
                  <Input value={data.amount} id='totalPayment' name='totalPayment' readOnly />
                </Col>
                <Col>
                  <Label md="auto" for='payType'>Способ оплаты</Label>
                  <Select id='payType' name='payType' value={data.method} options={payTypes} onChange={e => setData({ ...data, method: e })} placeholder="Поиск" />
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
          <FormGroup row>
            <Col>
              <Row>
                <Col md="auto">
                  <Button type="submit" color='success'>Прикрепить платеж</Button>
                </Col>
                <Col md="auto">
                  <Button onClick={detach} color='primary'>Открепить платеж</Button>
                </Col>
                <Col md="auto">
                  <Button color='danger' onClick={() => setShowConfirm(true)}>Удалить платеж</Button>
                </Col>
                <Col>
                  <Button
                    color="warning"
                    onClick={() => justEntryAttach()}
                  >
                    Вход без беседки
                  </Button>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </Form>
      </Container>
      <ConfirmDialog
        title="Удаление"
        body="Вы действительно хотите удалить платеж?"
        show={showConfirm}
        onClick={() => deletePayment()}
        onCancel={() => setShowConfirm(false)}
      />
    </div>
  )
}
