export const orderStatuses = [
  {
    id: 0,
    name: 'Удалён',
    color: 'danger',
  },
  {
    id: 1,
    name: 'Отменен',
    color: 'secondary',
  },
  {
    id: 2,
    name: 'Черновик',
    color: 'warning',
  },
  {
    id: 3,
    name: 'Новый',
    color: 'info',
  },
  {
    id: 4,
    name: 'Ждет предоплату',
    color: 'paywait',
  },
  {
    id: 5,
    name: 'Предоплачен',
    color: 'success',
  },
  {
    id: 6,
    name: 'Оплачен',
    color: 'paid',
  },
  {
    id: 7,
    name: 'Завершен',
    color: 'finished',
  },
];
