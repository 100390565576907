import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Card, Col, Form, FormGroup, Input, Label, Row, Toast, ToastBody, ToastHeader } from 'reactstrap'
import Select from "react-select";
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { Link, useHistory, useLocation } from "react-router-dom";
import { EmployeeService } from "../../services/employeeService";
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';

export default function EditEmployee() {
    const history = useHistory();
    const { search } = useLocation();
    const [id, setId] = useState(null);
    const [employe, setEmploye] = useState({});
    const [roles, setRoles] = useState([]);
    const [isEditedComplete, setIsEditedComplete] = useState(false);
    const [error, setError] = useState(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(async () => {
        setId(new URLSearchParams(search).get("u"));

        const response = await EmployeeService.getRolesList();
        let res = [];
        let roles_sources = response.data.data.item.roles;
        Object.keys(roles_sources).forEach(key => {
            res.push({ value: roles_sources[key].id, label: roles_sources[key].name });
        });

        setRoles(res);
    }, []);

    useEffect(async () => {
        if (!id) {
            return
        }
        const response = await EmployeeService.getEmplInfo(id);
        const role = { value: response.data.data.roles[0].id, label: response.data.data.roles[0].name };
        setEmploye({ ...response.data.data, role });
    }, [id]);

    useEffect(() => {
        if (!isEditedComplete) {
            return;
        }

        setTimeout(() => {
            history.goBack();
        }, 1500);
    }, [isEditedComplete]);

    const changeValue = (data) => {
        setEmploye({ ...employe, ...data })
        setIsSaveDisabled(false)
    }
    const handleFormSubmit = async e => {
        e.preventDefault();
        employe.roles = [{ id: employe.role.value, name: employe.role.label }]
        const toSave = { ...employe }
        delete toSave.role
        await EmployeeService
            .updateUser(id, { ...toSave })
            .then(r => {
                if (r.status === "error") {
                    throw r.errors.map(error => error.message).join(", ");
                }
            })
            .then(() => {
                setIsEditedComplete(true);
                toastr['success']('Пользователь успешно обновлен', 'Обновление пользователя', exportToast);
            })
            .catch(setError);
    }

    return (
        <>
            <LagoonaPageWrapper title='Редактирование сотрудника'>
                <Col md={7}>
                    <Form onSubmit={handleFormSubmit}>
                        {error && <Alert color="danger">{error}</Alert>}
                        <FormGroup row>
                            <Label for='role' sm={2}>
                                Роль <b className='text-danger'>*</b>
                            </Label>
                            <Col sm={9}>
                                <Select id="role" value={employe?.role} onChange={e => changeValue({ role: e })} options={roles} placeholder="Роль" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for='lastname' sm={2}>
                                Фамилия <b className='text-danger'>*</b>
                            </Label>
                            <Col sm={9}>
                                <Input id='lastname' name='lastname' defaultValue={employe?.last_name}
                                    onChange={e => changeValue({ last_name: e.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for='firstName' sm={2}>
                                Имя <b className='text-danger'>*</b>
                            </Label>
                            <Col sm={9}>
                                <Input id='firstName' name='firstName' defaultValue={employe?.first_name}
                                    onChange={e => changeValue({
                                        first_name: e.target.value
                                    })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for='secondName' sm={2}>
                                Отчество <b className='text-danger'>*</b>
                            </Label>
                            <Col sm={9}>
                                <Input id='secondName' name='secondName' defaultValue={employe?.middle_name}
                                    onChange={e => changeValue({ middle_name: e.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for='phone' sm={2}>
                                Телефон <b className='text-danger'>*</b>
                            </Label>
                            <Col sm={9}>
                                <Input id='phone' name='phone' type='tel' defaultValue={employe?.phone}
                                    onChange={e => changeValue({ phone: e.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for='position' sm={2}>
                                Должность
                            </Label>
                            <Col sm={9}>
                                <Input id='position' name='position' defaultValue={employe?.position}
                                    onChange={e => changeValue({ position: e.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row className='mb-4'>
                            <Label for='email' sm={2}>
                                Эл. почта
                            </Label>
                            <Col sm={9}>
                                <Input id='email' name='email' type='email' defaultValue={employe?.email}
                                    onChange={e => changeValue({ email: e.target.value })} />
                            </Col>
                        </FormGroup>
                        <Button type="submit" color='success' disabled={isSaveDisabled} onClick={function noRefCheck() {
                        }}>
                            Сохранить
                        </Button>{' '}
                        <Button onClick={() => history.goBack()} className='mx-2'>
                            Отмена
                        </Button>
                    </Form>
                </Col>
            </LagoonaPageWrapper>
        </>
    )
}
