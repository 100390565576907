import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { useHistory, useLocation } from 'react-router-dom'
import Select from 'react-select'
import { serviceStatuses } from './models'
import { ServiceServices } from "../../services/serviceServices";
import moment from "moment";
import toastr from "toastr";
import { exportToast } from '../../utils/toastr';

const makeOptions = options => options.map(option => ({
  value: option,
  label: option,
}))

export default function ServiceCreate() {

  const history = useHistory();
  const { search } = useLocation();
  const [categoryList, setCategoryList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [id, setId] = useState(false);
  const [newService, setNewService] = useState({});
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ServiceServices.getFormFilter().then(response => {
      setCategoryList(response.data.data.categories);
      setUnitList(response.data.data.units);
    }).catch(setError).finally(_ => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!isCreated) {
      return;
    }

    setTimeout(() => {
      history.goBack();
    }, 1500);
  }, [isCreated]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    ServiceServices.createService(newService)
      .then(_ => {
        setIsCreated(true);
        toastr['success']('Услуга успешно создана', 'Создание услуги', exportToast);
      })
      .catch(setError);
  };

  console.log(newService);

  return (
    <LagoonaPageWrapper title='Добавление услуги'>
      <Col>
        <Form onSubmit={handleFormSubmit}>
          {error && <Alert color="danger">{error}</Alert>}
          <FormGroup row>
            <Col sm={12}>
              <Label sm={2} for='name' style={{ width: "100%" }}>Наименование <b className='text-danger'>*</b></Label>
              <Input id='name' name='name' required onChange={(e) => setNewService({ ...newService, name: e.target.value })} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label sm={2} for='lastName' style={{ width: "100%" }}>Категория <b className='text-danger'>*</b></Label>
              <Select placeholder="Выберите категорию" id='lastName' name='lastName' type="select" options={makeOptions(categoryList)} required onChange={(value) => setNewService({ ...newService, category: value.value })} />
            </Col>
            <Col sm={6}>
              <Label sm={2} for='unit' style={{ width: "100%" }}>Усл. единица <b className='text-danger'>*</b></Label>
              <Select placeholder="Выберите условную единицу" id='unit' name='unit' type="select" options={makeOptions(unitList)} required onChange={(e) => setNewService({ ...newService, unit: e.value })} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label sm={3} for='persons' style={{ width: "100%" }}>Кол-во человек  <b className='text-danger'>*</b></Label>
              <Input id='persons' name='persons' required onChange={(e) => setNewService({ ...newService, people_count: e.target.value })} />
            </Col>
            <Col sm={6}>
              <Label sm={3} for='min' style={{ width: "100%" }}>Мин. значение <b className='text-danger'>*</b></Label>
              <Input id='min' name='min' required onChange={(e) => setNewService({ ...newService, min: e.target.value })} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <Label sm={4} for='startDate'>Время начала</Label>
              <Input id='startDate' name='startDate' type='time' onChange={(e) => setNewService({ ...newService, start_time: moment(e.target.value, ["H:mm"]).format("HH:mm:ss") })} />
            </Col>
            <Col sm={4}>
              <Label sm={4} for='endDate'>Время окончания</Label>
              <Input id='endDate' name='endDate' type='time' onChange={(e) => setNewService({ ...newService, finish_time: moment(e.target.value, ["H:mm"]).format("HH:mm:ss") })} />
            </Col>
            <Col sm={4}>
              <Label sm={4} for='price' style={{ width: "100%" }}>Стоимость <b className='text-danger'>*</b></Label>
              <Input id='price' name='price' required onChange={(e) => setNewService({ ...newService, price: e.target.value })} />
            </Col>
          </FormGroup>

          <Row className='mb-2'>
            <Col md={6}>
              <FormGroup>
                <Label for='status-select' style={{ width: "100%" }}>Статус <b className='text-danger'>*</b></Label>
                <Select placeholder="Выберите статус" options={serviceStatuses} required onChange={(e) => setNewService({ ...newService, status: e.value })} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for='id' style={{ width: "100%" }}>Id услуги <b className='text-danger'>*</b></Label>
                <Input id='id' name='id' required onChange={(e) => setNewService({ ...newService, type_id: e.target.value })} />
              </FormGroup>
            </Col>
          </Row>

          <Button type="submit" sm={2} color='success' onClick={function noRefCheck() { }}>
            Создать услугу
          </Button>{' '}
          <Button onClick={() => history.goBack()} className='mx-2'>
            Отмена
          </Button>
        </Form>
      </Col>
    </LagoonaPageWrapper>
  )
}
