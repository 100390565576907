/**
 * Converts 1234567 to 1 234 567
 * @param {number} price
 * @returns {string}
 */
export function hMoney(price) {
    return price.toString().split('')
        .reduceRight((() => {
            let index = 0;
            return (e1, e2) => {
                if ((index++ % 3) === 0) e1 = ' ' + e1;
                e1 = e2 + e1;
                return e1;
            }
        })(), '').trim();
}

export function hMillion(price) {
    return (price / 1_000_000).toFixed(2).toString();
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

/**
 * Converts date to YYYY-MM-DD HH-MM-SS
 * @param {date} date
 * @returns {string}
 */

export function formatDate(date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}

/**
* Converts date to YYYY-MM-DD
* @param {date} date
* @returns {string}
*/

export const formatDate2 = (date) => {
    if (!date) {
        return;
    }
    let d = date;
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
}