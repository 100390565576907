import MetaTags from 'react-meta-tags'
import React from 'react'
import { Card, Container } from 'reactstrap'
import { useHistory } from 'react-router-dom'

export default function LagoonaPageWrapper({
  // eslint-disable-next-line react/prop-types
  children,
  // eslint-disable-next-line react/prop-types
  title,
  // eslint-disable-next-line react/prop-types
  withBackButton = false,
  // eslint-disable-next-line react/prop-types
  disableContainer = false
}) {
  const history = useHistory()

  return (
    <div className='page-content'>
      {withBackButton && (
        <button
          onClick={() => history.goBack()}
          className='btn btn-lg btn-link'
        >
          <i
            className='bx bx-arrow-back
'
          />
        </button>
      )}
      <Container fluid>
        <MetaTags>
          <title>Lagoona | {title}</title>
        </MetaTags>
        <h4 className='mb-4'>{title}</h4>

        {!disableContainer && <Card className='p-3'>{children}</Card>}
        {disableContainer && children}
      </Container>
    </div>
  )
}
