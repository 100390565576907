import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
import PropTypes from 'prop-types'



//images import

import scooter from 'assets/images/authorization/water_scooter.svg'
import beach_amico from 'assets/images/authorization/beach_amico.svg';
import beach_bro from 'assets/images/authorization/beach_bro.svg'
import lifeguard_bro from 'assets/images/authorization/lifeguard_bro.svg'

const CarouselPage = (props) => {

  const { number } = props;

  const decidePicture = (number) => {
    switch (number) {
      case 1:
        return scooter;
      case 2:
        return beach_amico;
      case 3:
        return beach_bro;
      case 4:
        return lifeguard_bro;
      default:
        return scooter;
    }
  }
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            {/* <div className="bg-overlay"></div> */}
            <div className="d-flex h-100 flex-column justify-content-center">
              <img
                src={decidePicture(number)}
                height={'100%'}
                alt="scooter"
                className="auth-picture"
              />
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

CarouselPage.propTypes = {
  number: PropTypes.number,
}

export default CarouselPage;