import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardTitle, Col, FormGroup } from 'reactstrap'
import user1 from '../../assets/images/users/avatar-1.jpg'
import { Preloader } from '../../components/Preloader'

export default function OrderSourcesCard(props) {
  const items = [
    { value: props.fromSite, label: 'Сайт', icon: 'bxl-wordpress' },
    { value: props.fromReception, label: 'Ресепшен', icon: 'bxs-map' },
    { value: props.fromCall, label: 'Звонок', icon: 'bxs-phone-call' },
  ]
  const itemList = items.map((item, idx) => (
    <Col key={`order-source-card-item${idx}`} className='text-center'>
      <FormGroup row className='align-items-center'>
        <div className='mt-2 order-source-item'>
          <i className={`bx bx-lg ${item.icon} mb-2`} />
          <h3>{item.value}</h3>
          <h6 className='text-muted'>{item.label}</h6>
        </div>
      </FormGroup>
    </Col>
  ))
  return (
    <Card className='p-2'>
      <CardTitle>Источники заказов</CardTitle>
      <FormGroup row className='mt-2'>
        {props.state?.type === 'loading' && props.state?.data ? (
          <Preloader />
        ) : (
          itemList
        )}
      </FormGroup>
    </Card>
  )
}
OrderSourcesCard.propTypes = {
  fromSite: PropTypes.number.isRequired,
  fromReception: PropTypes.number.isRequired,
  fromCall: PropTypes.number.isRequired,
  state: PropTypes.object,
}
