import { Toast, ToastBody, ToastHeader } from "reactstrap";
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types'


export const ToastNotification = (props) => {
    const { title, content, icon, fade, close, closeAriaLabel } = props;
    return ReactDOM.createPortal(
        <div className="p-3 my-2 rounded" style={{ position: "fixed", right: 0, top: 0, zIndex: 9999 }}>
            <Toast fade={fade}>
                <ToastHeader close={close} closeAriaLabel={closeAriaLabel} icon={icon}>
                    {title}
                </ToastHeader>
                <ToastBody>
                    {content}
                </ToastBody>
            </Toast>
        </div>,
        document.body,
    );
}

// ToastNotification.propTypes = {
//     title: PropTypes.string,
//   }
