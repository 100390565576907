/* eslint-disable react/prop-types */
import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export default function ConfirmDialog(props) {
    return (
        <Modal isOpen={props.show} centered>
            <ModalHeader>{props.title}</ModalHeader>
            <ModalBody>{props.body}</ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => props.onClick()}>Да</Button>
                <Button onClick={() => props.onCancel()}>Нет</Button>
            </ModalFooter>
        </Modal>
    )
}