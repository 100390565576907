import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import {
    defaultColumnStyle,
    getDefaultPaginationSettings, getPagSettings,
} from '../../common/tableSettings'
import LagoonaTable from '../../components/LagoonaTable'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getEmplList } from "../../store/employees/actions";
import employeeReducer, { emplReducer } from "../../store/employees/reducer";
import { debounce } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import { clientsColumns } from "../Clients";
import { EmployeeService } from "../../services/employeeService";
import { ExportButton } from "../../components/ExportButton";
import { ClientsServices } from "../../services/clientsServices";
import { Preloader } from "../../components/Preloader";

const { SearchBar } = Search

/* eslint-disable react/display-name */
const columns = [
    {
        dataField: 'last_name',
        text: 'Фамилия',
        formatter: (cell) => <span>{cell}</span>,
        style: defaultColumnStyle,
    },
    {
        dataField: 'first_name',
        text: 'Имя',
        formatter: (cell) => <span>{cell}</span>,
        style: defaultColumnStyle,
    },
    {
        dataField: 'middle_name',
        text: 'Отчество',
        formatter: (cell) => <span>{cell}</span>,
        style: defaultColumnStyle,
    },
    { dataField: 'position', text: 'Должность', style: defaultColumnStyle },
    { dataField: 'roles', text: 'Роль', style: defaultColumnStyle, formatter: cell => cell.map(c => c.name).join(", "), },
    { dataField: 'phone', text: 'Телефон', style: defaultColumnStyle },
];

export default function Employees() {
    const history = useHistory();

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [employeeList, setEmployeeList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);

    const rowEvents = {
        onClick: (e, row, index) => {
            if (window.getSelection().toString()) {
                return;
            }

            history.push({
                pathname: '/employee-details',
                search: '?u=' + row.id,
            });
        }
    };

    const onTableChanged = (type, { page, sizePerPage, sortOrder }) => {
        setPage(page);
        setPageSize(sizePerPage);
    }

    const debouncedDispatch = debounce((_searchTerm) => {
        setSearchTerm(_searchTerm);
    }, 250);

    useEffect(async () => {
        const response = await EmployeeService.getEmployeeList({ searchTerm, pageSize, page });
        setEmployeeList(response.data.data);
    }, [searchTerm]);

    useEffect(async () => {
        const response = await EmployeeService.getEmployeeList({ page, pageSize, searchTerm });
        setEmployeeList(response.data.data);
        setLoading(false);
    }, []);

    useEffect(async () => {
        setLoading(true);
        const response = await EmployeeService.getEmployeeList({ pageSize, page, searchTerm });
        setEmployeeList(response.data.data);
        setLoading(false);
    }, [pageSize, page]);

    return (
        <LagoonaPageWrapper title='Сотрудники'>
            {loading ? <Preloader /> : (
                <>
                    <div style={{ display: 'flex', gap: 15, marginBottom: "24px" }}>
                        <div style={{ display: 'flex', gap: 15 }}>
                            <Input
                                onChange={(e) => debouncedDispatch(e.target.value)}
                                placeholder='Поиск по ФИО или телефону'
                                style={{
                                    width: 300,
                                }}
                            />
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <Link to='/add-employee'>
                                <button
                                    type='button'
                                    className='btn btn-success mx-2'
                                    style={{ width: 200 }}
                                >
                                    Добавить сотрудника
                                </button>
                            </Link>
                        </div>
                    </div>
                    <BootstrapTable
                        remote
                        keyField="id"
                        bordered={false}
                        data={employeeList.items}
                        columns={columns}
                        headerWrapperClasses='table-light'
                        wrapperClasses='mt-3'
                        hover
                        pagination={
                            getPagSettings({ page, sizePerPage: pageSize, totalSize: employeeList.total, length: employeeList.total })
                        }
                        onTableChange={onTableChanged}
                        rowEvents={rowEvents}
                    />
                </>
            )}
        </LagoonaPageWrapper>
    )
}
