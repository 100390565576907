import moment from 'moment'
import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { FormGroup, Input } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { defaultColumnStyle, getPagSettings } from '../../common/tableSettings'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { Preloader } from '../../components/Preloader'
import EditBraceletsModal from './edit.jsx'
import AddBraceletsModal from './add.jsx'
import RecordButton from './RecordButton'
import { BraceletsServices } from '../../services/braceletsServices'
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr'

export const braceletsColumns = [
    {
        dataField: 'created_at',
        text: 'Дата',
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: cell => {
            const m = moment(cell)
            return (
                <>
                    {m.format('D.MM.yyyy')}
                    <br />
                    <span className='text-black-50'>{m.format('HH:mm')}</span>
                </>
            )
        },
        headerStyle: {
            width: 150,
        },
        style: defaultColumnStyle,
    },
    {
        dataField: 'orange_adults',
        text: 'Оранжевые взрослые',
        style: defaultColumnStyle,
    },
    {
        dataField: 'orange_kids',
        text: 'Оранжевые до 12 лет',
        style: defaultColumnStyle,
    },
    {
        dataField: 'blue_adults',
        text: 'Синие взрослые',
        style: defaultColumnStyle,
    },
    {
        dataField: 'blue_kids',
        text: 'Синие до 12 лет',
        style: defaultColumnStyle,
    },
    {
        dataField: 'red',
        text: 'Красные',
        style: defaultColumnStyle,
    },
    {
        dataField: 'green',
        text: 'Зеленые',
        style: defaultColumnStyle,
    },
    {
        dataField: 'total',
        text: 'Всего',
        style: defaultColumnStyle,
    },
    {
        dataField: 'order_number',
        text: '№ Заказа',
        style: defaultColumnStyle,
    },
]

export default function BraceletsPage() {

    // Datepicker state
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [dateChange, setDateChange] = React.useState(false);

    // Table state
    const [braceletsList, setBraceletsList] = React.useState({ total: 0, items: [] });
    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(true);


    // Modals state
    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const [currentEditId, setCurrentEditId] = React.useState(undefined);
    const [isAddOpen, setIsAddOpen] = React.useState(false);
    const [toggle, setToggle] = React.useState(false);

    const rowEvents = {
        onClick: (e, row, index) => {
            if (window.getSelection().toString()) {
                return;
            }
            setCurrentEditId(row.id);
            setIsEditOpen(true);
        }
    };

    const onTableChanged = (type, { page, sizePerPage, sortOrder }) => {
        setPage(page);
        setPageSize(sizePerPage);
    }

    const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) setDateChange(!dateChange);
    };

    const fetch = async () => {
        setLoading(true);
        try {
            const data = await BraceletsServices.getBraceletsList({ page, pageSize });
            setBraceletsList(data.data.data);
        } catch (error) {
            toastr['error'](error, "Загрузка списка записей", exportToast);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        fetch()
    }, [pageSize, page, toggle])

    const toggleReload = () => {
        setToggle(!toggle);
    }

    const CreateReport = async () => {
        if (!startDate || !endDate) {
            return;
        }
        const start = moment(startDate).format('YYYY-MM-DD');
        const end = moment(endDate).format('YYYY-MM-DD');
        try {
            await BraceletsServices.createReport({ dateFrom: start, dateTo: end });
            toastr['success']('Отчет создан', "Создание отчета", exportToast);
        } catch (error) {
            toastr['error'](error, "Создание отчета", exportToast);
        }
    }

    return (
        <LagoonaPageWrapper title='Браслеты'>
            {' '}
            <EditBraceletsModal isOpen={isEditOpen} toggle={() => setIsEditOpen(false)} currentEditId={currentEditId} toggleReload={toggleReload} />
            <AddBraceletsModal isOpen={isAddOpen} toggle={() => setIsAddOpen(false)} toggleReload={toggleReload} />
            {loading ? <Preloader /> : (
                <React.Fragment>
                    <div style={{ display: 'flex', gap: 15, marginBottom: "24px" }}>
                        <div style={{ display: 'flex', gap: 15 }}>
                            <FormGroup>
                                <DatePicker
                                    locale='ru'
                                    dateFormat='dd.MM.yyyy'
                                    className='form-control'
                                    placeholderText="Выберите период"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(range) => onDateChange(range)}
                                    isClearable={true}
                                />
                            </FormGroup>
                        </div>
                        <div>
                            <RecordButton onClick={() => CreateReport()} />
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <button
                                type='button'
                                className='btn btn-success ms-2'
                                style={{ width: 200 }}
                                onClick={() => setIsAddOpen(true)}
                            >
                                Добавить запись
                            </button>
                        </div>
                    </div>
                    <BootstrapTable
                        remote
                        keyField="id"
                        data={braceletsList.items}
                        columns={braceletsColumns}
                        headerWrapperClasses='table-light'
                        wrapperClasses='mt-3'
                        hover
                        bordered={false}
                        pagination={
                            getPagSettings({ page, sizePerPage: pageSize, totalSize: braceletsList.total, length: braceletsList.total })
                        }
                        onTableChange={onTableChanged}
                        rowEvents={rowEvents}
                    />
                </React.Fragment>
            )}
        </LagoonaPageWrapper>
    )
}
