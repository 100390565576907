export const objects = [
  {
    title: 'Гостиница 1',
    columns: 4,
    rooms: [
      { title: '101' },
      { title: '102' },
      { title: '103' },
      { title: '104' },
      { title: '105' },
      { title: '201' },
      { title: '202' },
      { title: '203' },
      { title: '204' },
      { title: '205' },
    ],
  },
  {
    title: 'Гостиница 2',
    columns: 3,
    rooms: [
      { title: '1' },
      { title: '2' },
      { title: '3' },
      { title: '4' },
      { title: '5' },
      { title: 'К.Б' },
      { title: 'К.З' },
      { title: 'Баня' },
      { title: 'Бочка' },
    ],
  },
  {
    title: 'Хостел 1',
    columns: 2,
    rooms: [
      { title: '1' },
      { title: '2' },
      { title: '3' },
      { title: '4' },
      { title: '5' },
      { title: '6' },
    ],
  },
  {
    title: 'Хостел 2',
    columns: 2,
    rooms: [
      { title: '1' },
      { title: '2' },
      { title: '3' },
      { title: '4' },
      { title: '5' },
      { title: '6' },
    ],
  },
  {
    title: 'Хостел 3',
    columns: 2,
    rooms: [
      { title: '1' },
      { title: '2' },
      { title: '3' },
      { title: '4' },
      { title: '5' },
      { title: '6' },
    ],
  },
  {
    title: 'Хостел 4',
    columns: 2,
    rooms: [
      { title: '1' },
      { title: '2' },
      { title: '3' },
    ],
  },
  {
    title: 'Малые беседки',
    columns: 7,
    rooms: [
      { title: '3' },
      { title: '4' },
      { title: '5' },
      { title: '6' },
      { title: '7' },
      { title: '8-а' },
      { title: '8-б' },
      { title: '9' },
      { title: '10' },
      { title: '14' },
      { title: '15' },
      { title: '16' },
      { title: '17' },
      { title: '18' },
      { title: '21' },
      { title: '22' },
      { title: '25' },
      { title: '29' },
      { title: '31' },
    ],
  },
  {
    title: 'Средние беседки',
    columns: 7,
    rooms: [
      { title: '11' },
      { title: '12' },
      { title: '13' },
      { title: '20' },
      { title: '23' },
      { title: '24' },
      { title: '26' },
      { title: '27' },
      { title: '28' },
      { title: '34' },
      { title: '35' },
      { title: '36' },
      { title: '37' },
      { title: '38' },
      { title: '39' },
      { title: '40' },
      { title: '41' },
      { title: '42' },
    ],
  },
  {
    title: 'Бол. беседки',
    columns: 2,
    rooms: [
      { title: '1' },
      { title: '2' },
      { title: '19' },
      { title: '30' },
      { title: '32' },
      { title: '44' },
    ],
  },
]
