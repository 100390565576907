import React, { useEffect, useState } from 'react'
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import LagoonaTable from '../../components/LagoonaTable'
import { defaultColumnStyle, getPagSettings } from '../../common/tableSettings'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import moment from 'moment'
import BillFilters from './bill-filters'
import { ExportButton } from '../../components/ExportButton';
import { BillingServices } from 'services/billingService';
import BootstrapTable from 'react-bootstrap-table-next'
const { SearchBar } = Search;
import { Input } from 'reactstrap';
import StatusServ from 'services/statusServ';
/* eslint-disable react/display-name */
const columns = [
  {
    dataField: 'created_at',
    text: 'Дата',
    formatter: (cell) => (<>
      {moment(cell).format('D.MM.yyyy')}
      <br />
      <span className='text-black-50'>{moment(cell).format('HH:mm')}</span>
    </>),
    style: defaultColumnStyle
  },
  { dataField: 'order_number', text: '№ заказа', style: defaultColumnStyle },
  { dataField: 'client_first_name', text: 'Клиент', style: defaultColumnStyle },
  { dataField: 'client_phone', text: 'Телефон', style: defaultColumnStyle },
  { dataField: 'order_total_amount', text: 'Общая сумма', style: defaultColumnStyle },
  { dataField: 'amount', text: 'Сумма оплаты', style: defaultColumnStyle },
  {
    dataField: 'status',
    text: 'Статус',
    formatter: (status_name) => {
      var cell = StatusServ.getByTag(status_name);
      return (
        <h3>
          <Badge style={{ minWidth: "100px" }} className={"pb-2"} color={cell.color}><small>{cell.text}</small></Badge>
        </h3>
      )
    },
    style: defaultColumnStyle
  },
];


export default function Bills() {

  function get_params(id) {
    return { pageSize, page, search, client_id: id || '', ...filter }
  }

  const [filter, setFilter] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const [bilList, setBils] = useState({
    items: [],
    total: 0
  });

  const [search, setSearch] = useState('');

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('client_id')) {
      const id = urlParams.get('client_id');
      const response = await BillingServices.getInvoicesList(get_params(id));
      // const u = new URL(window.location.toString());
      // window.history.replaceState({}, document.title, u.origin);
      setBils(response.data.data);
      return
    }
    const response = await BillingServices.getInvoicesList(get_params());
    setBils(response.data.data);
  }, [pageSize, page, search, filter]);



  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);
  const rowEvents = {
    onClick: (e, row, index) => {
      history.push({
        pathname: `/bill-details`,
        search: `?u=${row.id}`,
      });
    }
  };

  const applyFilters = (newFilter) => {
    setFilter(newFilter);
    setShowFilters(false);
  };

  const onTableChanged = (type, { page, sizePerPage }) => {
    setPage(page);
    setPageSize(sizePerPage);
  }





  return (
    <ToolkitProvider keyField='id' data={bilList.items} columns={columns} search>
      {(props) => (
        <>
          <div style={{ display: 'flex', gap: 15 }}>
            <div style={{ display: 'flex', gap: 15 }}>
              <Input
                // eslint-disable-next-line react/prop-types
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Поиск по № заказа'
                style={{
                  width: 300,
                }}
              />
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <button
                type='button'
                className='btn btn-outline-success mx-2'
                onClick={() => setShowFilters(true)}
              >
                Фильтры
              </button>
              {/* <ExportButton/> */}
              <Link to='/add-bill' className='ms-2'>
                <Button
                  color="success"
                  style={{ width: 200 }}
                >
                  Выставить счет
                </Button>
              </Link>
            </div>
          </div>
          <BootstrapTable
            remote
            keyField="id"
            bordered={false}
            data={bilList.items}
            columns={columns}
            headerWrapperClasses='table-light'
            wrapperClasses='mt-3'
            hover
            pagination={getPagSettings({ page, sizePerPage: pageSize, totalSize: bilList.total, length: bilList.total })}
            onTableChange={onTableChanged}
            rowEvents={rowEvents}
          />
          <BillFilters show={showFilters} onApply={applyFilters} onCancel={() => setShowFilters(false)} />
        </>
      )}
    </ToolkitProvider>
  )
}
