import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {EDIT_PROFILE, GET_LAST_ACTIVITY, GET_PROFILE_DATA} from "./actionTypes"
import {profileSuccess, profileError, setProfileData, setLastActivity} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeProfile,
  postJwtProfile,
} from "../../../helpers/fakebackend_helper"
import {AuthServices} from "../../../services/authServices";

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      )
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    }
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* getProfile() {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "original") {
      const response = yield call(AuthServices.getProfile, {});
      yield put(setProfileData(response.data.data.employee));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* getLastActivity() {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "original") {
      const response = yield call(AuthServices.getLastActivity, {});
      yield put(setLastActivity(response.data.data));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(GET_PROFILE_DATA, getProfile);
  yield takeEvery(GET_LAST_ACTIVITY, getLastActivity);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
