
export const RESTORE_PASSWORD_REQUEST = "RESTORE_PASSWORD_REQUEST"
export const RESTORE_PASSWORD_TOKEN = "RESTORE_PASSWORD_TOKEN"

export const RESTORE_PASSWORD_ERROR = "RESTORE_PASSWORD_ERROR"
export const RESTORE_PASSWORD_STATUS = "RESTORE_PASSWORD_STATUS"

export const RESTORE_PASSWORD_CONFIRM = "RESTORE_PASSWORD_CONFIRM"
export const RESTORE_PASSWORD_CONFIRM_ERROR = "RESTORE_PASSWORD_CONFIRM_ERROR"

export const RESTORE_PASSWORD_RESEND = "RESTORE_PASSWORD_RESEND"
