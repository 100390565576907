import React from 'react'
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { BraceletsServices } from '../../services/braceletsServices';
import _ from 'lodash';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr'



const PossibleErrorModal = (props) => {
    const { isOpen, toggle } = props;

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center">
                <Modal isOpen={isOpen} centered size="md" toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        <span color="danger">
                            Ошибка редактирования
                        </span>
                    </ModalHeader>
                    <ModalBody>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => toggle()}>Назад</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment>
    )
}

PossibleErrorModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};


export default function EditBraceletsModal(props) {

    const { isOpen, toggle, currentEditId, toggleReload } = props;

    // Modal state
    const [orangeAdult, setOrangeAdult] = React.useState('');
    const [orangeChild, setOrangeChild] = React.useState('');

    const [blueAdult, setBlueAdult] = React.useState('');
    const [blueChild, setBlueChild] = React.useState('');

    const [redAll, setRedAll] = React.useState('');
    const [greenAll, setGreenAll] = React.useState('');

    const [orderId, setOrderId] = React.useState({ label: '', value: '' });

    const resetModalValues = () => {
        setOrangeAdult('');
        setOrangeChild('');
        setBlueAdult('');
        setBlueChild('');
        setRedAll('');
        setGreenAll('');
        setOrderId('');
    }

    const ModalToggle = () => {
        resetModalValues()
        toggle();
    }

    const Apply = async () => {
        try {

            const data = {
                orange_adults: orangeAdult ? orangeAdult : 0,
                orange_kids: orangeChild ? orangeChild : 0,
                blue_adults: blueAdult ? blueAdult : 0,
                blue_kids: blueChild ? blueChild : 0,
                red: redAll ? redAll : 0,
                green: greenAll ? greenAll : 0,
                order_id: orderId ? orderId.value : null
            }

            await BraceletsServices.updateBracelets(currentEditId, data);
            toastr['success']("Запись успешно отредактирована", "Редактирование записи", exportToast);
            toggleReload();
            ModalToggle();
        }
        catch (error) {
            toastr['error'](error, "Редактирование записи", exportToast);
        }
    }

    const Delete = async () => {
        try {
            await BraceletsServices.deleteBracelets(currentEditId);
            toastr['info']("Запись успешно удалена", "Удаление записи", exportToast);
            toggleReload();
            ModalToggle();
        } catch (error) {
            toastr['error'](error, "Удаление записи", exportToast);
        }
    }

    const searchForOrder = async (input) => {
        let orders = await BraceletsServices.searchOrder(input);
        orders = orders.data.data.items;
        let res = [];
        orders.forEach((val, key) => {
            res.push({ value: val.id, label: val.number });
        });
        return res;
    }

    const loadOptions = React.useCallback(
        _.debounce((inputText, callback) => {
            searchForOrder(inputText).then((options) => callback(options));
        }, 600),
        []
    );

    const preventNonNumeric = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    const onOrangeAdultChange = (e) => {
        if (e.length > 3) return;
        setOrangeAdult(e);
    }

    const onOrangeChildChange = (e) => {
        if (e.length > 3) return;
        setOrangeChild(e);
    }

    const onBlueAdultChange = (e) => {
        if (e.length > 3) return;
        setBlueAdult(e);
    }

    const onBlueChildChange = (e) => {
        if (e.length > 3) return;
        setBlueChild(e);
    }

    const onRedAllChange = (e) => {
        if (e.length > 3) return;
        setRedAll(e);
    }

    const onGreenAllChange = (e) => {
        if (e.length > 3) return;
        setGreenAll(e);
    }

    const onLoadSetter = (data) => {
        setOrangeAdult(data.orange_adults);
        setOrangeChild(data.orange_kids);
        setBlueAdult(data.blue_adults);
        setBlueChild(data.blue_kids);
        setRedAll(data.red);
        setGreenAll(data.green);
        if (data.order_id) {
            setOrderId({ label: data.order_number, value: data.order_id });
        }
    }

    const fetch = async () => {
        try {
            const data = await BraceletsServices.getBraceletsById(currentEditId);
            onLoadSetter(data.data.data.bracelet);
        } catch (error) {
            toastr['error'](error, "Загрузка записи", exportToast);
        }
    }

    React.useEffect(() => {
        if (!currentEditId || !isOpen) return;
        fetch();
    }, [isOpen]);

    return (
        <React.Fragment>
            {currentEditId !== undefined ?
                <div className="d-flex justify-content-center">
                    <Modal isOpen={isOpen} centered size="md" toggle={ModalToggle} >
                        <ModalHeader toggle={ModalToggle}>
                            {`Редактирование записи №${currentEditId}`}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup className="align-items-baseline">
                                        <Label md="auto">Оранжевые взрослые</Label>
                                        <Input
                                            placeholder={0}
                                            type='number'
                                            min="0"
                                            onKeyPress={(e) => preventNonNumeric(e)}
                                            onChange={(e) => onOrangeAdultChange(e.target.value)}
                                            value={orangeAdult}
                                        />
                                    </FormGroup>
                                    <FormGroup className="align-items-baseline">
                                        <Label md="auto">Синие взрослые</Label>
                                        <Input
                                            placeholder={0}
                                            type='number'
                                            min="0"
                                            onKeyPress={(e) => preventNonNumeric(e)}
                                            onChange={(e) => onBlueAdultChange(e.target.value)}
                                            value={blueAdult}
                                        />
                                    </FormGroup>
                                    <FormGroup className="align-items-baseline">
                                        <Label md="auto">Красные</Label>
                                        <Input
                                            placeholder={0}
                                            type='number'
                                            min="0"
                                            onKeyPress={(e) => preventNonNumeric(e)}
                                            onChange={(e) => onRedAllChange(e.target.value)}
                                            value={redAll}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup className="align-items-baseline">
                                        <Label md="auto">Оранжевые до 12 лет</Label>
                                        <Input
                                            placeholder={0}
                                            type='number'
                                            min="0"
                                            onKeyPress={(e) => preventNonNumeric(e)}
                                            onChange={(e) => onOrangeChildChange(e.target.value)}
                                            value={orangeChild}
                                        />
                                    </FormGroup>
                                    <FormGroup className="align-items-baseline">
                                        <Label md="auto">Синие до 12 лет</Label>
                                        <Input
                                            placeholder={0}
                                            type='number'
                                            min="0"
                                            onKeyPress={(e) => preventNonNumeric(e)}
                                            onChange={(e) => onBlueChildChange(e.target.value)}
                                            value={blueChild}
                                        />
                                    </FormGroup>
                                    <FormGroup className="align-items-baseline">
                                        <Label md="auto">Зеленые</Label>
                                        <Input
                                            placeholder={0}
                                            type='number'
                                            min="0"
                                            onKeyPress={(e) => preventNonNumeric(e)}
                                            onChange={(e) => onGreenAllChange(e.target.value)}
                                            value={greenAll}
                                        />
                                    </FormGroup>
                                </Col>
                                <FormGroup className="align-items-baseline">
                                    <Label md="auto">Заказ</Label>
                                    <AsyncSelect
                                        id='orderId'
                                        name='orderId'
                                        placeholder="Поиск"
                                        isClearable
                                        loadOptions={loadOptions}
                                        onChange={e => setOrderId(e)}
                                        value={orderId} />
                                </FormGroup>
                            </Row>
                        </ModalBody>
                        <ModalFooter className="d-flex mt-2">
                            <Button color='danger' className='me-auto ms-1' onClick={() => Delete()}>Удалить</Button>
                            <Button color="success" onClick={() => Apply()}>Сохранить</Button>
                        </ModalFooter>
                    </Modal>
                </div> : <PossibleErrorModal isOpen={isOpen} toggle={toggle} />}
        </React.Fragment>
    )
}

EditBraceletsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    currentEditId: PropTypes.number,
    toggleReload: PropTypes.func.isRequired,
};
