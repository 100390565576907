import React, { useEffect, useState } from 'react'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Bills from './bills'
import Payments from './payments'
import PropTypes from 'prop-types'


function Billing(props) {

  const [activeTab, setActiveTab] = useState(props?.match?.params?.id === 'payments' ? 'payments' : 'invoice');

  useEffect(() => {
    props.history.replace({ pathname: '/billing/' + activeTab, state:{isActive: true}})
  }, [activeTab])

  return (
    <LagoonaPageWrapper title='Биллинг'>
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink className={activeTab === 'invoice' ? 'active' : ''} onClick={() => setActiveTab('invoice')}>
            Счета
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab === 'payments' ? 'active' : ''} onClick={() => setActiveTab('payments')}>
            Платежи
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="invoice" className="pt-4">
          <Bills />
        </TabPane>
        <TabPane tabId="payments" className="pt-4">
          <Payments />
        </TabPane>
      </TabContent>
    </LagoonaPageWrapper>
  )
}

Billing.propTypes = {
  history: PropTypes.Obj,
  match: PropTypes.Obj,
};

export default Billing
