import React, { useEffect, useState } from 'react'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import FullCalendar, { ViewApi } from '@fullcalendar/react'
import { useHistory } from 'react-router-dom'
import ruLocale from '@fullcalendar/core/locales/ru'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Card, Col, FormGroup, Label, Row, Button } from 'reactstrap'
import Select from 'react-select'
import { Tooltip } from 'bootstrap'
import { TimeTableServices } from '../../services/timetableServices'






// const eventList = [
//   {
//     title: 'Заказ №1846',
//     start: '2022-05-02',
//     end: '2022-05-04',
//     backgroundColor: colorMap.success,
//     name: 'Петров А.В.',
//     phone: '+7 (987) 654-321-00',
//     services: 3,
//     sum: 333.3,
//     status: 'Предоплачен',
//   },
//   {
//     title: 'Заказ №1847',
//     start: '2022-05-05',
//     end: '2022-05-06',
//     backgroundColor: colorMap.success,
//     name: 'Иванов А.В.',
//     phone: '+7 (987) 654-321-01',
//     services: 2,
//     sum: 222.2,
//     status: 'Предоплачен',
//   },
//   {
//     title: 'Заказ №1848',
//     start: '2022-05-04',
//     backgroundColor: colorMap.danger,
//     name: 'Смирнов А.В.',
//     phone: '+7 (987) 654-321-02',
//     services: 1,
//     sum: 111.1,
//     status: 'Удалён',
//   },
//   {
//     title: 'Заказ №1849',
//     start: '2022-05-06',
//     backgroundColor: colorMap.warning,
//     name: 'Смирнов А.В.',
//     phone: '+7 (987) 654-321-02',
//     services: 1,
//     sum: 111.1,
//     status: 'Черновик',
//   },
//   {
//     title: 'Заказ №1850',
//     start: '2022-05-07',
//     backgroundColor: colorMap.warning,
//     name: 'Петров А.В.',
//     phone: '+7 (987) 654-321-02',
//     services: 1,
//     sum: 111.1,
//     status: 'Черновик',
//   },
//   {
//     title: 'Заказ №1851',
//     start: '2022-05-08',
//     backgroundColor: colorMap.info,
//     name: 'Смирнов А.В.',
//     phone: '+7 (987) 654-321-02',
//     services: 1,
//     sum: 111.1,
//     status: 'Новый',
//   },
//   {
//     title: 'Заказ №1852',
//     start: '2022-05-06',
//     backgroundColor: colorMap.info,
//     name: 'Петров А.В.',
//     phone: '+7 (987) 654-321-02',
//     services: 1,
//     sum: 111.1,
//     status: 'Новый',
//   },
// ]



function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

const formatDate2 = (date) => {
  if (!date) {
    return;
  }
  let d = date;
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export default function Timetable() {
  const history = useHistory();

  const renderEventContent2 = (info) => {
    return (
      <div>
        {info.event.title}
      </div>
    )
  }
  const [categoriesList, setCategoriesList] = useState([]);
  const [statusesList, setStatusesList] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateChanged, setDateChanged] = useState(false);

  const [currentCategory, setCurrentCategory] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');

  const [serviceList, setServiceList] = useState('');


  const onDateClick = info => {
    console.log(info.dateStr)
  }

  const colorMap = {
    deleted: '#F46A6A',
    canceled: '#74788D',
    draft: '#F1B44C',
    new: '#50A5F1',
    awaiting: '#C0C334',
    prepayment: '#34C38F',
    paid: '#0E8A47',
    finished: '#5350F1',
    primary: '#F1B44C',
  }

  const defaultEventData = {
    textColor: 'white',
    className: 'text-center',
  }

  const DecideColor = (status) => {
    switch (status) {
      case 'Удалён':
        return colorMap.deleted;
      case 'Отменен':
        return colorMap.canceled;
      case 'Черновик':
        return colorMap.draft;
      case 'Новый':
        return colorMap.new;
      case 'Ожидает предоплаты':
        return colorMap.awaiting;
      case 'Предоплачен':
        return colorMap.prepayment;
      case 'Оплачен':
        return colorMap.paid;
      case 'Завершен':
        return colorMap.finished;
      default:
        return colorMap.primary;
    }
  }

  const MakeData = (data) => {
    const events = data.map((event, i) => ({
      ...defaultEventData,
      service_id: event.id,
      id: event.id,
      start: new Date(event.booking_date_from),
      end: new Date(new Date(event.booking_date_to).setHours(new Date(event.booking_date_to).getHours() + 1)),
      name: event.client_name,
      phone: event.client_phone,
      sum: event.total_amount,
      title: `Заказ № ${event.number}`,
      status: event.status.name,
      backgroundColor: DecideColor(event.status.name),
    }))
    setServiceList(events);
  }

  useEffect(async () => {
    const response = await TimeTableServices.getTimetableCategoriesList();

    const data = response?.data.data.services;
    const categories = Object.keys(data).map((e) => {
      return {
        id: e,
        value: data[e],
        label: data[e],
      }
    });

    const data2 = response?.data.data.statuses;
    const statuses = data2.map((e) => { return { key: e.id, value: e.name, label: e.name } });

    setStatusesList(statuses);
    setCategoriesList(categories);
  }, [])

  const setDates = (start, end) => {
    if (startDate === start) return;
    if (endDate === end) return;
    setStartDate(start);
    setEndDate(end);
    setDateChanged(!dateChanged);
  }

  const clearFilters = () => {
    setCurrentCategory('');
    setCurrentStatus('');
  }
  const additions = '';

  useEffect(async () => {
    if (!startDate || !endDate) return;
    const dateFrom = formatDate(startDate);
    const dateTo = formatDate(endDate);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('service_id')) {
      const id = urlParams.get('service_id');
      const response = await TimeTableServices.getOrdersList({ dateFrom, dateTo, service_ids: id, statuses: currentStatus.key });
      const u = new URL(window.location.toString());
      window.history.replaceState({}, document.title, u.origin);
      MakeData(response?.data.data.items)
      return;
    }

    const response = await TimeTableServices.getOrdersList({ dateFrom, dateTo, service_ids: currentCategory.id, statuses: currentStatus.key });
    console.log("makeData", response)
    MakeData(response?.data.data.items)
  }, [dateChanged, currentCategory, currentStatus])

  return (
    <LagoonaPageWrapper title='Расписание' disableContainer>
      <Card className='p-4'>
        <FormGroup row>
          <Col md={3}>
            <Label>Услуга</Label>
            <Select id='user' value={currentCategory} onChange={(e) => setCurrentCategory(e)} options={categoriesList} placeholder='Поиск' menuPortalTarget={document.body} />
          </Col>
          <Col md={3}>
            <Label>Статусы</Label>
            <Select id='statuses' value={currentStatus} onChange={(e) => setCurrentStatus(e)} options={statusesList} placeholder='Поиск' menuPosition={'fixed'} menuPortalTarget={document.body} />
          </Col>
          <Col style={{ display: "flex", "alignItems": "end" }}>
            {currentCategory || currentStatus ?
              <Button
                color="secondary"
                style={{ "margin-bottom": "1px" }}
                onClick={() => clearFilters()}
              >
                Сброс
              </Button> : null}
          </Col>
        </FormGroup>
        <FullCalendar
          dayMaxEvents={3}
          moreLinkContent={(args) => {
            return 'Показать все';
          }}
          moreLinkHint={(args) => {
            return 'Перейти на заказы';
          }}
          moreLinkClick={(args) => {
            const date = new Date(args.date).toISOString()

            history.push(`/orders?created_at=${date}`)
          }}
          eventDisplay='block'
          eventTextColor='white'
          locale={'ru'}
          datesSet={(args) => setDates(new Date(args.view.currentStart), new Date(args.view.currentEnd))}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView='dayGridMonth'
          eventContent={renderEventContent2}
          events={serviceList}
          moreLinkText={'Показать все'}
          moreLinkClassNames={'h3'}
          dateClick={onDateClick}
          eventDidMount={data => {
            const { name, phone, sum, status } = data.event.extendedProps
            console.log("makeData", name, data.event.extendedProps)
            const content =
              `${name}<br/>${phone}<br/>` +
              `Сумма: ${sum}₽<br/>${status}`
            new Tooltip(data.el, { title: content, html: true })
          }}
          eventWillUnmount={(data) => {
            const tooltip = Tooltip.getInstance(data.el);
            tooltip?.dispose()
          }}
          buttonText={
            {
              today: 'сегодня',
              month: 'месяц',
              week: 'неделя',
              day: 'день',
              list: 'список'
            }
          }
          navLinks={true}
          navLinkDayClick={(date) => {
            // TODO: выяснить какой формат даты нужен + встречать этот параметр на странице заказов
            history.push(`/orders?date=${date.toLocaleDateString()}`);
          }}
          eventClick={({ event }) => {
            history.push(`/order-details?id=${event.id}`);
          }}
        />
      </Card>
    </LagoonaPageWrapper>
  )
}
