import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class TimeTableServices {

    static getOrdersList = async ({ dateFrom, dateTo, service_ids = '', statuses = '' }) => {
        return BaseApi.getRequest({
            url: `order-calendar/list?&booking_date_from=${dateFrom}&booking_date_to=${dateTo}`
                + (service_ids ? `&service_ids=${service_ids}` : '')
                + (statuses ? `&statuses=${statuses}` : ''),
            jwt: getToken(),
        });
    };

    static getTimetableCategoriesList = async () => {
        return BaseApi.getRequest({
            url: 'order-calendar/form-filter',
            jwt: getToken(),
        });
    };
}