export const SET_EMPL_LOADING = "SET_EMPL_LOADING";

export const SET_EMPL_LIST = "SET_EMPL_LIST";

export const SET_EMPL_ERROR = "SET_EMPL_ERROR";

export const GET_EMPL_LIST = "GET_EMPL_LIST";

export const SET_EMPL_ROLES = "SET_EMPL_ROLES";

export const GET_EMPL_ROLES = "GET_EMPL_ROLES";

export const CREATE_USER = "CREATE_USER";

export const USER_CREATED = "USER_CREATED";