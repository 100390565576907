import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Button, Card, CardFooter, CardTitle } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import LagoonaTable from '../../components/LagoonaTable'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { Link } from 'react-router-dom'
import { Preloader } from '../../components/Preloader'
import { defaultColumnStyle } from '../../common/tableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { getTop5ClientsData } from '../../store/actions';
import { hMoney } from '../../utils/toHuman';


const columns = [
  {
    dataField: 'id',
    text: '#',
    sort: false,
    style: defaultColumnStyle,
    headerStyle: {
      width: '80px'
    },
  },
  {
    dataField: 'clientName',
    text: 'Клиент',
    sort: false,
    style: defaultColumnStyle,
  },
  {
    dataField: 'phone',
    text: 'Телефон',
    sort: false,
    style: defaultColumnStyle,
  },
  {
    dataField: 'totalOrders',
    text: 'Всего заказов',
    sort: false,
    style: defaultColumnStyle,
  },
  {
    dataField: 'discount',
    text: 'Сумма скидки',
    style: defaultColumnStyle,
    // formatter: (cell) => <span>{cell}&#8381;</span>,
  },
  {
    dataField: 'totalPrice',
    text: 'Общая сумма',
    sort: false,
    style: defaultColumnStyle,
    // formatter: (cell) => <span>{cell}&#8381;</span>,
  },
];

export default function TopClientsCard(props) {
  const history = useHistory();
  const [info, setInfo] = useState([])
  const { dateRange, dateChange } = props;

  const dispatch = useDispatch()

  const { top5ClientsState, top5ClientsData } = useSelector(state => {
    return {
      top5ClientsState: state.Dashboard.top5ClientsState,
      top5ClientsData: state.Dashboard.top5ClientsData,
    }
  });

  useEffect(() => {
    dispatch(getTop5ClientsData(dateRange[0], dateRange[1]))
  }, [dateChange])

  useEffect(() => {
    const d = top5ClientsData?.data.items.map((e, i) => ({
      id: i + 1,
      clientId: e.client_id,
      clientName: e.fio,
      phone: e.phone,
      totalOrders: e.count,
      discount: hMoney(e.discount_amount || 0) + '₽',
      totalPrice: hMoney(e.total_amount || 0) + '₽',
    }))
    setInfo(d);
  }, [top5ClientsData])

  return (
    <Card className='p-2'>
      <CardTitle>ТОП-5 клиентов</CardTitle>
      {top5ClientsState?.type === 'loading' && top5ClientsState?.data ? (
        <Preloader />
      ) : (
        <ToolkitProvider keyField='id' data={info || []} columns={columns} search>
          {(props) => (
            <LagoonaTable
              // eslint-disable-next-line react/prop-types
              {...props.baseProps}
              rowEvents={{
                onClick: (e, row) => {
                  history.push(`/client-details?u=${row.clientId}`);
                }
              }}
            />
          )}
        </ToolkitProvider>)}
      <CardFooter>
        <div className="text-end">
          <Link to="/analytics-client">Все клиенты</Link>
        </div>
      </CardFooter>
    </Card>
  )
}

TopClientsCard.propTypes = {
  dateRange: PropTypes.array,
  dateChange: PropTypes.number
}
