import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class ServiceServices {
    static getServiceList = ({
        page = 1,
        pageSize = 10,
        categoriesList = null,
        unitFilter = "",
        people_count_from = null,
        people_count_to = null,
        price_min = 0,
        price_max = 1,
        searchTerm = "",
    }) => {
        const filters =
            `${people_count_to ? `&people_count_from=${people_count_from}` : ""}${people_count_from ? `&people_count_to=${people_count_to}` : ""}${categoriesList ? `&category=${categoriesList}` : ""}${unitFilter ? `&unit=${unitFilter}` : ""}${price_min ? `&price_min=${price_min}` : ""}${price_max ? `&price_max=${price_max}` : ""}`;

        return BaseApi.getRequest({
            url: `service/list?page=${page}&pageSize=${pageSize}&sort=id&direction=asc${filters}&column=${searchTerm}`,
            jwt: getToken(),
        });
    };

    static getServiceDetails = async id => {
        return BaseApi.getRequest({
            url: `service/${id}/read`,
            jwt: getToken(),
        });
    };

    static deleteSerivce = async id => {
        return BaseApi.deleteRequest({
            url: `service/${id}/delete`,
            jwt: getToken(),
        });
    };

    static makeExport = async ({ dateFrom, dateTo }) => {
        const formData = new FormData();
        formData.append("format", "xlsx");
        formData.append("action", "services_list");
        formData.append("params[from]", dateFrom);
        formData.append("params[to]", dateTo);

        return BaseApi.postRequest({
            url: 'export/create',
            data: formData,
            jwt: getToken(),
        });
    };

    static getExportList = async () => {
        return BaseApi.getRequest({
            url: "export/list?page=0&pageSize=1000&sort=id&direction=desc&action=services_list",
            jwt: getToken(),
        });
    };

    static deleteExportItem = async id => {
        return BaseApi.deleteRequest({
            url: `export/${id}/delete`,
            jwt: getToken(),
        });
    };

    static getFormFilter = async () => {
        return BaseApi.getRequest({
            url: "service/form-filter",
            jwt: getToken(),
        });
    };

    static createService = async cred => {
        const formData = new FormData();
        formData.append("type_id", cred.type_id);
        formData.append("name", cred.name);
        formData.append("status", cred.status);
        formData.append("category", cred.category);
        formData.append("people_count", cred.people_count);
        formData.append("unit", cred.unit);
        formData.append("price", cred.price);
        formData.append("min", cred.min);

        if (cred.start_time) {
            formData.append("start_time", cred.start_time);
        }

        if (cred.finish_time) {
            formData.append("finish_time", cred.finish_time);
        }

        return BaseApi.postRequest({
            url: "/service/create",
            jwt: getToken(),
            data: formData,
        });
    };

    static updateService = async (id, cred) => {
        const formData = new FormData();
        formData.append("type_id", cred.type_id);
        formData.append("name", cred.name);
        formData.append("status", cred.status);
        formData.append("category", cred.category);
        formData.append("people_count", cred.people_count);
        formData.append("unit", cred.unit);
        formData.append("price", cred.price);
        formData.append("min", cred.min);

        if (cred.start_time) {
            formData.append("start_time", cred.start_time);
        }

        if (cred.finish_time) {
            formData.append("finish_time", cred.finish_time);
        }

        return BaseApi.postRequest({
            url: `service/${id}/update`,
            jwt: getToken(),
            data: formData,
        });
    };

    static uploadFromFile = async (formData) => {
        return BaseApi.postRequest({
            url: "service/upload",
            data: formData,
            jwt: getToken(),
        });
    }

    static getOrderInputSearch = async ({ booking_date_from, booking_date_to, service_name }) => {
        return BaseApi.getRequest({
            jwt: getToken(),
            url: `order/input-search?booking_date_from=${booking_date_from}&booking_date_to=${booking_date_to}`
                + (service_name ? `&service_name=${service_name}` : ''),
        });
    }
}