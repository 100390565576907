import React from 'react';
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Badge, Button, Card, CardFooter, CardTitle } from 'reactstrap'
import { defaultColumnStyle } from '../../common/tableSettings'
import LagoonaTable from '../../components/LagoonaTable'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { Link } from 'react-router-dom'
import { menuItems } from '../../common/menu';
import { Preloader } from '../../components/Preloader';
import { hMoney } from '../../utils/toHuman';

const columns = [
  {
    dataField: 'id',
    text: '#',
    style: defaultColumnStyle,
    headerStyle: {
      width: 80,
    }
  },
  { dataField: 'name', text: 'Вид трафика', style: defaultColumnStyle },
  { dataField: 'orders', text: 'Поступило заказов', style: defaultColumnStyle, },
  { dataField: 'total', text: 'Сумма', style: defaultColumnStyle },
  {
    dataField: 'details',
    text: 'Детали',
    style: defaultColumnStyle,
    // eslint-disable-next-line react/display-name
    formatter: (cell) => (
      <h3>
        <Badge style={{ minWidth: "100px" }} className="pb-2" color={cell.color}><small>{cell.text}</small></Badge>
      </h3>),
  },
];




export default function TrafficSourcesCard(props) {
  const { state, items } = props;
  const history = useHistory();

  let data = Array.from(items ? items : []).map((_, id) => ({
    id: id + 1,
    name: _.traffic_source || '',
    orders: _.orders_count || 0,
    total: hMoney(_.amount_paid || 0),
    details: {
      color: 'info',
      text: 'Посмотреть'
    }
  }));

  const transformToId = (name) => {
    switch (name) {
      case 'Сайт':
        return 1;
      case 'Звонок':
        return 2;
      case 'Ресепшн':
        return 3;
      case 'Баннерная реклама':
        return 4;
      case 'Буклетная реклама':
        return 5;
      case 'Инстаграм':
        return 6;
      case 'Вконтакте':
        return 7;
      case 'Телеграм':
        return 8;
      case 'По рекомендации':
        return 9;
      case 'Постоянный клиент':
        return 10;
      case 'Радио-реклама':
        return 11;
      case 'Контекстная реклама (сайт)':
        return 12;
      case 'Транспортная реклама':
        return 13;
      default:
        return 14;
    }

  }


  return (
    state?.type === 'loading' && state?.data ?
      <Preloader /> :
      <Card className='p-2'>
        <CardTitle>Источники трафика</CardTitle>
        <ToolkitProvider keyField='id' data={data} columns={columns} search>
          {(props) => (
            <LagoonaTable
              // eslint-disable-next-line react/prop-types
              {...props.baseProps}
              rowEvents={{
                onClick: (e, row) => {
                  history.push(`/orders?traffic_source=${transformToId(row.name)}`);
                }
              }}
            />
          )}
        </ToolkitProvider>
      </Card>
  )
}

TrafficSourcesCard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  state: PropTypes.object,
}