const menuItems = [
  { to: '/dashboard', icon: 'bx bx-bar-chart-square', text: 'Аналитика' },
  { to: '/orders', icon: 'bx bx-copy-alt', text: 'Заказы' },
  { to: '/bracelets', icon: 'bx bx-circle', text: 'Браслеты' },
  { to: '/clients', icon: 'bx bx-user-circle', text: 'Клиенты' },
  { to: '/services', icon: 'bx bx-home-alt', text: 'Услуги' },
  { to: '/timetable', icon: 'bx bx-calendar', text: 'Расписание' },
  { to: '/map', icon: 'bx bx-map-alt', text: 'Карта объекта' },
  { to: '/employees', icon: 'bx bx-user', text: 'Сотрудники' },
  { to: '/billing', icon: 'bx bx-receipt', text: 'Биллинг' },
]

const marketologLinks = [
  { to: '/orders', icon: 'bx bx-copy-alt', text: 'Заказы' },
  { to: '/clients', icon: 'bx bx-user-circle', text: 'Клиенты' },
]

const operatorLinks = [
  { to: '/orders', icon: 'bx bx-copy-alt', text: 'Заказы' },
  { to: '/bracelets', icon: 'bx bx-circle', text: 'Браслеты' },
  { to: '/clients', icon: 'bx bx-user-circle', text: 'Клиенты' },
  { to: '/services', icon: 'bx bx-home-alt', text: 'Услуги' },
  { to: '/timetable', icon: 'bx bx-calendar', text: 'Расписание' },
  { to: '/map', icon: 'bx bx-map-alt', text: 'Карта объекта' },
  { to: '/billing', icon: 'bx bx-receipt', text: 'Биллинг' },
]

export { menuItems, marketologLinks, operatorLinks }
