import React from "react";
import PropTypes from "prop-types";
import { Card, CardText, CardTitle, Col, FormGroup } from "reactstrap";
import Chart from "react-apexcharts";
import { Preloader } from "../../components/Preloader";
import { hMoney } from "../../utils/toHuman";

const chartOptions = {
  chart: {
    id: 'dashboard-chart1',
    height: 240
  },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 135,
      dataLabels: {
        name: {
          fontSize: '12px',
          color: '#adb5bd',
          offsetY: 48
        }
      }
    }
  },
  stroke: {
    dashArray: 5
  },
  labels: ['']
};

export default function PaymentsCard(props) {

  const summary = props.cashTotal + props.nonCashTotal;
  const cashPercent = Math.floor(props.cashTotal * 100 / (summary + 1));
  const nonCashPercent = 100 - cashPercent;
  const nonCashSeries = [nonCashPercent];
  const cashSeries = [cashPercent];

  const cashChartOptions = { ...chartOptions, labels: ['Безнал'] };
  const nonCashChartOptions = { ...chartOptions, labels: ['Наличные'] };

  console.log(props);

  return (
    <Card className="p-2">
      {
        props.state?.type === "loading" && props.state?.data ? (
          <Preloader />
        ) : (
          <>
            <CardTitle>Форма расчета</CardTitle>
            <CardText>Указано в процентном и денежном соотношении</CardText>
            <FormGroup row>
              <Col className="text-center">
                <Chart
                  type="radialBar"
                  options={cashChartOptions}
                  series={nonCashSeries}
                />
                <h3>{hMoney(props.nonCashTotal) || 0}&#8381;</h3>
                Поступило безналичным способом
              </Col>
              <Col className="text-center">
                <Chart
                  type="radialBar"
                  options={nonCashChartOptions}
                  series={cashSeries}
                />
                <h3>{hMoney(props.cashTotal) || 0}&#8381;</h3>
                Поступило наличным способом
              </Col>
            </FormGroup>
          </>
        )
      }
    </Card>
  )
}
PaymentsCard.propTypes = {
  state: PropTypes.object,
  cashTotal: PropTypes.number.isRequired,
  nonCashTotal: PropTypes.number.isRequired
}