/** Get Dashboard Chart data */
export const API_SUCCESS = 'API_SUCCESS'
export const API_FAIL = 'API_FAIL'
export const GET_CHARTS_DATA = 'GET_CHARTS_DATA'

export const GET_DASHBOARD_SUMMARY = 'GET_DASHBOARD_SUMMARY'
export const SET_DASHBOARD_SUMMARY = 'SET_DASHBOARD_SUMMARY'
export const SET_DASHBOARD_STATE = 'SET_DASHBOARD_STATE'

export const SET_PAYMENT_METHOD_DATA = 'SET_PAYMENT_METHOD_DATA'
export const GET_PAYMENT_METHOD_DATA = 'GET_PAYMENT_METHOD_DATA'
export const SET_PAYMENT_METHOD_STATE = 'SET_PAYMENT_METHOD_STATE'

export const GET_ORDER_SOURCE_DATA = 'GET_ORDER_SOURCE_DATA'
export const SET_ORDER_SOURCE_DATA = 'SET_ORDER_SOURCE_DATA'
export const SET_ORDER_SOURCE_STATE = 'SET_ORDER_SOURCE_STATE'

export const GET_GRAPH_INCOME_DATA = 'GET_GRAPH_INCOME_DATA'
export const SET_GRAPH_INCOME_DATA = 'SET_GRAPH_INCOME_DATA'
export const SET_GRAPH_INCOME_STATE = 'SET_GRAPH_INCOME_STATE'

export const GET_TRAFFIC_SOURCE_DATA = 'GET_TRAFFIC_SOURCE_DATA'
export const SET_TRAFFIC_SOURCE_DATA = 'SET_TRAFFIC_SOURCE_DATA'
export const SET_TRAFFIC_SOURCE_STATE = 'SET_TRAFFIC_SOURCE_STATE'

export const GET_TOP5_CLIENTS_DATA = 'GET_TOP5_CLIENTS_DATA'
export const SET_TOP5_CLIENTS_DATA = 'SET_TOP5_CLIENTS_DATA'
export const SET_TOP5_CLIENTS_STATE = 'SET_TOP5_CLIENTS_STATE'

export const GET_TOP5_ORDERS_DATA = 'GET_TOP5_ORDERS_DATA'
export const SET_TOP5_ORDERS_DATA = 'SET_TOP5_ORDERS_DATA'
export const SET_TOP5_ORDERS_STATE = 'SET_TOP5_ORDERS_STATE'

export const GET_TOP5_SERVICE_DATA = 'GET_TOP5_SERVICE_DATA'
export const SET_TOP5_SERVICE_DATA = 'SET_TOP5_SERVICE_DATA'
export const SET_TOP5_SERVICE_STATE = 'SET_TOP5_SERVICE_STATE'

