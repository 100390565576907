import React, { useState, useEffect } from 'react'
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import LagoonaTable from '../../components/LagoonaTable'
import { defaultColumnStyle, getPagSettings } from '../../common/tableSettings'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import moment from 'moment'
import PayFilters from './pay-filters'
import { ExportButton } from '../../components/ExportButton';
import { BillingServices } from 'services/billingService';
import { Input } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import StatusServ from 'services/statusServ';
import { useSelector } from 'react-redux'
import toastr from "toastr";
import { exportToast } from '../../utils/toastr';

const { SearchBar } = Search;

const PAY_LIST = {
  CASH: "Наличные",
  NONCASH: "Безналичные",
  ONLINE_PAYMENT: "Онлайн-платеж"
};



/* eslint-disable react/display-name */
const columns = [
  {
    dataField: 'created_at',
    text: 'Дата',
    formatter: (cell) => (<>
      {moment(cell).format('D.MM.yyyy')}
      <br />
      <span className='text-black-50'>{moment(cell).format('HH:mm')}</span>
    </>),
    style: defaultColumnStyle
  },
  { dataField: 'id', text: '№', style: defaultColumnStyle, headerStyle: { width: '70px' } },
  { dataField: 'name', text: 'Наименование', style: defaultColumnStyle },
  { dataField: 'purpose', text: 'Назначение', style: defaultColumnStyle },
  { dataField: 'amount', text: 'Сумма', style: defaultColumnStyle },
  {
    dataField: 'method',
    text: 'Способ оплаты',
    formatter: (cell) => {
      var cell = PAY_LIST[cell];
      return (
        <>
          {cell}
        </>)
    },
    style: defaultColumnStyle
  },
  {
    dataField: 'statusText',
    text: 'Статус',
    formatter: (cell) => (
      <h3>
        <Badge style={{ minWidth: "100px" }} className={"pb-2"} color={StatusServ.getByName(cell).color}><small>{cell}</small></Badge>
      </h3>),
    headerStyle: {
      width: '180px',
    },
    style: defaultColumnStyle
  },
];


export default function Payments() {

  function get_params(id) {
    return { pageSize, page, search, client_id: id || '', ...filter }
  }

  const [filter, setFilter] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const [payList, setPays] = useState({
    items: [],
    total: 0
  });

  const [search, setSearch] = useState('');

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('client_id')) {
      const id = urlParams.get('client_id');
      const response = await BillingServices.getPaymentsList(get_params(id));
      setPays(response.data.data);
      return
    }
    const response = await BillingServices.getPaymentsList(get_params());
    setPays(response.data.data);
  }, [pageSize, page, search, filter]);

  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);
  const rowEvents = {

    onClick: (e, row, index) => {
      history.push({
        pathname: `/pay-details`,
        search: `?u=${row.id}`,
      });
    }
  };

  const { roles } = useSelector(state => {
    return {
      roles: state.Login.roles,
    }
  })

  const applyFilters = (newFilter) => {
    setFilter(newFilter);
    setShowFilters(false);
  };

  const onTableChanged = (type, { page, sizePerPage }) => {
    setPage(page);
    setPageSize(sizePerPage);
  }

  const handleExport = async () => {
    let dateFrom = moment().subtract(1, "month").format("YYYY-MM-DD");
    if (filter && filter.date_from) dateFrom = filter.date_from;
    let dateTo = moment().format("YYYY-MM-DD");
    if (filter && filter.date_to) dateTo = filter.date_to;

    let status = false;
    if (filter && filter.status) status = filter.status.value;

    toastr['success']('Экспорт данных запущен', 'Экспорт', exportToast);
    await BillingServices.makeExport({ dateTo, dateFrom, status });
  };

  const restrictAccess = (roles) => {
    if (roles[0] === 'ADMIN') return false;
    if (roles[0] === 'OPERATOR') return true;
    else return false;
  }

  return (
    <ToolkitProvider keyField='id' data={payList.items} columns={columns} search>
      {(props) => (
        <>
          <div style={{ display: 'flex', gap: 15 }}>
            <div style={{ display: 'flex', gap: 15 }}>
              <Input
                onChange={(e) => setSearch(e.target.value)}
                // eslint-disable-next-line react/prop-types
                placeholder='Поиск по наименованию'
                style={{
                  width: 300,
                }}
              />
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <button
                type='button'
                className='btn btn-outline-success mx-2'
                onClick={() => setShowFilters(true)}
              >
                Фильтры
              </button>
              <ExportButton onClick={handleExport} action="payments_list" />
            </div>
          </div>
          <BootstrapTable
            remote
            keyField="id"
            data={payList.items}
            columns={columns}
            headerWrapperClasses='table-light'
            wrapperClasses='mt-3'
            hover
            bordered={false}
            pagination={getPagSettings({ page, sizePerPage: pageSize, totalSize: payList.total, length: payList.total })}
            onTableChange={onTableChanged}
            rowEvents={rowEvents}
          />
          <PayFilters show={showFilters} onApply={applyFilters} onCancel={() => setShowFilters(false)} />
        </>
      )}
    </ToolkitProvider>
  )
}
