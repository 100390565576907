import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class OrderAnalyticsServices {

    static getOrdersList = async (
        {
            pageSize = 10,
            page = 0,
            sort = "amount_paid",
            direction = "desc",
            dateFrom,
            dateTo,
            days_from,
            days_to,
            services_count_from,
            services_count_to,
            amount_paid_from,
            amount_paid_to,
        }) => {
        try {
            return BaseApi.getRequest({
                url: `analytics/order/list?page=${page}&pageSize=${pageSize}&sort=${sort}&direction=${direction}&booking_date_from=${dateFrom}&booking_date_to=${dateTo}`
                    + (days_from ? `&days_from=${days_from}` : '')
                    + (days_to ? `&days_to=${days_to}` : '')
                    + (services_count_from ? `&services_count_from=${services_count_from}` : '')
                    + (services_count_to ? `&services_count_to=${services_count_to}` : '')
                    + (amount_paid_from ? `&amount_paid_from=${amount_paid_from}` : '')
                    + (amount_paid_to ? `&amount_paid_to=${amount_paid_to}` : ''),
                jwt: getToken(),
            });
        } catch (error) {
            console.log(error)
        }
    };

    static getOrdersSummary = async ({ dateFrom, dateTo }) => {
        try {
            return BaseApi.getRequest({
                url: `analytics/order/summary?booking_date_from=${dateFrom}&booking_date_to=${dateTo}`,
                jwt: getToken(),
            })
        } catch (error) {
            console.log(error)
        }

    }

    static makeExport = async ({ dateFrom, dateTo, ...filters }) => {
        const exportDate = {
            format:"xlsx",
            action:"analytics_orders_list",
            params:{
                from:dateFrom,
                to:dateTo
            }
        }
        return BaseApi.postRequest({
            url: 'export/create',
            data: JSON.stringify(exportDate),
            jwt: getToken(),
            headers:{ "Content-Type": "application/json"}
        });
    };
}