import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class OrdersService {
    static list = async ({
        pageSize = 10,
        page = 0,
        search = "",
        client_id = false,
        date_from = false,
        date_to = false,
        status = false,
        amount_from = false,
        amount_to = false,
        sort = "created_at",
        direction = "desc",
        order_source = false,
        traffic_source = false,
        booking_date_from = false,
        booking_date_to = false,
        super_date = false
    }) => {
        return BaseApi.getRequest({
            url: `order/list?page=${page}&pageSize=${pageSize}&sort=${sort}&direction=${direction}` +
                (search ? `&column=${search}` : ``) +
                (client_id ? `&client_id=${client_id}` : ``) +
                (date_from ? `&date_from=${date_from}` : ``) +
                (date_to ? `&date_to=${date_to}` : ``) +
                (status ? `&status=${status.value}` : ``) +
                (order_source ? `&order_source=${order_source.value}` : ``) +
                (traffic_source ? `&traffic_source=${traffic_source.value}` : ``) +
                (amount_from ? `&total_amount_from=${amount_from}` : ``) +
                (amount_to ? `&total_amount_to=${amount_to}` : ``) +
                (booking_date_from ? `&booking_date_from=${booking_date_from}` : ``) +
                (booking_date_to ? `&booking_date_to=${booking_date_to}` : ``) +
                (super_date ? `&created_at_from=${super_date}` : '') +
                (super_date ? `&created_at_to=${super_date}` : ''),
            jwt: getToken(),
        });
    };

    static create = async (order) => {

        const formData = new FormData();

        formData.append("client_id", order.client_id);
        formData.append("client_email", order.client_email);
        formData.append("client_phone", order.client_phone);

        return BaseApi.postRequest({
            url: 'employee/create',
            jwt: getToken(),
            data: formData,
        });
    }

    static fullCreate = async (order) => {

        if (!order.client_email) {
            delete order.client_email;
        }

        if (!order.kids_before_seven) {
            delete order.kids_before_seven;
        }

        if (!order.kids_after_seven) {
            delete order.kids_after_seven;
        }

        if (!order.birthdate) {
            delete order.birthdate;
        }

        return BaseApi.postRequest({
            url: 'order/create',
            jwt: getToken(),
            data: order,
        });
    }

    static read = async (id) => {
        return BaseApi.getRequest({
            url: `order/${id}/read`,
            jwt: getToken(),
        });
    };

    static update = async (id, order) => {

        const formData = new FormData();

        formData.append("client_id", order.client_id);
        formData.append("client_email", order.client_email);
        formData.append("client_phone", order.client_phone);

        return BaseApi.postRequest({
            url: `order/${id}/update`,
            jwt: getToken(),
            data: formData,
        });
    };


    static fullUpdate = async (id, order) => {

        if (!order.client_email) {
            delete order.client_email;
        }

        if (!order.birthdate) {
            delete order.birthdate;
        }

        if (!order.traffic_source) {
            delete order.traffic_source;
        }

        if (!order.order_source) {
            delete order.order_source;
        }

        if (!order.discount) {
            delete order.discount;
        }

        if (!order.manager_comment) {
            delete order.manager_comment
        }

        return BaseApi.postRequest({
            url: `order/${id}/update`,
            jwt: getToken(),
            data: order,
        });
    }

    static setStatus = async (id, status) => {
        return BaseApi.postRequest({
            url: `order/${id}/change-status`,
            jwt: getToken(),
            data: { status },
        });
    }

    static getBilets = async ({ booking_date_from,
        booking_date_to,
        adults_count,
        kids_after_seven,
        service_ids
    }) => {
        if (!kids_after_seven) kids_after_seven = 0;
        return BaseApi.getRequest({
            url: `order/services?booking_date_from=${booking_date_from}` +
                `&booking_date_to=${booking_date_to}` +
                `&adults_count=${adults_count}` +
                `&kids_after_seven=${kids_after_seven}` +
                `&service_ids=${service_ids}`,
            jwt: getToken()
        });
    }

    static addItem = async (data) => {
        return BaseApi.postRequest({
            url: `order/add-item`,
            jwt: getToken(),
            data: data,
        });
    }

    static delItem = async (data) => {
        return BaseApi.deleteRequest({
            url: `order/remove-item`,
            jwt: getToken(),
            data: data,
        });
    }

    static delete = async (id) => {
        return BaseApi.deleteRequest({
            url: `order/${id}/delete`,
            jwt: getToken(),
        });
    };

    static payments = async (id) => {
        return BaseApi.getRequest({
            url: `order/${id}/payments`,
            jwt: getToken(),
        });
    };

    static history = async (id) => {
        return BaseApi.getRequest({
            url: `order/${id}/history`,
            jwt: getToken(),
        });
    };

    static invoices = async (id) => {
        return BaseApi.getRequest({
            url: `order/${id}/invoices`,
            jwt: getToken(),
        });
    };

    static items = async (id) => {
        return BaseApi.getRequest({
            url: `order/${id}/items`,
            jwt: getToken(),
        });
    };

    static makeExport = async ({ dateFrom, dateTo, status = false, ...filter }) => {
        const formData = new FormData();
        formData.append("format", "xlsx");
        formData.append("action", "orders_list");
        formData.append("params[from]", dateFrom);
        formData.append("params[to]", dateTo);

        if (status) {
            formData.append("params[status]", status.value);
        }
        if (filter) {
            if (filter.order_source) formData.append("params[order_source]", filter.order_source.value);
            if (filter.traffic_source) formData.append("params[traffic_source]", filter.traffic_source.value);
            if (filter.booking_date_from) formData.append("params[booking_date_from]", filter.booking_date_from);
            if (filter.booking_date_to) formData.append("params[booking_date_to]", filter.booking_date_to);
            if (filter.amount_from) formData.append("params[amount_from]", filter.amount_from);
            if (filter.amount_to) formData.append("params[amount_to]", filter.amount_to);
        }
        return BaseApi.postRequest({
            url: 'export/create',
            data: formData,
            jwt: getToken(),
        });
    };

    static getOrdersFilter = async () => {
        return BaseApi.getRequest({
            url: `order/form-filter`,
            jwt: getToken(),
        });
    };

    static getUsers = async ({ phone = false, fio = false }) => {
        return BaseApi.getRequest({
            url: `client/input-search?` +
                (phone ? `&phone=${phone}` : ``) +
                (fio ? `&fio=${fio}` : ``),
            jwt: getToken(),
        });
    };
}