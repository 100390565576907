import { call, put, takeEvery, all, fork } from 'redux-saga/effects'

// Crypto Redux States
import {
  GET_CHARTS_DATA,
  GET_DASHBOARD_SUMMARY,
  GET_PAYMENT_METHOD_DATA,
  GET_ORDER_SOURCE_DATA,
  GET_GRAPH_INCOME_DATA,
  GET_TRAFFIC_SOURCE_DATA,
  GET_TOP5_CLIENTS_DATA,
  GET_TOP5_ORDERS_DATA,
  GET_TOP5_SERVICE_DATA,
} from './actionTypes'
import {
  apiSuccess,
  apiFail,
  setDashboardState,
  setDashboarSummary,
  setDashboardSummary,
  setPaymentMethodState,
  setPaymentMethodData,
  setOrderSourceState,
  setOrderSourceData,
  setGraphIncomeState,
  setGraphIncomeData,
  setTrafficSourceState,
  setTrafficSourceData,
  setTop5ClientsState,
  setTop5ClientsData,
  setTop5OrdersData,
  setTop5OrdersState,
  setTop5ServiceState,
  setTop5ServiceData,
} from './actions'

//Include Both Helper File with needed methods
import {
  getWeeklyData,
  getYearlyData,
  getMonthlyData,
} from '../../helpers/fakebackend_helper'
import { DashboardServices } from '../../services/dashboardServices'
import { apiError } from '../auth/login/actions'

function* getChartsData({ payload: periodType }) {
  try {
    var response
    if (periodType == 'monthly') {
      response = yield call(getWeeklyData, periodType)
    }
    if (periodType == 'yearly') {
      response = yield call(getYearlyData, periodType)
    }
    if (periodType == 'weekly') {
      response = yield call(getMonthlyData, periodType)
    }

    yield put(apiSuccess(GET_CHARTS_DATA, response))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* getDashboardSummary(action) {
  try {
    yield put(setDashboardState({ type: 'loading', data: true }))
    const response = yield call(DashboardServices.getDashboardSummary, action.dateFrom, action.dateTo)
    yield put(setDashboardState({ type: 'loading', data: false }))
    yield put(setDashboardSummary(response.data.data))
  } catch (error) {
    yield put(setDashboardState({ type: 'error', data: error }))
  }
}

function* getPaymentMethodData(action) {
  try {
    yield put(setPaymentMethodState({ type: 'loading', data: true }))
    const response = yield call(DashboardServices.getPaymentMethodData, action.dateFrom, action.dateTo)
    yield put(setPaymentMethodState({ type: 'loading', data: false }))
    yield put(setPaymentMethodData(response.data.data))
  } catch (error) {
    yield put(setDashboardState({ type: 'error', data: error }))
  }
}

function* getOrderSourceData(action) {
  try {
    yield put(setOrderSourceState({ type: 'loading', data: true }))
    const response = yield call(DashboardServices.getOrderSourceData, action.dateFrom, action.dateTo)
    yield put(setOrderSourceState({ type: 'loading', data: false }))
    yield put(setOrderSourceData(response.data))
  } catch (error) {
    console.log('get order source', error)
  }
}

function* getGraphIncomeData(action) {
  try {
    yield put(setGraphIncomeState({ type: 'loading', data: true }));
    const response = yield call(DashboardServices.getGraphIncomeData, action.group, action.dateFrom, action.dateTo);
    yield put(setGraphIncomeState({ type: 'loading', data: false }));
    yield put(setGraphIncomeData(response.data))
  } catch (error) {
    console.log('get graph income', error)
  }
}

function* getTrafficSourceData(action) {
  try {
    yield put(setTrafficSourceState({ type: 'loading', data: true }));
    const response = yield call(DashboardServices.getTrafficSourceData, action.dateFrom, action.dateTo);
    yield put(setTrafficSourceState({ type: 'loading', data: false }));
    yield put(setTrafficSourceData(response.data))
  } catch (error) {
    console.log('get graph income', error)
  }
}

function* getTop5ClientsData(action) {
  try {
    yield put(setTop5ClientsState({ type: 'loading', data: true }));
    const response = yield call(DashboardServices.getTop5ClientsData, action.dateFrom, action.dateTo);
    yield put(setTop5ClientsState({ type: 'loading', data: false }));
    yield put(setTop5ClientsData(response.data))
  } catch (error) {
    console.log(error)
  }
}

function* getTop5OrdersData(action) {
  try {
    yield put(setTop5OrdersState({ type: 'loading', data: true }));
    const response = yield call(DashboardServices.getTop5OrdersData, action.dateFrom, action.dateTo);
    yield put(setTop5OrdersState({ type: 'loading', data: false }));
    yield put(setTop5OrdersData(response.data))
  } catch (error) {
    console.log(error)
  }
}

function* getTop5ServiceData(action) {
  try {
    yield put(setTop5ServiceState({ type: 'loading', data: true }));
    const response = yield call(DashboardServices.getTop5ServiceData, action.dateFrom, action.dateTo);
    yield put(setTop5ServiceState({ type: 'loading', data: false }));
    yield put(setTop5ServiceData(response.data))
  } catch (error) {
    console.log(error)
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData)
  yield takeEvery(GET_DASHBOARD_SUMMARY, getDashboardSummary)
  yield takeEvery(GET_PAYMENT_METHOD_DATA, getPaymentMethodData)
  yield takeEvery(GET_ORDER_SOURCE_DATA, getOrderSourceData)
  yield takeEvery(GET_GRAPH_INCOME_DATA, getGraphIncomeData)
  yield takeEvery(GET_TRAFFIC_SOURCE_DATA, getTrafficSourceData)
  yield takeEvery(GET_TOP5_CLIENTS_DATA, getTop5ClientsData)
  yield takeEvery(GET_TOP5_ORDERS_DATA, getTop5OrdersData)
  yield takeEvery(GET_TOP5_SERVICE_DATA, getTop5ServiceData)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])
}

export default dashboardSaga
