import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR, SET_CHANGE_PASSWORD_LOADING, SET_CHANGE_PASSWORD_ERROR, SET_PASSWORD_CHANGED, SET_USER_ROLES,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  changePasswordLoading: false,
  changePasswordError: "",
  isPasswordChanged: false,
  roles: [],
}

const login = (state = initialState, action) => {

  switch (action.type) {
    case SET_PASSWORD_CHANGED:
      state = {
        ...state,
        isPasswordChanged: action.payload,
      };
      break;
    case SET_USER_ROLES:
      state = {
        ...state,
        roles: action.payload,
      }
      break;
    case SET_CHANGE_PASSWORD_LOADING:
      state = {
        ...state,
        changePasswordLoading: action.payload,
      }
      break;
    case SET_CHANGE_PASSWORD_ERROR:
      state = {
        ...state,
        changePasswordError: action.payload,
      };
      break;
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
