export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const SET_CHANGE_PASSWORD_LOADING = "SET_CHANGE_PASSWORD_LOADING";

export const SET_CHANGE_PASSWORD_ERROR = "SET_CHANGE_PASSWORD_ERROR";

export const SET_PASSWORD_CHANGED = "SET_PASSWORD_CHANGED";

export const SET_USER_ROLES = "SET_USER_ROLES"
