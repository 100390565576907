import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  SET_DASHBOARD_SUMMARY,
  SET_DASHBOARD_STATE,
  SET_PAYMENT_METHOD_DATA,
  SET_PAYMENT_METHOD_STATE,
  SET_ORDER_SOURCE_DATA,
  SET_ORDER_SOURCE_STATE,
  SET_GRAPH_INCOME_DATA,
  SET_GRAPH_INCOME_STATE,
  SET_TRAFFIC_SOURCE_DATA,
  SET_TRAFFIC_SOURCE_STATE,
  SET_TOP5_CLIENTS_DATA,
  SET_TOP5_CLIENTS_STATE,
  SET_TOP5_ORDERS_DATA,
  SET_TOP5_ORDERS_STATE,
  SET_TOP5_SERVICE_DATA,
  SET_TOP5_SERVICE_STATE
} from './actionTypes'

const INIT_STATE = {
  chartsData: [],
  dashboardSummary: null,
  dashboardState: null,
  paymentMethodData: null,
  paymentMethodState: null,
  orderSourceData: null,
  orderSourceState: null,
  graphIncomeData: null,
  graphIncomeState: null,
  trafficSourceData: null,
  trafficSourceState: null,
  top5ClientsData: null,
  top5ClientsState: null,
  top5OrdersData: null,
  top5OrdersState: null,
  top5ServiceData: null,
  top5ServiceState: null,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHOD_DATA:
      return {
        ...state,
        paymentMethodData: action.payload,
      }
    case SET_PAYMENT_METHOD_STATE:
      return {
        ...state,
        paymentMethodState: action.payload,
      }
    case SET_DASHBOARD_SUMMARY:
      return {
        ...state,
        dashboardSummary: action.payload,
      }
    case SET_DASHBOARD_STATE:
      return {
        ...state,
        dashboardState: action.payload,
      }
    case SET_ORDER_SOURCE_DATA:
      return {
        ...state,
        orderSourceData: action.payload.data,
      }
    case SET_ORDER_SOURCE_STATE:
      return {
        ...state,
        orderSourceState: action.payload,
      }
    case SET_GRAPH_INCOME_DATA:
      return {
        ...state,
        graphIncomeData: action.payload
      }
    case SET_GRAPH_INCOME_STATE:
      return {
        ...state,
        graphIncomeState: action.payload
      }
    case SET_TRAFFIC_SOURCE_DATA:
      return {
        ...state,
        trafficSourceData: action.payload,
      }
    case SET_TRAFFIC_SOURCE_STATE:
      return {
        ...state,
        trafficSourceState: action.payload,
      }
    case SET_TOP5_CLIENTS_DATA:
      return {
        ...state,
        top5ClientsData: action.payload,
      }
    case SET_TOP5_CLIENTS_STATE:
      return {
        ...state,
        top5ClientsState: action.payload,
      }
    case SET_TOP5_ORDERS_DATA:
      return {
        ...state,
        top5OrdersData: action.payload,
      }
    case SET_TOP5_ORDERS_STATE:
      return {
        ...state,
        top5OrdersState: action.payload,
      }
    case SET_TOP5_SERVICE_DATA:
      return {
        ...state,
        top5ServiceData: action.payload,
      }
    case SET_TOP5_SERVICE_STATE:
      return {
        ...state,
        top5ServiceState: action.payload,
      }
    case SET_TOP5_ORDERS_STATE:
      return {
        ...state,
        top5OrdersState: action.payload,
      }
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsData: action.payload.data,
          }
        default:
          return state
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsDataError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}

export default Dashboard
