import paginationFactory from 'react-bootstrap-table2-paginator'
import {Input} from 'reactstrap'
import React from 'react'

export const getPagSettings = ({length, page, sizePerPage, totalSize}) => {
    return paginationFactory({
        page,
        sizePerPage,
        totalSize,
        showTotal: true,
        sizePerPageList: [
            {
                text: 'По 10',
                value: 10,
            },
            {
                text: 'По 30',
                value: 30,
            },
            {
                text: 'По 50',
                value: 50,
            },

            {
                text: `Все (${length})`,
                value: length,
            },
        ],

        // eslint-disable-next-line react/display-name
        sizePerPageRenderer: ({
                                  // eslint-disable-next-line react/prop-types
                                  options,
                                  // eslint-disable-next-line react/prop-types
                                  currSizePerPage,
                                  // eslint-disable-next-line react/prop-types
                                  onSizePerPageChange,
                              }) => {
            return <Input
                type='select'
                value={currSizePerPage}
                onChange={event => {
                    onSizePerPageChange(event.target.value)
                }}
                style={{display: 'inline', width: 90}}
            >
                {/* eslint-disable-next-line react/prop-types */}
                {options.map(option => (
                    <option key={option.text} value={option.page}>
                        {option.text}
                    </option>
                ))}
            </Input>;
        },
        // eslint-disable-next-line react/display-name
        paginationTotalRenderer: (from, to, size) => {
            console.log(from, to, size, "RENDERER");
            return  <span className='mx-3'>
                {from}-{to} из {size}
            </span>
        },
    })
}

const getDefaultPaginationSettings = (length, _onSizePerPageChange, isDisabled = false, defaultValue = false) =>
    paginationFactory({
        showTotal: true,
        sizePerPageList: defaultValue || [
            {
                text: 'По 10',
                value: 10,
            },
            {
                text: 'По 30',
                value: 30,
            },
            {
                text: 'По 50',
                value: 50,
            },

            {
                text: 'Все',
                value: length,
            },
        ],
        // eslint-disable-next-line react/display-name
        sizePerPageRenderer: ({
                                  // eslint-disable-next-line react/prop-types
                                  options,
                                  // eslint-disable-next-line react/prop-types
                                  currSizePerPage,
                                  // eslint-disable-next-line react/prop-types
                                  onSizePerPageChange,
                              }) => (
            <Input
                type='select'
                value={currSizePerPage}
                disabled={isDisabled}
                onChange={event => {
                    onSizePerPageChange(event.target.value)
                }}
                style={{display: 'inline', width: 90}}
            >
                {/* eslint-disable-next-line react/prop-types */}
                {options.map(option => (
                    <option key={option.text} value={option.page}>
                        {option.text}
                    </option>
                ))}
            </Input>
        ),
        // eslint-disable-next-line react/display-name
        paginationTotalRenderer: (from, to, size) => (
            <span className='mx-3'>
        {from}-{to} из {size}
      </span>
        ),
    })

const defaultColumnStyle = {
    verticalAlign: 'middle',
}

export {defaultColumnStyle, getDefaultPaginationSettings}
