import axios from "axios";

export const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_HOST,
});

axiosApi.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            window.localStorage.removeItem("authUser");
            window.location = "/login"
        }

        // if (error.response.status === 404) {
        //     window.location = "/pages-404"
        // }

        //TODO: ATM not good
        // if (error.response.status === 500) {
        //     window.location = "/pages-500"
        // }

        const message = error.response?.data?.message || "Ошибка";
        return Promise.reject(message);
    }
)

export class BaseApi {
    static makeAuthHeader = jwt => {
        return {
            "Authorization": `Bearer ${jwt}`,
        };
    }

    static deleteRequest = async ({ url, jwt, data = {}, headers }) => {
        return await axiosApi
            .delete(url, {
                data,
                headers: {
                    ...headers,
                    ...(jwt ? BaseApi.makeAuthHeader(jwt) : {}),
                }
            });
    }

    static postRequest = async ({ url, data, jwt, headers }) => {
        return await axiosApi
            .post(url, data, {
                headers: {
                    ...headers,
                    ...(jwt ? BaseApi.makeAuthHeader(jwt) : {}),
                }
            }).then(response => response.data);
    }

    static getRequest = async ({ url, jwt, headers }) => {
        return await axiosApi
            .get(url, {
                headers: {
                    ...headers,
                    ...(jwt ? BaseApi.makeAuthHeader(jwt) : {}),
                }
            });
    }
}