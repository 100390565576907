import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class ClientsAnalyticsServices {

    static getClientsList = async (
        {
            search = "",
            pageSize = 10,
            page = 0,
            sort = "total_amount",
            direction = "desc",
            dateFrom,
            dateTo,
            discount_amount_from,
            discount_amount_to,
            count_from,
            count_to,
            total_amount_from,
            total_amount_to,
        }) => {
        return BaseApi.getRequest({
            url: `analytics/client/list?page=${page}&pageSize=${pageSize}&sort=${sort}&direction=${direction}&booking_date_from=${dateFrom}&booking_date_to=${dateTo}`
                + (search ? `&column=${search}` : '')
                + (discount_amount_from ? `&discount_amount_from=${discount_amount_from}` : '')
                + (discount_amount_to ? `&discount_amount_to=${discount_amount_to}` : '')
                + (count_from ? `&count_from=${count_from}` : '')
                + (count_to ? `&count_to=${count_to}` : '')
                + (total_amount_from ? `&total_amount_from=${total_amount_from}` : '')
                + (total_amount_to ? `&total_amount_to=${total_amount_to}` : ''),
            jwt: getToken(),
        });
    };

    static getClientsSummary = async ({ dateFrom, dateTo }) => {
        try {
            return BaseApi.getRequest({
                url: `analytics/client/summary?booking_date_from=${dateFrom}&booking_date_to=${dateTo}`,
                jwt: getToken(),
            })
        } catch (error) {
            console.log(error)
        }

    }

    static makeExport = async ({ dateFrom, dateTo, ...filters }) => {
        const exportDate = {
            format:"xlsx",
            action:"analytics_clients_list",
            params:{
                from:dateFrom,
                to:dateTo
            }
        }
        return BaseApi.postRequest({
            url: 'export/create',
            data: JSON.stringify(exportDate),//formData,
            jwt: getToken(),
            headers:{ "Content-Type": "application/json"}
        });
    };

    static getExportList = async () => {
        return BaseApi.getRequest({
            url: "export/list?page=0&pageSize=1000&sort=id&direction=desc&action=clients_list",
            jwt: getToken(),
        });
    };

    static deleteExportItem = async id => {
        return BaseApi.deleteRequest({
            url: `export/${id}/delete`,
            jwt: getToken(),
        });
    }

    static createClient = async (cred) => {
        const formData = new FormData();
        formData.append("phone", cred.phone);
        formData.append("first_name", cred.first_name);
        cred.email ? formData.append("email", cred.email) : null;
        cred.last_name ? formData.append("last_name", cred.last_name) : null;
        cred.middle_name ? formData.append("middle_name", cred.middle_name) : null;
        cred.birthday ? formData.append("birthday", cred.birthday) : null;
        cred.discount ? formData.append("discount", cred.discount) : null;

        return BaseApi.postRequest({
            url: "client/create",
            jwt: getToken(),
            data: formData,
        })
    };
}