import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Button, Card, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { OrdersService } from "../../services/ordersService";
import AsyncSelect from 'react-select/async';
import { ClientsServices } from "../../services/clientsServices";
import toastr from "toastr";
import { exportToast } from '../../utils/toastr';

export default function OrderGeneralCard(props) {

  const [fio, setFio] = useState(null);
  const [client_phone, setPhone] = useState(null);
  const [client_email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [order_source, setOrderSource] = useState(null);
  const [traffic_source, setTrafficSource] = useState(null);
  const [booking_date_from, setBookingFrom] = useState('');
  const [booking_date_to, setBookingTo] = useState('');
  const [manager_comment, setComment] = useState('');
  const [adults_count, setAdults] = useState('');
  const [kids_before_seven, setBeforeSeven] = useState('');
  const [kids_after_seven, setAfterSeven] = useState('');
  const [discount, setDiscount] = useState('');
  const [client_id, setClientId] = useState('');
  const [uuid, setUuid] = useState('');


  useEffect(() => {
    props.setGenData({
      client_phone,
      client_email,
      birthdate,
      order_source,
      traffic_source,
      booking_date_from,
      booking_date_to,
      manager_comment,
      adults_count,
      kids_before_seven,
      kids_after_seven,
      discount,
      client_id,
      uuid
    })
  },
    [fio,
      client_phone,
      client_email,
      birthdate,
      order_source,
      traffic_source,
      booking_date_from,
      booking_date_to,
      manager_comment,
      adults_count,
      kids_before_seven,
      kids_after_seven,
      discount,
      uuid]
  )


  useEffect(() => {
    props.setSCURDIS(discount);
  }, [discount])


  const [sourceList, setOrdSourceList] = useState([]);
  const [trafficSourceList, setTrafics] = useState([]);
  useEffect(async () => {
    const response = await OrdersService.getOrdersFilter();
    let res = [];
    setUuid(response.data.data.uuid);
    let trafics = response.data.data.traffic_source;
    Object.keys(trafics).forEach(key => {
      res.push({ value: trafics[key].id, label: trafics[key].name });
    });
    setTrafics(res);


    res = [];
    let order_sources = response.data.data.order_source;
    Object.keys(order_sources).forEach(key => {
      res.push({ value: order_sources[key].id, label: order_sources[key].name });
    });
    setOrdSourceList(res);

  }, [true]);
  useEffect(() => {
    props.setSPeriod([booking_date_from, booking_date_to])
  }, [booking_date_from, booking_date_to])

  useEffect(async () => {
    if (props.modalUser) {
      selectUset(props.modalUser.data.client.id);
    }
  }, [props.modalUser]);

  useEffect(async () => {
    if (props.modalError) {
      toastr['error'](props.modalError, 'Ошибка', exportToast);
      setTimeout(() => {
        props.setModalError(false);
      }, 1500);
    }
  }, [props.modalError]);


  const getOptionsPhone = async (input) => {
    let users = await OrdersService.getUsers({ phone: input });
    users = users.data.data.items;
    let res = [];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.phone });
    });
    return res;
  }

  const getOptionsFio = async (input) => {
    let users = await OrdersService.getUsers({ fio: input });
    users = users.data.data.items;
    let res = [{ value: 'create', label: 'Создать нового клиента' }];
    users.forEach((val, key) => {
      res.push({ value: val.id, label: val.fullName });
    });
    return res;
  }


  const selectUset = async (id) => {
    let client = await ClientsServices.getClientInfo(id);
    client = client.data.data;
    setClientId(client.id);
    setFio({ value: client.id, label: client.fullName });
    setPhone({ value: client.id, label: client.phone });
    setEmail(client.email);
    setDiscount(client.discount)
    if (client.birthday) {
      setBirthdate(client.birthday);
    }
  }

  const onSelectUser = async (user) => {
    if (user.value === 'create') {
      props.onCreateUser();
    }
    else {
      selectUset(user.value)
    }
  };


  function genBillet(a, k) {
    props.setBilet({
      booking_date_from,
      booking_date_to,
      adults_count: a,
      kids_after_seven: k
    })
  }

  return (
    <Card className='p-4'>
      <FormGroup row>
        <Col md={10}>
          <CardTitle>Общая информация</CardTitle>
        </Col>
        <Col md={2} className={"text-end"}>
          <Button type="button" color="info" style={{ minWidth: "120px" }}>Новый</Button>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <FormGroup row>
            <Col>
              <Label md="auto" for="clientName">Клиент <b className='text-danger'>*</b></Label>
              <AsyncSelect defaultOptions={[{ value: 'create', label: 'Создать нового клиента' }]} value={fio} id="clientName" loadOptions={getOptionsFio} placeholder="Поиск" onChange={onSelectUser} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label md="auto" for="sourceList">Источник заказа <b className='text-danger'>*</b></Label>
              <Select id="sourceList" value={order_source} onChange={e => setOrderSource(e)} options={sourceList} placeholder="Поиск" />
            </Col>
          </FormGroup>
          <Label md="auto" for="startDate">Период <b className='text-danger'>*</b></Label>
          <FormGroup row className="align-items-baseline">
            <Col>
              <Input value={booking_date_from} onChange={e => { setBookingFrom(e.target.value); props.setPeriod([e.target.value, booking_date_to]) }} required id="startDate" type="date" />
            </Col>
            -
            <Col>
              <Input value={booking_date_to} onChange={e => { setBookingTo(e.target.value); props.setPeriod([booking_date_from, e.target.value]) }} required id="endDate" type="date" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label md="auto" for="comment">Комментарий</Label>
              <Input value={manager_comment} onChange={e => setComment(e.target.value)} id="comment" type="textarea" rows={6} style={{ resize: "none" }} />
            </Col>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup row>
            <Col>
              <Label md="auto" for="phone">Номер телефона <b className='text-danger'>*</b></Label>
              <AsyncSelect value={client_phone} id="phone" loadOptions={getOptionsPhone} onChange={onSelectUser} placeholder="Поиск" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label md="auto" for="email">Электронная почта</Label>
              <Input value={client_email} onChange={e => setEmail(e.target.value)} id="email" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label md="auto" for="people">Взрослых <b className='text-danger'>*</b></Label>
              <Input value={adults_count} onChange={e => { setAdults(e.target.value); genBillet(e.target.value, kids_after_seven) }} required type="number" id="people" />
            </Col>
            <Col>
              <Label md="auto" for="children">Детей до 7</Label>
              <Input value={kids_before_seven} onChange={e => setBeforeSeven(e.target.value)} type="number" id="children" />
            </Col>
            <Col>
              <Label md="auto" for="teens">Детей от 8 до 12</Label>
              <Input value={kids_after_seven} onChange={e => { setAfterSeven(e.target.value), genBillet(adults_count, e.target.value) }} type="number" id="teens" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label md="auto" for="birthDate">Дата рождения</Label>
              <Input value={birthdate} onChange={e => setBirthdate(e.target.value)} id="birthDate" type="date" />
            </Col>
            <Col>
              <Label md="auto" for="discount">Фикс. скидка</Label>
              <Input value={discount} onChange={e => {
                setDiscount(e.target.value);
                props.setSDIS(e.target.value);
              }} type="number" id="discount" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label md="auto" for="trafficSource">Источник трафика <b className='text-danger'>*</b></Label>
              <Select id="phone" value={traffic_source} onChange={e => setTrafficSource(e)} options={trafficSourceList} placeholder="Поиск" />
            </Col>
          </FormGroup>
        </Col>
      </FormGroup>
    </Card>
  )
}
OrderGeneralCard.propTypes = {
  onCreateUser: PropTypes.func.isRequired,
  modalUser: PropTypes.any.isRequired,
  modalError: PropTypes.any,
  setModalError: PropTypes.func,
  setGenData: PropTypes.func,
  setSDIS: PropTypes.func,
  setBilet: PropTypes.func,
  setSCURDIS: PropTypes.func,
  setPeriod: PropTypes.func,
  setSPeriod: PropTypes.func
}
