import { RESTORE_PASSWORD_CONFIRM, RESTORE_PASSWORD_ERROR, RESTORE_PASSWORD_REQUEST, RESTORE_PASSWORD_RESEND, RESTORE_PASSWORD_STATUS, RESTORE_PASSWORD_TOKEN } from "./actionTypes"


export const restorePasswordRequest = (phone, history) => {
  return {
    type: RESTORE_PASSWORD_REQUEST,
    payload: {
      phone,
      history,
    },
  }
}

export const restorePasswordError = error => {
  return {
    type: RESTORE_PASSWORD_ERROR,
    payload: error,
  }
}

export const restorePasswordStatus = state => {
  return {
    type: RESTORE_PASSWORD_STATUS,
    payload: state,
  }
}

export const restorePasswordToken = token => {
  return {
    type: RESTORE_PASSWORD_TOKEN,
    payload: {
      token,
    }
  }
}

export const restorePasswordConfirm = (token, code, history) => {
  return {
    type: RESTORE_PASSWORD_CONFIRM,
    payload: {
      token,
      code,
      history,
    }
  }
}

export const restorePasswordResend = (token) => {
  return {
    type: RESTORE_PASSWORD_RESEND,
    payload: {
      token,
    }
  }
}