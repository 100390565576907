import React from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { useHistory } from 'react-router-dom'
import CreateUserForm from './create-user-form'

export default function CreateClient() {
  const history = useHistory();
  const onCreateUser = (user) => {
    //
  };
  return (
    <LagoonaPageWrapper title='Создание клиента'>
      <CreateUserForm onApply={onCreateUser} onCancel={history.goBack} />
    </LagoonaPageWrapper>
  )
}
