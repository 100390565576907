import { BaseApi } from "../helpers/api";
import { getToken } from "../helpers/common";

export class MapServices {

    static getMapObjects = async (dateFrom, dateTo) => {
        return BaseApi.getRequest({
            url: `order/map-objects?&booking_date_from=${dateFrom}&booking_date_to=${dateTo}`,
            jwt: getToken(),
        });
    };
}