import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input } from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import LagoonaTable from '../../components/LagoonaTable';
import {
  defaultColumnStyle,
  getDefaultPaginationSettings,
  getPagSettings,
} from '../../common/tableSettings';
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper';
import DatePicker from 'react-datepicker';
import ClientsAnalyticsFilter from './analytics-filter';
import { ExportButton } from '../../components/ExportButton';
import { ServiceAnalyticsServices } from '../../services/serviceAnalyticsServices';
import { Preloader } from '../../components/Preloader';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment'
import { debounce } from 'lodash';
import { hMoney } from '../../utils/toHuman';
import { useHistory } from 'react-router-dom';
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';


const { SearchBar } = Search

/* eslint-disable react/display-name */
const makeColumns = (e) => {
  const columns = [
    {
      dataField: 'id',
      text: '#',
      sort: true,
      style: defaultColumnStyle,
      headerStyle: {
        width: '80px',
      },
      footer: ''
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Наименование',
      style: defaultColumnStyle,
      headerStyle: {
        width: '240px',
      },
      footer: ''
    },
    { dataField: 'category', text: 'Категория', sort: true, style: defaultColumnStyle, footer: '' },
    { dataField: 'unit', text: 'Усл. единица', sort: true, style: defaultColumnStyle, footer: '' },
    {
      dataField: 'price',
      text: 'Стоимость',
      sort: true,
      style: defaultColumnStyle,
      formatter: (cell) => <span>{cell}&#8381;</span>,
      footer: '',
    },
    {
      dataField: 'orders_count',
      text: 'Всего заказов',
      sort: true,
      style: defaultColumnStyle,
      footer: `${e?.orders_count || 0}`
    },
    {
      dataField: 'total_amount',
      text: 'Общая сумма',
      sort: true,
      style: defaultColumnStyle,
      formatter: (cell) => <span>{cell}&#8381;</span>,
      footer: `${e?.total_amount || 0}`
    },
  ];
  return columns;
}

/* eslint-enable react/display-name */

const getOneMonthAgo = (date) => {
  const d = date;
  const m = date.getMonth();
  d.setMonth(d.getMonth() - 1);
  if (d.getMonth() == m) d.setDate(0);
  d.setHours(0, 0, 0, 0);
  return d;
}

const formatDate = (date) => {
  if (!date) {
    return;
  }
  let d = date;
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}



export default function ServicesAnalytics() {
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [dateChange, setDateChange] = useState(0);
  const [filter, setFilter] = useState();
  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [startDate, setStartDate] = useState(getOneMonthAgo(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const [serviceList, setServiceList] = useState([]);
  const [columns, setColumns] = useState(makeColumns());
  const [loading, setLoading] = useState(true);
  const [exportStarted, setExportStarted] = useState(false);
  const [total, setTotal] = useState(0);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  function get_params(name) {
    return { pageSize, page: page - 1, sort: sortField, direction: sortOrder, search: name || search || '', dateFrom: formatDate(startDate), dateTo: formatDate(endDate), ...filter }
  }

  const onTableChanged = (type, { page, sizePerPage, sortOrder, sortField }) => {
    setPage(page);
    setPageSize(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  }

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) setDateChange(dateChange + 1)
  };

  const handleExport = async () => {
    const dateFrom = moment().subtract(1, "month").format("YYYY-MM-DD");
    const dateTo = moment().format("YYYY-MM-DD");

    setExportStarted(true);
    toastr["success"]("Экспорт данных запущен", "Экспорт", exportToast)
    await ServiceAnalyticsServices.makeExport({ dateTo, dateFrom, ...filter }).finally(() => {
      setTimeout(() => {
        setExportStarted(false);
      }, 1500);
    });
  };

  const history = useHistory();

  const makeData = (a) => {
    const data = (Array.isArray(a.items) ? a?.items : Object.values(a?.items)).map((e, i) => ({
      service_id: e?.service_id,
      id: i + 1,
      name: e?.name,
      category: e?.category,
      unit: e?.unit,
      price: hMoney(e?.price),
      orders_count: e?.orders_count,
      total_amount: hMoney(e?.total_amount),
    }));
    return data;
  }

  useEffect(async () => {
    setLoading(true)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('name')) {
      const name = urlParams.get('name');
      const response = await ServiceAnalyticsServices.getServiceList(get_params(false, name));
      const data = response.data.data;
      // const u = new URL(window.location.toString());
      // window.history.replaceState({}, document.title, u.origin);
      setServiceList(makeData(data));
      setTotal(response.data.data.total);
      setLoading(false);
      return;
    }
    const response = await ServiceAnalyticsServices.getServiceList(get_params());
    const data = response.data.data;
    if (data && data !== undefined) {
      setServiceList(makeData(data));
      setTotal(response.data.data.total);
    }
    setLoading(false);
  }, [page, pageSize, search, filter, dateChange, sortField, sortOrder])

  useEffect(async () => {
    const dateFrom = formatDate(startDate);
    const dateTo = formatDate(endDate);
    const response = await ServiceAnalyticsServices.getServiceSummary({ dateFrom, dateTo })
    const data2 = response.data.data;
    setColumns(makeColumns(data2.summary));
  }, [dateChange])

  const applyFilters = (newFilter) => {
    setFilter(newFilter);
    setShowFilters(false);
  };

  const debouncedDispatch = debounce((_search) => {
    setSearch(_search);
  }, 400);

  const onChange = (e) => {
    setSearchInput(e)
    debouncedDispatch(e)
  }

  const rowEvents2 = {
    onClick: (e, row, index) => {
      history.push({
        pathname: '/service-details',
        search: `?u=${row.service_id}`,
      });
    }
  };

  return (
    <>
      {/* {exportStarted && <ToastNotification title="Экспорт" content="Экспорт данных запущен" />} */}
      <LagoonaPageWrapper title='Аналитика.Услуги'>
        <div style={{ display: 'flex', gap: 15 }}>
          <div style={{ display: 'flex', gap: 15 }}>
            <Input
              // eslint-disable-next-line react/prop-types
              onChange={(e) => debouncedDispatch(e.target.value)}
              placeholder='Поиск по наименованию'
              style={{
                width: 300,
                height: 38
              }}
            />
          </div>
          <Col md={2}>
            <FormGroup>
              <DatePicker
                locale='ru'
                dateFormat='dd.MM.yyyy'
                className='form-control'
                placeholderText="Выберите период"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(range) => onDateChange(range)}
                isClearable={true}
              />
            </FormGroup>
          </Col>
          <div style={{ marginLeft: 'auto' }}>
            <button
              type='button'
              className='btn btn-outline-success mx-2'
              onClick={() => setShowFilters(true)}
            >
              Фильтры
            </button>
            <ExportButton onClick={handleExport} action={"analytics_services_list"} />
          </div>
        </div>
        {loading ? <Preloader /> : (
          <BootstrapTable
            sort={{ dataField: sortField, order: sortOrder }}
            remote
            keyField="id"
            data={serviceList}
            headerWrapperClasses='table-light'
            wrapperClasses='mt-3'
            hover
            bordered={false}
            columns={columns}
            pagination={
              getPagSettings({ page, sizePerPage: pageSize, totalSize: total, length: total })
            }
            onTableChange={onTableChanged}
            rowEvents={rowEvents2}
          />)}
        <ClientsAnalyticsFilter show={showFilters} onApply={applyFilters} onCancel={() => setShowFilters(false)} />
      </LagoonaPageWrapper>
    </>
  )
}
