import React, { useEffect, useMemo, useState } from 'react'
import { Card, FormGroup, Input } from 'reactstrap'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { ObjectsMaps, objects } from '../../components/Map'
import { MapServices } from '../../services/mapServices'
import _ from 'lodash';
import moment from 'moment-timezone'

export default function MapPage() {
  const [mapObject, setMapObject] = useState([]);
  const [date, setDate] = useState(
    new Date().toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  )

  useEffect(async () => {
    const dateFrom = date;
    const dateTo = date;

    const response = await MapServices.getMapObjects(dateFrom, dateTo,);

    let data = response?.data?.data?.items;


    const all = data?.length ? _.groupBy(data.filter((e) => e.group_objects !== null), 'group_objects') : [];


    setMapObject(makeData(all));
  }, [date]);


  const DecideColumn = (e) => {
    switch (e) {
      case 'Хостел 1':
      case 'Хостел 2':
      case 'Хостел 3':
      case 'Хостел 4':
      case 'Бол. беседки':
        return 2;
      case 'Гостиница 2':
        return 3;
      case 'Гостиница 1':
        return 4;
      case 'Малые беседки':
      case 'Средние беседки':
        return 7;
      default:
        return 4;
    }
  }

  const DecideOrder = (e) => {
    switch (e) {
      case 'Гостиница 1':
        return 1;
      case 'Гостиница 2':
        return 2;
      case 'Хостел 1':
        return 3;
      case 'Хостел 2':
        return 4;
      case 'Хостел 3':
        return 5;
      case 'Хостел 4':
        return 6;
      case 'Малые беседки':
        return 7;
      case 'Средние беседки':
        return 8;
      case 'Бол. беседки':
        return 9;
      default:
        return 999;
    }
  }

  const makeData = (data) => {

    return Object.keys(data).sort((a, b) => DecideOrder(a) - DecideOrder(b)).map((e) => {
      return {
        title: e,
        columns: DecideColumn(e),
        rooms: data[e].map((c) => {
          return {
            title: c.short_name,
            free: c.free,
          }
        })
      }
    });
  }

  return (
    <LagoonaPageWrapper title='Карта объекта' disableContainer>
      <Card className='p-4'>
        <FormGroup>
          <Input
            id='map-date'
            type='date'
            value={date}
            onChange={e => setDate(e.target.value)}
          />
        </FormGroup>
        <ObjectsMaps models={mapObject} />
      </Card>
    </LagoonaPageWrapper>
  )
}
