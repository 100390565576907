import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button, Card, Col, Container, Row, Toast, ToastBody, ToastHeader } from 'reactstrap'
import ConfirmDialog from "../../components/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import { getEmplList } from "../../store/employees/actions";
import { EmployeeService } from "../../services/employeeService";
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';

export default function ClientDetails() {

  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const [showConfirm, setShowConfirm] = useState(false);
  const [empl, setEmpl] = useState(null);
  const [deleted, setIsDeleted] = useState(false);

  useEffect(async () => {
    const id = new URLSearchParams(search);
    const response = await EmployeeService.getEmplInfo(id.get("u"));
    setEmpl(response.data.data);
  }, []);

  const deleteEmployee = async () => {
    const id = new URLSearchParams(search).get("u");
    const response = await EmployeeService.deleteEmploye(id);
    setIsDeleted(true);
    toastr['success']('Пользователь успешно удален', 'Удаление пользователя', exportToast);
    setShowConfirm(false);
    setTimeout(() => {
      history.goBack();
    }, 2000);
  };

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <h4 className='mb-4'>Карточка сотрудника</h4>
          <Row>
            <Col md={6}>
              <Card className='p-4'>
                <h5 className='mb-5'>Общая информация</h5>
                <Row className='mb-4'>
                  <Col>Фамилия:</Col>
                  <Col>{empl?.last_name}</Col>
                </Row>
                <Row className='mb-4'>
                  <Col>Имя:</Col>
                  <Col>{empl?.first_name}</Col>
                </Row>
                <Row className='mb-4'>
                  <Col>Отчество:</Col>
                  <Col>{empl?.middle_name}</Col>
                </Row>
                <Row className='mb-4'>
                  <Col>Номер телефона:</Col>
                  <Col>{empl?.phone}</Col>
                </Row>
                <Row className='mb-4'>
                  <Col>Эл. почта:</Col>
                  <Col>{empl?.email}</Col>
                </Row>
                <Row className='mb-4'>
                  <Col>Роль:</Col>
                  <Col>{empl?.roles.map(r => r.name).join(", ")}</Col>
                </Row>
                <Row className='mb-4'>
                  <Col>Должность:</Col>
                  <Col>{empl?.position}</Col>
                </Row>
                <Row>
                  <Col>
                    <Link to={`/edit-employee?u=${new URLSearchParams(search).get("u")}`}>
                      <Button color='primary' outline block>
                        Редактировать
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Button
                      color='danger'
                      outline block
                      onClick={() => setShowConfirm(true)}
                    >
                      Удалить
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmDialog
          title="Удаление"
          body="Вы действительно хотите удалить сотрудника?"
          show={showConfirm}
          onClick={() => deleteEmployee()}
          onCancel={() => setShowConfirm(false)}
        />
      </div>
    </>
  )
}
