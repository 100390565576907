import PropTypes from 'prop-types'
import React, { useState, useMemo, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardTitle, Nav, NavItem, NavLink } from 'reactstrap'
import { Preloader } from '../../components/Preloader'
import { getGraphIncomeData } from '../../store/actions'

const createChartProps = (count, data) => {
  // const xItems = Array.from(Array(count)).map((_, idx) => idx)
  return {
    options: {
      chart: { height: 320, toolbar: { show: false } },
      // xaxis: { xItems },
    },
    series: [data],
  }
}

const getOneWeekAgo = date => {
  const d = date
  d.setDate(d.getDate() - 7)
  return d
}

const getOneMonthAgo = date => {
  const d = date
  const m = date.getMonth()
  d.setMonth(d.getMonth() - 1)
  if (d.getMonth() === m) d.setDate(0)
  d.setHours(0, 0, 0, 0)
  return d
}

const getOneDayAgo = date => {
  const d = date
  d.setDate(d.getDate() - 1)
  return d
}

const formatDate = date => {
  if (!date) {
    return
  }
  let d = date
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  let year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export default function IncomeChartCard(props) {

  const [periodType, setPeriodType] = useState('day')
  const dispatch = useDispatch()

  const [series, setSeries] = useState([])
  const [axis, setAxis] = useState([])

  const { dateChange, dateRange } = props

  useEffect(() => {
    dispatch(getGraphIncomeData(periodType, dateRange[0], dateRange[1]));
  }, [periodType, dateChange])

  const { graphIncomeData, graphIncomeState } = useSelector(state => {
    return {
      graphIncomeData: state.Dashboard.graphIncomeData,
      graphIncomeState: state.Dashboard.graphIncomeState,
    }
  })

  useEffect(() => {
    const newData = graphIncomeData?.data.items.map((c, i) => c.total_amount)
    const newAxis = graphIncomeData?.data.items.map(c =>
      new Date(c.date).toISOString().slice(0, 10)
    )
    setSeries(newData)
    setAxis(newAxis)
  }, [graphIncomeData])

  // const chartProps = useMemo(
  //   () => chartsPropsByPeriod[periodType],
  //   [periodType]
  // )

  return (
    <>
      <Card className='p-2'>
        <div style={{ display: 'flex' }}>
          <CardTitle style={{ display: 'flex' }}>График выручки</CardTitle>
          <Nav pills style={{ marginLeft: 'auto' }}>
            <NavItem>
              <NavLink
                active={periodType === 'day'}
                onClick={() => setPeriodType('day')}
              >
                День
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={periodType === 'month'}
                onClick={() => setPeriodType('month')}
              >
                Месяц
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <Chart
          type='line'
          height={320}
          series={[{ name: 'Выручка', data: series }]}
          options={{
            chart: { height: 320, toolbar: { show: false } },
            xaxis: { categories: axis },
          }}
        />
        <div className='text-end'>
          <Link to='/analytics-billing'>Посмотреть подробнее</Link>
        </div>
      </Card>
    </>
  )
}

IncomeChartCard.propTypes = {
  dateChange: PropTypes.number,
  dateRange: PropTypes.array,
}
