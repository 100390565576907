import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row, Toast, ToastBody, ToastHeader } from 'reactstrap'
import LagoonaPageWrapper from '../../components/LagoonaPageWrapper'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUser, getEmplRoles, setUserCreated } from "../../store/employees/actions";
import { EmployeeService } from "../../services/employeeService";
import toastr from 'toastr';
import { exportToast } from '../../utils/toastr';

export default function EmployeeCreate() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { item } = useSelector(state => {
        return state.emplReducer.rolesList
    });

    const [role, setRole] = useState(null);
    const [fio, setFio] = useState("");
    const [name, setName] = useState("");
    const [middName, setMiddName] = useState("");
    const [phone, setPhone] = useState("");
    const [position, setPosition] = useState("");
    const [email, setEmail] = useState("");

    const [isUserCreated, setIsUserCreated] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        dispatch(getEmplRoles())
    }, []);

    useEffect(() => {
        if (!item?.roles) {
            return;
        }

        setRole(item.roles[0].id);
    }, [item]);

    useEffect(() => {
        if (!isUserCreated) {
            return;
        }

        setTimeout(() => {
            setUserCreated(false);
            history.goBack();
        }, 1500);
    }, [isUserCreated])

    const renderDescription = useMemo(() => {
        const rolesList = item?.roles || [];

        const name = rolesList.find(roleItem => role === roleItem.id)?.name;
        const description = rolesList.find(roleItem => role === roleItem.id)?.description;

        if (!name || !description) {
            return null;
        }

        return <Col md={5}>
            <h4 className='mb-3'>{name}</h4>
            <p>{description}</p>
        </Col>
    }, [item, role]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        EmployeeService
            .createUser({ fio, name, email, middName, position, role, phone })
            .then(() => {
                setIsUserCreated(true);
                toastr['success']('Пользователь успешно создан', 'Создание пользователя', exportToast);
            })
            .catch(setError);
    }

    return (
        <>
            <LagoonaPageWrapper title='Добавление сотрудника'>
                {error && <Alert color="danger">{error}</Alert>}
                <Row>
                    <Col md={7}>
                        <Form onSubmit={handleFormSubmit}>
                            <FormGroup row>
                                <Label for='role' sm={2}>
                                    Роль <b className='text-danger'>*</b>
                                </Label>
                                <Col sm={9}>
                                    <Input required id='role' name='select' type='select'
                                        onChange={e => setRole(e.target.value)}>
                                        {item?.roles.map(role => <option value={role.id}
                                            key={role.id}>{role.name}</option>)}
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='lastname' sm={2}>
                                    Фамилия <b className='text-danger'>*</b>
                                </Label>
                                <Col sm={9}>
                                    <Input required id='lastname' name='lastname'
                                        onChange={e => setFio(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='firstName' sm={2}>
                                    Имя <b className='text-danger'>*</b>
                                </Label>
                                <Col sm={9}>
                                    <Input required id='firstName' name='firstName'
                                        onChange={e => setName(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='secondName' sm={2}>
                                    Отчество <b className='text-danger'>*</b>
                                </Label>
                                <Col sm={9}>
                                    <Input required id='secondName' name='secondName'
                                        onChange={e => setMiddName(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='phone' sm={2}>
                                    Телефон <b className='text-danger'>*</b>
                                </Label>
                                <Col sm={9}>
                                    <Input required id='phone' name='phone' type='tel'
                                        onChange={e => setPhone(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='position' sm={2}>
                                    Должность
                                </Label>
                                <Col sm={9}>
                                    <Input id='position' name='position' onChange={e => setPosition(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row className='mb-4'>
                                <Label for='email' sm={2}>
                                    Эл. почта
                                </Label>
                                <Col sm={9}>
                                    <Input id='email' name='email' type='email'
                                        onChange={e => setEmail(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <Button type="submit" color='success' onClick={function noRefCheck() {
                            }}>
                                Пригласить сотрудника
                            </Button>{' '}
                            <Button onClick={() => history.goBack()} className='mx-2'>
                                Отмена
                            </Button>
                        </Form>
                    </Col>
                    {renderDescription}
                </Row>
            </LagoonaPageWrapper>
        </>
    )
}
